import React, { Component } from 'react'
import { Provider } from 'mobx-react'
import { BrowserRouter as Router, Route, RouteComponentProps, Redirect } from 'react-router-dom'
import { observer } from 'mobx-react'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import { createMuiTheme } from '@material-ui/core/styles'

import CircularProgress from '@material-ui/core/CircularProgress'
import {
  Snackbar,
  MuiThemeProvider
} from '@material-ui/core'

import logo from './../simplan-logo.png'
import './App.scss'

import CreateScreen from './screens/create/CreateScreen.component'
import MainScreen from './screens/main/MainScreen.component'
import SettingsScreen from './screens/settings/SettingsScreen.component'
import AuthScreen from './screens/auth/auth.screen'
import AnalyticsScreen from './screens/analytics/analytics.screen'
import UserNavbarComponent from './screens/auth/user-navbar.component'

import OrganizationScreen from './screens/organization/organization.screen'
import RootStore, { RootStoreContext } from '../stores/root.store'

import AddOrganizationModalComponent from './screens/organization/add-organization/add-organization.component'
import EditOrganizationModalComponent from './screens/organization/edit-organization/edit-organization.component'
import Organization from '../models/settings/Organization.model'
import baseClient from '../utils/client/base.client'
import { OrganizationUsers } from './screens/organization/users/users.component'

const theme = createMuiTheme({
  typography: {
  },
  palette: {
    primary: {
      main: '#44A785',
    },
    secondary: {
      main: '#90a4ae',
    },
  },
})

const rootStore = new RootStore()

const stores = {
  createStore: rootStore.createStore,
  mainStore: rootStore.mainStore,
  settingsStore: rootStore.settingsStore,
  authStore: rootStore.authStore,
  organizationStore: rootStore.organizationStore,
  analyticsStore: rootStore.analyticsStore
}

enum safeScreens {
  mainScreen,
  createScreen,
  settingsScreen,
  organizationsScreen,
  analyticsScreen
}

@observer
class App extends Component {

  componentWillMount() {
    baseClient.init()
  }

  componentWillUnmount = () => {
  }

  // Temp - make it generic protectd - see ProtectedRoute below 
  safeRender = (screen: safeScreens, routeProps: RouteComponentProps) => {

    if (stores.authStore.loggedUser) {
      switch (screen) {
        case safeScreens.mainScreen:
          return <MainScreen {...routeProps} />
          break;
        case safeScreens.createScreen:
          return <CreateScreen {...routeProps} />
          break;
        case safeScreens.settingsScreen:
          return <SettingsScreen {...routeProps} />
          break;
        case safeScreens.organizationsScreen:
          return <OrganizationScreen {...routeProps} />
        case safeScreens.analyticsScreen:
          return <AnalyticsScreen {...routeProps} />

        default:
          break;
      }
    } else {
      return <Redirect
        to={{
          pathname: '/auth/',
          state: { from: routeProps.location }
        }}
      />
    }
  }

  handleSnackClose = () => {
    stores.createStore.removeMessage()
  }

  onOrganizationAdded = (organization: Organization) => {
    stores.authStore.setIsNewUser(false)
    stores.authStore.setOrganization(organization)
  }

  render() {
    const isLoading = stores.settingsStore.isLoading
    const { snackMessage } = stores.createStore

    const ids = new Set(['6ZMImFWGdrfbtR4cqjQpgvCNkP83', 'B4bjYG3oXBTjlAszBPxsTX1LLFt2', 'OihjxWbRuPahtC1S2Q2VMJxlfMy2'])

    if (isLoading) {
      return <div className='loading'>
        <CircularProgress />
      </div>
    } else {
      return (
        <div>
          <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500' />
          <MuiThemeProvider theme={theme}>
            <AppBar position='static' color='secondary'>
              <Toolbar className='toolbar'>
                <a href='/'><Typography variant='h6' color='inherit'>
                  Simplan Studio
                </Typography></a>

                <div className='row'>
                  {!!stores.authStore.loggedUser && ids.has(stores.authStore.loggedUser.id) && <a href='/settings'><Typography variant='h6' color='inherit'>
                    Settings
                  </Typography></a>}

                  {!!stores.authStore.loggedUser && <UserNavbarComponent authStore={stores.authStore} />}
                </div>
              </Toolbar>
            </AppBar>

            <Provider {...stores}>
              <RootStoreContext.Provider value={rootStore}>
                <Router>
                  <div>
                    <Route exact path='/auth' component={AuthScreen} />
                    <Route exact path='/' render={this.safeRender.bind(this, safeScreens.mainScreen)} />
                    <Route exact path='/plans/:id' render={this.safeRender.bind(this, safeScreens.createScreen)} />
                    <Route exact path='/create/' render={this.safeRender.bind(this, safeScreens.createScreen)} />
                    <Route exact path='/settings' render={this.safeRender.bind(this, safeScreens.settingsScreen)} />
                    <Route exact path='/organizations/:id' render={this.safeRender.bind(this, safeScreens.organizationsScreen)} />
                    <Route exact path='/analytics/:id' render={this.safeRender.bind(this, safeScreens.analyticsScreen)} />
                  </div>
                </Router>



                {!!snackMessage &&
                  <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={true}
                    onClose={this.handleSnackClose}
                    autoHideDuration={3000}
                    ContentProps={{
                      'aria-describedby': 'message-id',
                    }}
                    message={<span id='message-id'>{snackMessage}</span>}
                  />}

                {stores.authStore.isNewUser &&
                  <AddOrganizationModalComponent
                    organizationStore={stores.organizationStore}
                    onAdded={this.onOrganizationAdded} />}

                {stores.organizationStore.isEditingOrganization &&
                  <EditOrganizationModalComponent
                    organization={stores.organizationStore.organization!}
                    organizationStore={stores.organizationStore} />}

                {stores.organizationStore.isEditingUsers &&
                  <OrganizationUsers />}
                  
              </RootStoreContext.Provider>
            </Provider>
          </MuiThemeProvider>
        </div>
      )
    }
  }
}

export default App
