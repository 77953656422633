import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyAhO6fup8NwP5gVz8EQ0JWPcBZXLebcbWA",
    authDomain: "simplan-38b89.firebaseapp.com",
    databaseURL: "https://simplan-38b89-fa7f6.firebaseio.com/",
    projectId: "simplan-38b89",
    storageBucket: "simplan-38b89.appspot.com",
    messagingSenderId: "1045102160917"
}

firebase.initializeApp(config)

export { firebase }
export default firebase.firestore()