import { action, observable } from 'mobx'

import RootStore from './root.store'
import Plan from './../models/Plan.model'
import { PlanAnalytic } from './../models/Analytics.models'
import plansClient from './../utils/client/plans.client'
import { analyticsClientService } from '../utils/client/analytics.client'

class AnalyticsStore {
    displayedPlan?: Plan
    @observable analytics?: PlanAnalytic[]
    @observable displayedColumns: string[] = []

    @observable numOfStarts: number = 0

    constructor(private rootStore: RootStore) {}

    @action 
    initAnalytics = async (planId: string) => {
        try {
            const plan = await plansClient.getPlan(planId)

            if (plan) {
                this.displayedPlan = plan
                const analytics = await analyticsClientService.getPlanAnalytics(planId)
                this.numOfStarts = analytics.length
                this.analytics = analytics.filter(a => a.events.length)
                
                const planEvents = plan.paths.map(p => p.events).flat()
                this.updateDisplayedColumns(planEvents.map(e => e.title))
            }
        } catch (error) {
            console.warn(error)
        }
    }

    @action
    updateDisplayedColumns = async (columns: string[]) => {
        this.displayedColumns = columns
    }
}

export default AnalyticsStore
