import React, { useCallback } from 'react';

import { inject, observer } from 'mobx-react';

import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Grid, { GridSpacing } from '@material-ui/core/Grid';

import { PlanAnalytic } from './../../../models/Analytics.models'
import AnalyticsStore from '../../../stores/analytics.store';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    paper: {
        padding: 4,
        margin: 4,
        maxWidth: '30%',
    },
    dataContainer: {
        display: 'flex',
    }
})

const Row = inject('analyticsStore')((props: { planRow: PlanAnalytic, analyticsStore?: AnalyticsStore }) => {
    const { planRow } = props
    const { displayedColumns } = props.analyticsStore!
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {/* <TableCell component="th" scope="row">
                    {planRow.id}
                </TableCell> */}
                {planRow.events.map((e, idx) =>
                    <TableCell key={idx}>{e.endWith}{e.data ? ' 📝' : ''}</TableCell>
                )}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Paper elevation={0}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" gutterBottom component="div">
                                            Id: {planRow.id}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body1" gutterBottom component="div">
                                            Time: {new Date(planRow.startTime).toLocaleString()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} className={classes.dataContainer}>
                                        {planRow.events.map((e, idx) => e.data && <Paper key={idx} elevation={1} className={classes.paper}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                {displayedColumns[idx]}
                                            </Typography>
                                            {Object.keys(e.data!).filter(k => e.data![k]).map((k, dIdx) =>
                                                <Typography key={dIdx} variant="body1" component="div">
                                                    {k}: {e.data![k]}
                                                </Typography>)}
                                        </Paper>)}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
})


interface AnalyticsTableProps {
    analyticsStore?: AnalyticsStore
}

export const AnalyticsTable = inject('analyticsStore')(
    observer((props: AnalyticsTableProps) => {
        const { t } = useTranslation('activePlans')
        const onFileIconClicked = useCallback(() => {

        }, [])

        const { analytics, displayedColumns } = props.analyticsStore!

        if (!analytics) {
            return <span></span>
        }

        return (<TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        {displayedColumns.map((c, idx) =>
                            <TableCell key={idx} >{c}</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {analytics.map((row) => (
                        <Row key={row.id} planRow={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        )
    })
)
