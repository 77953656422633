import Event, { Content, Appearance, Availability, Action, Dependency, Settings, EventFormItem, EventRoles } from '../models/Event.model'
import { PlanDeadline } from '../models/Plan-deadline.model'


const parseEvent = (eventData: any): Event => {
    let event

    // Settings backwards compatibility
    let settings: Settings
    if (eventData.settings) {
        settings = parseSettings(eventData.settings)
    } else {
        const { shouldShowOnPreview } = eventData
        settings = parseSettings({ shouldShowOnPreview })
    }

    if (!eventData.dependencies) {
        const dependencies = [{
            availability: eventData.availability,
            appearance: eventData.appearance
        }]

        event = new Event(
            eventData.title,
            eventData._id,
            parseDependencies(dependencies),
            parseContent(eventData.content),
            settings,
            parseDeadline(eventData.deadline),
            parseEventRoles(eventData.roles)
        )
    } else {
        event = new Event(
            eventData.title,
            eventData._id,
            parseDependencies(eventData.dependencies),
            parseContent(eventData.content),
            settings,
            parseDeadline(eventData.deadline),
            parseEventRoles(eventData.roles))
    }

    return event
}

const parseSettings = (settings: any): Settings => {

    return new Settings(
        settings.shouldShowOnPreview,
        settings.shouldDisplayOverWeb
    )
}

const parseContent = (contentData: any): Content => {

    const actions: [Action] = [] as any as [Action]
    const form: [EventFormItem] = [] as any as [EventFormItem]

    if (contentData.actions) {
        for (const actionData of contentData.actions) {
            actions.push(parseAction(actionData))
        }
    }

    if (contentData.form) {
        for (const formItemData of contentData.form) {
            form.push(parseFormItem(formItemData))
        }
    }

    const content = new Content(contentData.type, contentData.message, actions, contentData.comment, form)

    return content
}

const parseAction = (actionData: any): Action => {
    const action = new Action(actionData.label, actionData.type, actionData.link,
        actionData.call, actionData.repeatIn, actionData.remindIn, actionData.end,
        actionData.eventFiles, actionData.note)

    return action
}

const parseFormItem = (formItemData: any): EventFormItem => {
    const action = new EventFormItem(formItemData.key, formItemData.type, formItemData.isMultiLine, formItemData.required)

    return action
}

const parseDependency = (dependencyData: any): Dependency => {
    const dependency = new Dependency(
        parseAvailability(dependencyData.availability),
        parseAppearance(dependencyData.appearance)
    )

    return dependency
}

const parseAppearance = (appearanceData: any): Appearance => {
    const appearance = new Appearance(appearanceData.time, appearanceData.location)

    return appearance
}

const parseAvailability = (availabilityData: any): Availability => {
    const availability = new Availability(availabilityData.afterTime, availabilityData.afterEvents, availabilityData.eventEndIdx)

    return availability
}

const parseDependencies = (dependenciesData: any): [Dependency] => {
    const dependencies = [] as any as [Dependency]

    for (const dependencyData of dependenciesData) {

        dependencies.push(new Dependency(
            parseAvailability((dependencyData as any).availability),
            parseAppearance((dependencyData as any).appearance)
        ))
    }

    return dependencies
}

const parseDeadline = (data: any): PlanDeadline | undefined => {
    return data ? new PlanDeadline(data.type, data.value) : undefined
}

const parseEventRoles = (data: any): EventRoles | undefined => {
    return data ? new EventRoles(data.executors, data.viewers, data.notified) : undefined
}

export { parseEvent }