import React, { Component, ReactElement } from 'react'
import PropTypes from 'prop-types'
import { action, observable, reaction } from 'mobx'
import { inject, observer } from 'mobx-react'
import { DragDropContext, DropResult, ResponderProvided, Droppable, Draggable } from 'react-beautiful-dnd'


import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import Typography from '@material-ui/core/Typography'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import MenuItem from '@material-ui/core/MenuItem'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionActions from '@material-ui/core/AccordionActions'
import { FormControlLabel, Checkbox, Modal } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Path, { PathSettings } from '../../../../../models/Path.model'
import PathEvent from '../../../../../models/Event.model'
import timeTypes from '../../../../../utils/constants/time-types'
import CreateStore from '../../../../../stores/create.store'

import EventComponent from '../event/event.component'

import DeleteIcon from '@material-ui/icons/RemoveCircleOutline'

import { withTranslation, WithTranslation } from 'react-i18next'

import InputComponent from '../../../../../utils/components/input-component'
import './path.style.scss'
import { TFunction } from 'i18next'

const boxShadow = (color: string) => `0px 1px 3px 0px ${color}, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)`

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? "lightblue" : "white",
})

interface PathComponentProps extends WithTranslation {
  path: Path
  dataKey: number
  addEvent: () => void
  removePath: (any: any) => void
  createStore?: CreateStore
}

@inject((stores) => ({
  createStore: (stores as any).createStore as CreateStore,
}))
@observer
class PathComponent extends Component<PathComponentProps, object> {
  lengthTypeIdx: number = 3
  private fullPath: string

  @observable isExpanded: boolean = false

  @observable candidateDeleteEvent?: PathEvent
  @observable dependedEvents: PathEvent[] = []

  @observable allPaths: Path[]

  @observable settings: PathSettings

  reaction: any
  constructor(props: PathComponentProps) {
    super(props)

    this.settings = props.path.settings

    this.fullPath = `paths/${props.dataKey}`
    this.allPaths = props.createStore!.paths.filter(p => p._id !== props.path._id)

    this.reaction = reaction(() => this.props.createStore!.paths.length, this.onPathListChanged)
  }

  onPathListChanged = () => {
    this.allPaths = this.props.createStore!.paths.filter(p => p._id !== this.props.path._id)
  }

  setLengthType = (event: Event) => {
    this.lengthTypeIdx = parseInt((event.target as HTMLOptionElement).value)
  }

  onDuplicatePressed = () => {
    this.props.createStore!.duplicatePath(this.props.path)
  }

  addEvent = (): void => {
    const { addEvent } = this.props
    addEvent()
  }

  onRemoveEventPressed = async (event: PathEvent) => {
    this.dependedEvents = this.props.createStore!.getDependedEvents(event)
    this.candidateDeleteEvent = event
  }

  @action closeDeleteModal = () => {
    this.candidateDeleteEvent = undefined
    this.dependedEvents = []
  }

  removeEvent = (event: PathEvent) => {
    const idx = this.props.path.events.findIndex(e => e._id === event._id)

    if (idx >= 0) {
      this.props.createStore!.removePropertyFromArray(`${this.fullPath}/events`, idx)
    }
    this.closeDeleteModal()
  }

  onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) {
      return
    }

    const { dataKey } = this.props

    this.props.createStore!.reorderEvents(dataKey,
      result.source.index,
      result.destination.index)
  }

  onSettingsChanged = action((prop: keyof PathSettings, val: boolean | string | undefined) => {
    if (prop === 'parallelWithPath') {
      this.props.createStore!.setParallelPath(this.props.path._id, val as string, this.settings[prop])

      this.props.createStore!.setProperty(`${this.fullPath}/settings/${prop}`, val)
      this.settings[prop] = val as string
    } else {
      this.props.createStore!.setProperty(`${this.fullPath}/settings/${prop}`, val)
      this.settings[prop] = val as boolean
    }
  })

  // Added this to support dragging
  componentWillUpdate(props: PathComponentProps) {
    this.settings = props.path.settings
  }

  @action onExpandedChange = (event: any, isExpanded: boolean) => {
    this.isExpanded = isExpanded
  }

  render() {
    const { t, path, addEvent, dataKey, removePath } = this.props
    const { events } = path

    return (
      <React.Fragment>
        {!!this.candidateDeleteEvent && <DeleteEventModal t={t}
          event={this.candidateDeleteEvent}
          dependedEvents={this.dependedEvents}
          toggleModal={this.closeDeleteModal}
          deleteEvent={this.removeEvent.bind(this, this.candidateDeleteEvent)}
        />}

        <Draggable draggableId={`path-${dataKey}`} isDragDisabled={this.isExpanded} index={dataKey} key={`p-${dataKey}`}>
          {(provided, snapshot) =>
            <div ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}>
              <Accordion style={{ boxShadow: boxShadow(path.color) }} className={'pathPanel'}
                classes={{ expanded: 'panelExpanded' }} id={`p-a-${dataKey}`} onChange={this.onExpandedChange}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h6' style={{ color: path.color }}>Path {path.title && `- ${path.title}`}</Typography>
                  {!!dataKey && <DeleteIcon className={'remove-icon'} onClick={removePath} />}
                </AccordionSummary>
                <AccordionDetails className={'main-container'}>
                  <InputComponent
                    id='path_title'
                    label='Title'
                    required
                    fullPath={`${this.fullPath}/title`}
                    style={{ margin: 8 }}
                    placeholder='title'
                    margin='normal'
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <Button onClick={this.onDuplicatePressed}>Duplicate Path</Button>

                  <PathSettingsView settings={this.settings}
                    onChange={this.onSettingsChanged}
                    paths={this.allPaths} />

                  {/* <div>

            <TextField
              id="appearance"
              label="appearance"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              />
              <TextField
                id="type"
                select
                label="type"
                SelectProps={{
                }}
                helperText="Before"
                margin="normal"
                value={this.lengthTypeIdx}
                onChange={this.setLengthType as any as React.ChangeEventHandler}
                >
              {timeTypes.map((option: any, idx: number) => (
                <MenuItem key={option.value} value={idx}>
                  {option.label}
                </MenuItem>
              ))}
              </TextField>
              </div> */}

                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId={'eventsDroppable'}>
                      {(provided, snapshot) =>
                        (<div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}>
                          <EventList events={events} fullPath={this.fullPath} removeEvent={this.onRemoveEventPressed} />
                          {provided.placeholder}
                        </div>)
                      }
                    </Droppable>
                  </DragDropContext>

                  <Card className={'add-card'}>
                    <Button fullWidth onClick={this.addEvent}>
                      Add Event
              </Button>
                  </Card>
                </AccordionDetails>
              </Accordion>
            </div>
          }
        </Draggable>
      </React.Fragment>
    )
  }
}


interface PathSettingsViewProps {
  settings: PathSettings
  onChange: (prop: keyof PathSettings, val: boolean | string | undefined) => void
  paths: Path[]
}

const PathSettingsView = observer((props: PathSettingsViewProps) => {
  const { settings, onChange, paths } = props

  const parallelPathIndex = paths.findIndex(p => p._id === settings.parallelWithPath)

  const onParallelChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value) {
      onChange('parallelWithPath', paths[+e.target.value - 1]._id)
    } else {
      onChange('parallelWithPath', undefined)
    }
  }

  return <div className={'event-box-border'}>
    <label className={'event-box-label'}>Path Settings</label>
    <FormControlLabel
      control={
        <Checkbox checked={settings.shouldShowOnPreview}
          onChange={(e, checked) => onChange('shouldShowOnPreview', checked)} />
      }
      label={'Should show on preview?'} />

    <div>
      <TextField
        id='parallel-path-option'
        select
        label='Parallel Path'
        SelectProps={{
        }}
        style={{ minWidth: 250, margin: '0 16px 4px' }}
        margin='normal'
        value={parallelPathIndex >= 0 ? parallelPathIndex + 1 : 0}
        onChange={onParallelChanged}
      >
        <MenuItem key={'default'} value={0}>
          {'None'}
        </MenuItem>
        {paths.map((path: Path, idx: number) => (
          <MenuItem key={`p${idx}`} value={idx + 1}>
            {path.title}
          </MenuItem>
        ))}
      </TextField>
    </div>
  </div>
})

interface EventListProps {
  events: PathEvent[]
  fullPath: string
  removeEvent: (event: PathEvent) => void
}
const EventList = observer((props: EventListProps) => {
  const { events, removeEvent, fullPath } = props
  return <div>
    {events.map((event: PathEvent, idx: number) =>

      <Draggable draggableId={`path-${idx}`} index={idx} key={`e-${idx}`}>
        {(provided, snapshot) =>
          <DivMobx provided={provided}>
            <EventComponent event={event} removeEvent={removeEvent.bind({}, event)}
              fullPath={fullPath} dataKey={idx} />
          </DivMobx>}
      </Draggable>
    )}
  </div>
})

const DivMobx = observer((props: { provided: any, children: ReactElement }) => {
  const { provided, children } = props
  return <div ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}>
    {children}
  </div>
})

interface DeleteModalProps {
  t: TFunction,
  event: PathEvent
  dependedEvents: PathEvent[]
  toggleModal: () => void
  deleteEvent: () => void
}
const DeleteEventModal = observer((props: DeleteModalProps) => {
  const { t, toggleModal, deleteEvent, event, dependedEvents } = props

  return (<Modal
    aria-labelledby='delete organization'
    open={!!event}
    onClose={toggleModal}
  >
    <div className='delete-modal'>
      <Typography variant='h5'>
        {t('deleteEvent.title')} {event.title}
      </Typography>

      <div className='body'>
        {dependedEvents.length ? <React.Fragment>
          <Typography variant='h6'>
            {t('deleteEvent.cannotRemove')}
          </Typography>
          {dependedEvents.map((e, idx) => <Typography key={`de-${idx}`}>
            {e.title}
          </Typography>)}
        </React.Fragment>
          :
          <Typography variant='h6'>
            {t('deleteEvent.areYouSure')}
          </Typography>}
      </div>

      <div className='actions'>
        <Button size='small' onClick={toggleModal}>{t('deleteEvent.cancel')}</Button>
        <Button size='small' onClick={deleteEvent} disabled={!!dependedEvents.length}>{t('deleteEvent.delete')}</Button>
      </div>
    </div>

  </Modal>
  )
})

export default withTranslation('pathComponent')(PathComponent)
