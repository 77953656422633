
enum AppearanceEnum {
    ByTime,
    ByLocation
}

const AppearanceTypes = {
    [AppearanceEnum[0]]: { label: 'By Time', idx: 0 },
    [AppearanceEnum[1]]: { label: 'By Location', idx: 1 },
}

const appearanceOptions = Object.values(AppearanceTypes)

export { appearanceOptions, AppearanceTypes, AppearanceEnum }