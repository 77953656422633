import React, { Component } from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'

import { withTranslation, WithTranslation } from 'react-i18next'

import {
    Button,
    Dialog,
    Typography,
    IconButton
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/CloseRounded'


import { ContentText } from './content-text'
import PathEvent, { Action, EventFormItem } from '../../../../../../models/Event.model'

import { EventTypesEnum } from '../../../../../../utils/constants/event-types'
import CreateStore from '../../../../../../stores/create.store'
import { EventModalFormComponent } from './event-modal-form.component'

import './event-modal.style.scss'

const englishAlphabet = 'abcdefghijklmnopqrstuvwxyz'
const isPlanRtl = (event: PathEvent) => {
    const letter = event.title.length ? event.title[0] : 'a'
    // Consider use a better way
    return !englishAlphabet.includes(letter.toLocaleLowerCase())
}

interface EventModalComponentProps extends WithTranslation {
    createStore?: CreateStore
}

@inject((stores) => ({
    createStore: (stores as any).createStore as CreateStore,
}))
@observer
class EventModalComponent extends Component<EventModalComponentProps, any> {

    onActionPressed = async (action: Action) => {
        this.props.createStore!.setDisplayedEvent(undefined)
    }

    onClosePressed = () => {
        this.props.createStore!.setDisplayedEvent(undefined)
    }

    render() {
        const { displayedEvent, title } = this.props.createStore!
        const { t } = this.props

        if (!displayedEvent) {
            return <span></span>
        }

        const isRtl = isPlanRtl(displayedEvent)

        return (
            <Dialog onClose={this.onClosePressed} aria-labelledby='customized-dialog-title'
                open={!!displayedEvent} dir={`${isRtl ? 'rtl' : 'ltr'}`} className='event-modal'>
                <div className='plan-name-container'>
                    <Typography className='plan-name'>
                        {title}
                    </Typography>

                    <IconButton className='close-button' aria-label='menu' onClick={this.onClosePressed}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Typography className='event-title' variant='h5' component='span'>
                    {displayedEvent.title}
                </Typography>

                <div className='content'>
                    {ContentText(displayedEvent.content.message)}

                    {displayedEvent.content.form && displayedEvent.content.type === EventTypesEnum.Form &&
                        <EventModalFormComponent 
                            actionItem={displayedEvent.content.actions![0]}
                            formItems={displayedEvent.content.form}
                            finish={(filledData: EventFormItem[]) => {}}/>
                    }
                </div>

                {displayedEvent.content.type !== EventTypesEnum.Form && <div className='actions-container'>
                    {displayedEvent.content.actions && displayedEvent.content.actions.map((a, idx) =>
                        <Button size='large' className='action-button' autoFocus onClick={this.onActionPressed.bind(this, a)} key={idx}>
                            {a.label}
                        </Button>
                    )}
                </div>}
            </Dialog>
        )
    }
}


export default withTranslation('eventModal')(EventModalComponent)
