import { CircularProgress, createStyles, makeStyles, Theme } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CreateStore from '../../../../stores/create.store'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: '20px 8px 8px',
            display: 'flex',
            flexDirection: 'column',
        },
        label: {
            margin: '4px'
        }
    }),
)

interface PlansColorsProps {
    createStore: CreateStore
}
export const PlansColors = observer((props: PlansColorsProps) => {
    const { t } = useTranslation('createScreen')
    const classes = useStyles()

    const setColor = props.createStore.setColor
    const colors = props.createStore.colors!

    useEffect(() => {
        if (!colors) {
            props.createStore.initColors()
        }
    }, [])

    return colors === undefined ? <CircularProgress /> :
        <div className='event-box-border'>
            <label className='event-box-label'>Colors</label>
            <div className={classes.container}>

                <div className="row">
                    <input type="color" id="main" name="main" value={colors.main} onChange={(event) => setColor('main', event.target.value)}/>
                    <label htmlFor="main" className={classes.label}>{t('colors.main')}</label>
                </div>
                <div className="row">
                    <input type="color" id="title" name="title" value={colors.title} onChange={(event) => setColor('title', event.target.value)}/>
                    <label htmlFor="title" className={classes.label}>{t('colors.title')}</label>
                </div>
                <div className="row">
                    <input type="color" id="background" name="background" value={colors.background} onChange={(event) => setColor('background', event.target.value)}/>
                    <label htmlFor="background" className={classes.label}>{t('colors.background')}</label>
                </div>
                <div className="row">
                    <input type="color" id="description" name="description" value={colors.description} onChange={(event) => setColor('description', event.target.value)} />
                    <label htmlFor="description" className={classes.label}>{t('colors.description')}</label>
                </div>
            </div>
        </div>
})
