import React, { Component } from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'

import { withTranslation, WithTranslation } from 'react-i18next'

import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    DialogTitle,
    Link,
    Stepper,
    Step,
    StepLabel,
    TextField,
    CircularProgress,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'

import RootStore from '../../../../stores/root.store'
import DropZoneComponent from '../../../../utils/components/files/dropzone/dropzone.component'

import './../add-organization/add-organization.style.scss'
import OrganizationStore from '../../../../stores/organization.store'
import Organization from '../../../../models/settings/Organization.model'

interface EditOrganizationModalComponentProps extends WithTranslation {
    organization: Organization
    organizationStore: OrganizationStore
    // onEdited: () => void
}

enum StepsEnum {
    name,
    logo,
    coverLogo,
    docsLogo,
    settings
}

@inject((stores) => ({
    // organizationStore: (stores as any).organizationStore as OrganizationStore,
}))
@observer
class EditOrganizationModalComponent extends Component<EditOrganizationModalComponentProps, any> {

    @observable activeStep = StepsEnum.name
    skippedSet = new Set()

    steps: string[]

    @observable isLoading = false

    @observable name = ''
    @observable logo = ''
    logoFile: File | undefined
    @observable coverLogo = ''
    coverLogoFile: File | undefined
    @observable docsLogo = ''
    docsLogoFile: File | undefined

    @observable isPublic: boolean = true

    constructor(props: EditOrganizationModalComponentProps) {
        super(props)
        this.steps = [
            props.t('stepsEdit.name'),
            props.t('stepsEdit.logo'),
            props.t('stepsEdit.coverLogo'),
            props.t('stepsEdit.docsLogo'),
            props.t('stepsEdit.settings')
        ]

        const { organization } = props
        this.name = organization.name
        this.isPublic = organization.isPublic
        this.logo = organization.logo || ''
        this.coverLogo = organization.coverLogo || ''
        this.docsLogo = organization.docsLogo || ''
    }

    componentWillMount = () => {
    }

    componentWillUnmount = () => {
    }

    onCancel = () => {
        this.props.organizationStore.setIsEditingOrganization(false)
    }

    @action onFinish = async () => {
        this.isLoading = true

        try {
            await this.props.organizationStore!.editOrganization(
                this.props.organization,
                this.name,
                this.isPublic,
                this.logoFile,
                this.coverLogoFile,
                this.docsLogoFile)

            // this.props.onEdited()
        } catch (err) {
            console.error(err)
        } finally {
            this.isLoading = false
        }
    }

    isStepOptional = (step: StepsEnum) => {
        return step !== StepsEnum.name
    }

    isStepSkipped = (step: number) => {
        return this.skippedSet.has(step)
    }

    isNextDisabled = (activeStep: StepsEnum) => {
        return activeStep === StepsEnum.name && !this.name
    }

    @action setActiveStep = (step: number) => {
        this.activeStep = step
    }

    @action onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.name = event.target.value
    }

    setSkipped = (step: number) => {
        this.skippedSet.add(step)
    }

    handleNext = () => {
        if (this.isStepSkipped(this.activeStep)) {
            this.skippedSet.delete(this.activeStep)
        }

        if (this.activeStep === this.steps.length - 1) {
            this.onFinish()
        } else {
            this.setActiveStep(this.activeStep + 1)
        }
    }

    handleBack = () => {
        this.setActiveStep(this.activeStep - 1)
    }

    handleSkip = () => {
        if (!this.isStepOptional(this.activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error('You can\'t skip a step that isn\'t optional.')
        }

        this.setActiveStep(this.activeStep + 1)
        this.setSkipped(this.activeStep)
    }

    @action
    onIsPublicChanged = (newState: boolean) => {
        this.isPublic = newState
    }

    onFilesAdded = (step: StepsEnum, files: File[]) => {
        const readers: FileReader[] = []
        files.forEach((file: File, index: number) => {
            readers.push(new FileReader())

            readers[index].addEventListener('load', action(() => {
                // convert image file to base64 string
                // preview.src = reader.result;

                switch (step) {
                    case StepsEnum.logo:
                        this.logo = readers[index].result as string
                        this.logoFile = file
                        break;
                    case StepsEnum.coverLogo:
                        this.coverLogo = readers[index].result as string
                        this.coverLogoFile = file
                        break;
                    case StepsEnum.docsLogo:
                        this.docsLogo = readers[index].result as string
                        this.docsLogoFile = file
                        break;

                    default:
                        break;
                }
            }), false);

            if (file) {
                try {
                    readers[index].readAsDataURL(file);
                } catch (err) {
                    console.warn(err)
                }
            }
        })
    }

    getStepContent = (step: StepsEnum) => {
        switch (step) {
            case StepsEnum.name:
                return <TextField
                    id='o-name'
                    label='Name'
                    required
                    style={{ margin: 8 }}
                    placeholder='title'
                    value={this.name}
                    margin='normal'
                    onChange={this.onNameChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            case StepsEnum.logo:
                return <div className='logo-container'>
                    {this.logo && <img className='logo' src={this.logo} />}
                    <DropZoneComponent onFilesAdded={this.onFilesAdded.bind(this, StepsEnum.logo)} />
                </div>
            case StepsEnum.coverLogo:
                return <div className='cover-logo-container'>
                    {this.coverLogo && <img className='logo' src={this.coverLogo} />}
                    <DropZoneComponent onFilesAdded={this.onFilesAdded.bind(this, StepsEnum.coverLogo)} />
                </div>
            case StepsEnum.docsLogo:
                return <div className='docs-logo-container'>
                    {this.docsLogo && <img className='logo' src={this.docsLogo} />}
                    <DropZoneComponent onFilesAdded={this.onFilesAdded.bind(this, StepsEnum.docsLogo)} />
                </div>
            case StepsEnum.settings:
                return <div className='settings-container'>
                    <FormControlLabel
                        control={
                            <Checkbox checked={this.isPublic}
                                onChange={(e, checked) => this.onIsPublicChanged(checked)} />
                        }
                        label={this.props.t!('isPublic')}
                    />
                </div>
            default:
                break;
        }
    }

    render() {
        // const {  } = this.props.rootStore!
        const { t } = this.props

        return (
            <Dialog onClose={this.onCancel} aria-labelledby='customized-dialog-title'
                open={!!true} className='add-organization'>
                <DialogTitle>
                    {t('titleEdit')}
                </DialogTitle>

                <DialogContent className='dialog-content'>
                    {this.isLoading ?
                        <CircularProgress />
                        :
                        <React.Fragment>
                            <Stepper activeStep={this.activeStep}>
                                {this.steps.map((label, index) => {
                                    const stepProps: { completed?: boolean } = {}
                                    const labelProps: { optional?: React.ReactNode } = {}

                                    if (this.isStepSkipped(index)) {
                                        stepProps.completed = false
                                    }
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    )
                                })}
                            </Stepper>
                            <div className='step-content'>
                                {this.getStepContent(this.activeStep)}
                            </div>

                            <div className='buttons'>
                                <Button disabled={this.activeStep === 0} onClick={this.handleBack}>
                                    {t('back')}
                                </Button>
                                {this.isStepOptional(this.activeStep) && this.activeStep === this.steps.length - 1 && (
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={this.handleSkip}
                                    >
                                        {t('later')}
                                    </Button>
                                )}
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={this.handleNext}
                                    disabled={this.isNextDisabled(this.activeStep)}
                                >
                                    {this.activeStep === this.steps.length - 1 ? t('save') : t('next')}
                                </Button>
                            </div>
                        </React.Fragment>
                    }
                </DialogContent>
                {/* <DialogActions>
                    <Button size='large' autoFocus onClick={this.onAddClicked}>
                        {t('add')}
                    </Button>
                </DialogActions> */}
            </Dialog>
        )
    }
}


export default withTranslation('addOrganization')(EditOrganizationModalComponent)
