import Path from './../models/Path.model'

const getPathIdx = (fullPath: string) => {
    const pathParts = fullPath.split('/')

    validatePath(pathParts)

    return parseInt(pathParts[1])
}

const getEventIdx = (fullPath: string) => {
    const pathParts = fullPath.split('/')

    validateEvent(pathParts)

    return parseInt(pathParts[3])
}

const validatePath = (pathParts: string[]) => {
    let isValid: boolean = true

    if (pathParts[0] != 'paths') {
        isValid = false
        throw new Error('Invalid path')
    }

    return isValid
} 

const validateEvent = (pathParts: string[]) => {
    let isValid: boolean = true

    if (pathParts[2] != 'events') {
        isValid = false
        throw new Error('Invalid path')
    }

    return isValid
} 

const propFromPathObject: {[key: string]: (...args: any[]) => any;} = {
    2: (a: [any], b: string) => a[parseInt(b)],
    3: (a: [any], b: string, c: string) => a[parseInt(b)][c],
    4: (a: [any], b: string, c: string, d: string) => a[parseInt(b)][c][d],
    5: (a: [any], b: string, c: string, d: string, e: string) => a[parseInt(b)][c][d][e],
    6: (a: [any], b: string, c: string, d: string, e: string, f: string) => a[parseInt(b)][c][d][e][f],
    7: (a: [any], b: string, c: string, d: string, e: string, f: string, g: string) => a[parseInt(b)][c][d][e][f][g],
    8: (a: [any], b: string, c: string, d: string, e: string, f: string, g: string, h: string) => a[parseInt(b)][c][d][e][f][g][h],
    9: (a: [any], b: string, c: string, d: string, e: string, f: string, g: string, h: string, i: string) => a[parseInt(b)][c][d][e][f][g][h][i],
    10: (a: [any], b: string, c: string, d: string, e: string, f: string, g: string, h: string, i: string, j: string) => a[parseInt(b)][c][d][e][f][g][h][i][j],
} 

const propFromPath = (baseObj: Path[], pathParts: string[]) => propFromPathObject[pathParts.length - 1](baseObj, ...pathParts.slice(1, pathParts.length -1))

export {getPathIdx, getEventIdx, propFromPath}