import { observable, action, runInAction } from 'mobx'

import RootStore from './root.store'
import Organization from '../models/settings/Organization.model'
import { addImage, removeImage } from '../utils/dal/organizations.dal'
import { User } from '../models/User.model'
import { getUser } from '../utils/dal/users.dal'
import organizationClient from '../utils/client/organization.client'
import { OrganizationCustomerField } from '../models/settings/CustomerField'

const uuidv4 = require('uuid/v4')

class OrganizationStore {
    @observable organization?: Organization
    @observable organizationUsers: User[] = []

    @observable isEditingOrganization = false
    @observable isEditingUsers = false

    constructor(private rootStore: RootStore) {}

    addOrganization = async (name: string, isPublic: boolean, logo?: File, coverLogo?: File, docsLogo?: File): Promise<Organization> => {
        try {
            const id = uuidv4()
            let logoUrl: string | undefined
            let coverLogoUrl: string | undefined
            let docsLogoUrl: string | undefined

            if (logo) {
                logoUrl = await addImage(id, 'logo', logo)
            }

            if (coverLogo) {
                coverLogoUrl = await addImage(id, 'cover', coverLogo)
            }

            if (docsLogo) {
                docsLogoUrl = await addImage(id, 'docs', docsLogo)
            }

            const organization = new Organization(id, name, logoUrl, coverLogoUrl, docsLogoUrl, undefined, undefined, isPublic)

            await organizationClient.addOrganization(organization)

            this.setOrganization(organization)
            return organization
        } catch (err) {
            console.error('Failed to add organization')
            throw err
        }
    }

    @action
    editOrganization = async (
        organization: Organization,
        name: string,
        isPublic: boolean,
        logo?: File,
        coverLogo?: File,
        docsLogo?: File
    ): Promise<void> => {
        try {
            if (logo) {
                if (organization.logo) {
                    await removeImage(organization.logo)
                }

                organization.logo = await addImage(organization._id, 'logo', logo)
            }

            if (coverLogo) {
                if (organization.coverLogo) {
                    await removeImage(organization.coverLogo)
                }
                organization.coverLogo = await addImage(organization._id, 'cover', coverLogo)
            }

            if (docsLogo) {
                if (organization.docsLogo) {
                    await removeImage(organization.docsLogo)
                }
                organization.docsLogo = await addImage(organization._id, 'docs', docsLogo)
            }

            organization.name = name
            organization.isPublic = isPublic

            await organizationClient.updateOrganization(organization)

            // Update ui organization
            this.organization = undefined
            setTimeout(
                action(() => {
                    this.organization = organization
                }),
                100
            )
        } catch (err) {
            console.error('Failed to edit organization')
            throw err
        } finally {
            this.isEditingOrganization = false
        }
    }

    @action addCustomerField = async (field: OrganizationCustomerField) => {
        if (!this.organization) {
            throw new Error('Add customer filed called with no organization on the store')
        }

        const customerFields = this.organization.customersFields
        customerFields.push(field)
        const organization = { ...this.organization, customerFields }

        await organizationClient.updateOrganization(organization)

        this.setOrganization(organization)
    }

    @action deleteCustomerField = async (fieldId: string) => {
        if (!this.organization) {
            throw new Error('Add customer filed called with no organization on the store')
        }

        const customerFields = this.organization.customersFields

        const index = customerFields.findIndex((f) => f.id === fieldId)
        customerFields[index].isDeleted = true
        const organization = { ...this.organization, customerFields }

        await organizationClient.updateOrganization(organization)

        this.setOrganization(organization)
    }

    @action setOrganization = (organization: Organization) => {
        this.organization = organization

        Promise.all(this.organization.users.map((u) => getUser(u.userId))).then(
            action((users) => {
                this.organizationUsers = users
            })
        )
    }

    @action startEditOrganization = (organization: Organization) => {
        this.organization = organization
        this.isEditingOrganization = true
    }

    @action setIsEditingOrganization = (state: boolean) => {
        this.isEditingOrganization = state
    }

    @action setIsEditingUsers = (state: boolean) => {
        this.isEditingUsers = state
    }

    addRole = async (name: string) => {
        if (!this.organization) {
            throw new Error('No organization')
        }

        const roles = this.organization.roles
        roles.push({
            id: uuidv4(),
            text: name
        })

        await organizationClient.updateOrganization({
            ...this.organization,
            roles
        })

        runInAction(() => {
            this.organization!.roles = roles
        })
    }

    addUser = async (user: User, roleIds: string[], canSearchAllData: boolean) => {
        if (!this.organization || !this.organizationUsers) {
            throw new Error('No organization')
        }

        const { userId } = await organizationClient.addOrganizationUser(this.organization, user)

        const users = this.organization.users
        this.organizationUsers.push({ ...user, id: userId })

        users.push({
            userId,
            roleIds,
            canSearchAllData
        })

        await organizationClient.updateOrganization({
            ...this.organization,
            users
        })

        runInAction(() => {
            this.organization!.users = users
        })
    }
}

export default OrganizationStore
