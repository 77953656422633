

export enum PlanDeadlineType {
    TIME,
    DATE
}

export class PlanDeadline {
    type: PlanDeadlineType
    value: number

    constructor(type: PlanDeadlineType, value: number) {
        this.type = type
        this.value = value 
    }
}


const DeadlineTypes = {
    [0]: { label: 'None', idx: 0 },
    [PlanDeadlineType[0]]: { label: 'Time', idx: 1 },
    // [PlanDeadlineType[1]]: { label: 'Date', idx: 2 }
}

export const deadlineOptions = Object.values(DeadlineTypes)
