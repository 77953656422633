
const timeTypes = [
    { label: 'Weeks', value: 7 * 24 * 60 },
    { label: 'Days', value: 24 * 60 },
    { label: 'Hours', value: 60 },
    { label: 'Minutes', value: 1 }
]

const fromTheOptions = [
    {
        label: 'Start', value: 1
    },
    {
        label: 'End', value: -1
    }
]

const timeParser = (time: number) => {
    let returnedData = { type: 3, value: 0, isNegative: time < 0 }
    
    time = Math.abs(time)

    if (time) {
        for (let i = 0; i < timeTypes.length; i++) {
            const timeType = timeTypes[i]
            if (time >= timeType.value && !(time % timeType.value)) {
                returnedData.type = i
                returnedData.value = time / timeType.value 
                
                break
            }
        }
    }

    return returnedData
}

export { fromTheOptions, timeParser }
export default timeTypes
