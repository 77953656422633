import { observable } from 'mobx'

import i18n from 'i18next'

import AuthStore from './auth.store'
import MainStore from './main.store'
import CreateStore from './create.store'
import SettingsStore from './settings.store'
import OrganizationStore from './organization.store'
import AnalyticsStore from './analytics.store'
import React, { useContext } from 'react'


class RootStore {
    mainStore: MainStore
    createStore: CreateStore
    authStore: AuthStore
    settingsStore: SettingsStore
    organizationStore: OrganizationStore
    analyticsStore: AnalyticsStore

    lang: string

    @observable isRtl: boolean
    
    constructor() {
        this.mainStore = new MainStore(this)
        this.createStore = new CreateStore(this)
        this.authStore = new AuthStore(this)
        this.settingsStore = new SettingsStore(this)
        this.organizationStore = new OrganizationStore(this)
        this.analyticsStore = new AnalyticsStore(this)

        this.lang = i18n.language || window.localStorage.i18nextLng || ''
        this.isRtl = this.lang === 'he-IL' || this.lang === 'ar'

        this.setLang(this.lang)
    }

    setLang = (lang: string) => {
        this.lang = lang
    }
}

export default RootStore

export const RootStoreContext = React.createContext<RootStore | undefined>(undefined)

export const useRootStore = () => useContext(RootStoreContext)!

export const useStore = <T extends keyof RootStore>(name: T): RootStore[T] => useRootStore()[name]
