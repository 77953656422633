import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { toJS, observable, action } from 'mobx'

import MainStore from '../../../stores/main.store'
import CreateStore from '../../../stores/create.store'
import SettingsStore from '../../../stores/settings.store'

import Grid from '@material-ui/core/Grid'

import Snackbar from '@material-ui/core/Snackbar'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import Typography from '@material-ui/core/Typography'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'

import MapIcon from '@material-ui/icons/Map'
import TemplateIcon from '@material-ui/icons/Class'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import NewIcon from '@material-ui/icons/FiberNew'

import {
  Menu,
  MenuItem
} from '@material-ui/core'

import { RouteProps } from 'react-router'

import Plan from '../../../models/Plan.model'

import Organization from '../../../models/settings/Organization.model';
import { Modal, IconButton, CircularProgress } from '@material-ui/core'
import PlanPreview from '../../../models/Plan-preview.model'
import { RouteComponentProps } from 'react-router-dom'
import OrganizationCard from '../organization/organization-card/organization-card.component'

import './MainScreen.style.scss'
import logo from './../../../simplan-logo.png'

interface MainScreenProps extends RouteComponentProps {
  mainStore: MainStore
  createStore: CreateStore
  settingsStore: SettingsStore
}

@inject((stores) => ({
  mainStore: (stores as any).mainStore as MainStore,
  createStore: (stores as any).createStore as CreateStore,
  settingsStore: (stores as any).settingsStore as SettingsStore,
}))
@observer
class MainScreen extends Component<any, object> {
  @observable anchorElement: HTMLDivElement | null = null

  constructor(props: any) {
    super(props)
    this.state
  }

  componentDidMount = () => {
    this.props.mainStore.init()
  }

  componentWillUnmount = () => {
    // this.props.mainStore.reset()
  }

  addPlan = () => {
    this.props.history.push('/create')
  }

  editPlan = (plan: PlanPreview) => {
    const { history } = this.props

    history.push(`/plans/${plan._id}`)
  }

  openInNewTab = (plan: PlanPreview) => {
    window.open(`${window.location.href}plans/${plan._id}`, '_blank')
  }

  onRemovePressed = (plan: PlanPreview) => {
    this.props.mainStore.setCandidateDeletePlan(plan)
  }

  getPathSecondary = (plan: PlanPreview) => {
    const numOfEvents = plan.paths.reduce((acc, curr) => acc + curr.events.length, 0)
    return `Paths: ${plan.paths.length}, Events: ${numOfEvents}`
  }

  getOrganizationLogo = (organizationId: string) => {
    const { organizations } = this.props.settingsStore

    const organization = organizations.find((o: Organization) => o._id === organizationId)

    if (organization) {
      return organization.logo
    } else {
      return logo
    }
  }

  @action onPluPressed = (event: any) => {
    this.anchorElement = event.target
  }

  @action handleClose = () => {
    this.anchorElement = null
  }

  createFromTemplate = async (id: string) => {
    this.props.mainStore!.setIsLoading(true)
    const newId = await this.props.createStore!.duplicatePlan(id, true)
    // Wait for fully loading of the new plan
    await this.props.createStore!.timeout(2100)
    this.props.mainStore!.setIsLoading(false)
    this.props.history.push(`/plans/${newId}`)
  }

  render() {
    const {
      plans, candidateDeletePlan, removePlan, setCandidateDeletePlan,
      isLoading, templates, userTemplates
    } = this.props.mainStore

    if (isLoading) {
      return <div className='loading'>
        <CircularProgress />
      </div>
    }

    return (
      <Grid container alignItems={'center'} direction={'column'} className='main-screen'>
        <Grid item xs={12} style={{ minWidth: '500px' }}>
          <OrganizationCard />

          {!!userTemplates.length && <React.Fragment>
            <Typography variant='h6' key='templates-title'>
              Your Templates
          </Typography>
            <List dense key={'template-list'}>
              {userTemplates.map((plan: PlanPreview, idx: number) =>
                <ListItem key={idx}>
                  <ListItemIcon>
                    <TemplateIcon />
                  </ListItemIcon>
                  <ListItemText primary={plan.title}
                    secondary={this.getPathSecondary(plan)} />
                  <ListItemSecondaryAction>
                    <Button onClick={this.editPlan.bind({}, plan)}>
                      Edit / View
                    </Button>
                    <IconButton aria-label='delete' onClick={this.onRemovePressed.bind(this, plan)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>,
              )}
            </List>
          </React.Fragment>}

          <div className='row space-between'>
            <Typography variant='h6'>
              Plans
          </Typography>
            <Fab color='primary' aria-label='Add' onClick={this.onPluPressed}>
              <AddIcon />
            </Fab>

            <Menu
              id='new-plan-menu'
              anchorEl={this.anchorElement}
              open={Boolean(this.anchorElement)}
              onClose={this.handleClose.bind(this)}
            >
              <MenuItem onClick={this.addPlan}>New Plan / Template
                <ListItemIcon>
                  <NewIcon />
                </ListItemIcon>
              </MenuItem>
              {!!userTemplates.length && <div>
                <div className='list-divider'>Your Templates</div>
                {userTemplates.map((t: Plan, idx: number) => <MenuItem key={idx} onClick={this.createFromTemplate.bind(this, t._id)}>{t.title}</MenuItem>)}
              </div>}
              {!!templates.length && <div>
                <div className='list-divider'>Global templates</div>
                {templates.map((t: Plan, idx: number) => <MenuItem key={`t-${idx}`} onClick={this.createFromTemplate.bind(this, t._id)}>{t.title}</MenuItem>)}
              </div>}
            </Menu>
          </div>
          <List dense>
            {plans.map((plan: PlanPreview, idx: number) =>
              <ListItem key={idx}>
                <ListItemIcon>
                  {!!plan.organizationId ?
                    <img src={this.getOrganizationLogo(plan.organizationId)} width={30} height={30} />
                    :
                    <MapIcon />}
                </ListItemIcon>
                <ListItemText primary={plan.title}
                  secondary={this.getPathSecondary(plan)} />
                <ListItemSecondaryAction>
                  <Button onClick={this.editPlan.bind({}, plan)} onContextMenu={this.openInNewTab.bind({}, plan)}>
                    Edit / View
                    </Button>
                  <IconButton aria-label='delete' onClick={this.onRemovePressed.bind(this, plan)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>,
            )}
          </List>
        </Grid>
        {
          !!candidateDeletePlan && <DeleteModal plan={candidateDeletePlan}
            deletePlan={removePlan.bind(this, candidateDeletePlan)}
            toggleModal={setCandidateDeletePlan.bind(this, undefined)} />
        }
      </Grid >
    )
  }
}


interface DeleteModalProps {
  plan?: Plan
  toggleModal: () => void
  deletePlan: () => void
}
const DeleteModal = observer((props: DeleteModalProps) => {
  const { toggleModal, deletePlan, plan } = props

  return (<Modal
    aria-labelledby='delete organization'
    open={!!plan}
    onClose={toggleModal}
  >
    <div className='delete-modal'>
      <Typography variant='h5'>
        Delete Plan {plan!.title}
      </Typography>

      <div className='body'>
        <Typography variant='h6'>
          Are you sure?
      </Typography>
      </div>

      <div className='actions'>
        <Button size='small' onClick={toggleModal}>Cancel</Button>
        <Button size='small' onClick={deletePlan}>Delete</Button>
      </div>
    </div>
  </Modal>
  )
})

export default MainScreen
