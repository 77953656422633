import db from './../firebase'
import Plan from '../../models/Plan.model'
import Path from '../../models/Path.model'
import Event from '../../models/Event.model'
import { parseEvent } from '../parser'
import { removeUndefined } from './utils.dal'
import clientService from '../client/client.service'

const buildPlanFromDb = (planData: any): Plan => {
    const paths = [] as any as [Path]

    for (const pathData of planData.paths) {
        let events: [Event]
        events = [] as any as [Event]
        for (const eventData of pathData.events) {
            events.push(parseEvent(eventData))
        }

        paths.push(new Path(pathData.title, pathData._id, pathData.color, events, pathData.settings))
    }

    return new Plan(planData.title,
        planData._id,
        paths,
        planData.lastUpdate,
        planData.inDevelopment,
        planData.organizationId,
        planData.displayOnOrganizationOnly,
        planData.type,
        planData.isPublic,
        planData.description,
        planData.isLocked,
        planData.permissions,
        planData.users,
        planData.version,
        planData.pixelId,
        planData.deadline,
        planData.roles,
        planData.customerDetails
    )
}

const getPlansByOrganizationId = async (organizationId: string): Promise<Plan[]> => {

    const res = await db.collection('planz').where('organizationId', '==', organizationId).get()

    const plans = []
    if (res && res.docs && res.docs.length) {

        for (let dock of res.docs) {
            const planData = dock.data()
            plans.push(buildPlanFromDb(planData))
        }
    }

    return plans
}

const updatePlan = async (plan: Plan) => {

    const plans = await db
        .collection('planz')
        .where('_id', '==', plan._id)
        .get()

    if (plans && Array.isArray(plans.docs)) {
        plans.docs[0].ref.update(removeUndefined(plan))
    }

    await clientService.updatePlan(plan)
}

const addBackUp = (plan: any) => {
    db.collection('removed-plans').add(plan)
}

const savePlan = async (plan: Plan) => {

    const jsPlan: Plan = {
        _id: plan._id,
        title: plan.title,
        version: plan.version,
        description: plan.description,
        type: plan.type,
        lastUpdate: Date.now(),
        inDevelopment: plan.inDevelopment,
        isPublic: plan.isPublic,
        organizationId: plan.organizationId,
        paths: JSON.parse(JSON.stringify(plan.paths)),
        isLocked: plan.isLocked,
        permissions: plan.permissions,
        users: plan.users,
        customerDetails: plan.customerDetails
    }

    await db.collection('planz').doc(plan._id).set(removeUndefined(jsPlan))
    await clientService.updatePlan(plan)
}


export {
    getPlansByOrganizationId,
    buildPlanFromDb,
    updatePlan,
    savePlan
}
