import db from './../firebase'
import PlanPreview from '../../models/Plan-preview.model'
import { removeUndefined } from './utils.dal'

const savePlanPreview = async (plan: PlanPreview): Promise<void> => {
    const planPreview = removeUndefined(plan)

    const res = await db.collection('plans-preview').where('_id', '==', plan._id).get()

    if (res && res.docs && res.docs.length) {
      res.docs[0].ref.update(removeUndefined({ ...planPreview }))
    } else {
      db.collection('plans-preview').doc(plan._id).set(removeUndefined({ ...planPreview }))
    }
  }

export {
    savePlanPreview
}