import * as React from 'react';
import { DiagramEngine, PortWidget } from '@projectstorm/react-diagrams-core';
import styled from '@emotion/styled';
import { EventEndPortModel } from './end-port.model'

export interface EventEndPortLabelProps {
	port: EventEndPortModel;
	engine: DiagramEngine;
}

const S =  {
	PortLabel: styled.div`
		position: relative;
		font-style: italic;
		background-color: #d4d0d07a;
		border-radius: 5px;
		margin: 0 5px;
		display: flex;
		align-items: center;
		text-align: center;
	`,
	Label: styled.div`
		padding: 0 5px;
		flex-grow: 1;
	`,
	Port: styled.div`
		width: 96%;
    	height: 93%;
		background: rgba(white, 0.1);

		&:hover {
			border: 2px solid #1ab1a2;
    		border-radius: 8px;
		}
	`
}

export class EventEndPortLabel extends React.Component<EventEndPortLabelProps> {
	render() {
		const port = (
			<PortWidget engine={this.props.engine} port={this.props.port}>
				<S.Port />
			</PortWidget>
		);
		const label = <S.Label>{this.props.port.getOptions().label}</S.Label>;

		return (
			<S.PortLabel>
				{this.props.port.getOptions().in ? port : label}
				{this.props.port.getOptions().in ? label : port}
			</S.PortLabel>
		);
	}
}
