import { observable, action } from 'mobx'

import Organization, { PlansGroup } from '../models/settings/Organization.model'
import organizationClient from '../utils/client/organization.client'
import {
    getOrganizations,
    removeOrganization,
    removeImage
} from '../utils/dal/organizations.dal'
import RootStore from './root.store'

const uuidv4 = require('uuid/v4')

class SettingsStore {
    @observable organizations: Organization[] = []
    @observable selectedOrganization?: Organization

    @observable isLoading: boolean = true
    @observable isModalOpen: boolean = false
    isInitialized = false

    constructor(private rootStore: RootStore) { }

    init = async () => {
        if (!this.isInitialized) {
            await this.initOrganizations()
        }
    }
    @action toggleIsModalOpen = () => {
        this.isModalOpen = !this.isModalOpen
    }

    @action addOrganization = async (organization: Organization) => {
        await organizationClient.addOrganization(organization)

        this.organizations.push(organization)
    }

    @action removeOrganization = async (organization: Organization) => {

        if (organization.logo) {
            try {
                await removeImage(organization.logo)
            } catch (err) {
                console.warn(err)
            }
        }
        if (organization.coverLogo) {
            try {
                await removeImage(organization.coverLogo)
            } catch (err) {
                console.warn(err)
            }
        }

        removeOrganization(organization._id)

        const index = this.organizations.findIndex(o => o._id === organization._id)
        this.organizations.splice(index, 1)
    }

    @action initOrganizations = async () => {
        const user = this.rootStore.authStore.loggedUser!
        if (user) {
            this.organizations = await getOrganizations(user)

            this.isInitialized = true
            this.isLoading = false
        } else {
            this.isInitialized = false
        }
    }

    @action
    setSelectedOrganization = (organizationId: string): void => {
        const organization = this.organizations.find(({ _id }) => _id === organizationId)

        if (organization) {
            this.selectedOrganization = organization
        } else {
            console.warn('No organization')
            throw new Error('Organization not found')
        }
    }

    // Groups ---------------->

    @action updateGroupName = (group: PlansGroup, name: string) => {
        group.name = name
    }

    @action updatePlanGroups = (group: PlansGroup, planIds: string[]) => {
        group.planIds = planIds
    }

    @action addNewGroup = (organization: Organization) => {
        const newGroup = new PlansGroup(uuidv4(), '', [])

        if (!organization.groups) {
            organization.groups = []
        }
        organization.groups.push(newGroup)
    }

    updateSelectedOrganization = () => {
        organizationClient.updateOrganization(this.selectedOrganization!)
    }
}

export default SettingsStore
