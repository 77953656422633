
const colors = [
    '#90998c',
    '#071833',
    '#9c5fb5',
    '#e25664',
    '#84e256',
    '#6ba4ff'
]
let idx = Math.floor(Math.random() * 6 + 1)

const generateColor = () => {
    let color = colors[idx]
    idx = (idx += 1) % colors.length
    
    return color
}

export default generateColor