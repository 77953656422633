import React, { Component, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { action, observable } from 'mobx'

import TextField from '@material-ui/core/TextField'

import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline'


import Event, { Action, Note } from '../../../../../models/Event.model'
import { availabilityOptions, AvailabilitiesEnum } from '../../../../../utils/constants/availability-types'
import TimeSelectorComponent from '../../../../../utils/components/time-selector'
import EventSelectorComponent from '../../../../../utils/components/event-selector'
import { eventTypeOptions, EventTypesEnum } from '../../../../../utils/constants/event-types'
import { actionsOptions, ActionsEnum } from '../../../../../utils/constants/action-types'
import InputComponent from '../../../../../utils/components/input-component'
import CreateStore from '../../../../../stores/create.store'
import SelectComponent from '../../../../../utils/components/select-component';
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { NoteTemplateEditor } from './noteTemplate'

interface ActionComponentProps {
  action: Action
  fullPath: string
  dataKey: number
  removeAction: (any: any) => void
  createStore?: CreateStore
}

@inject((stores) => ({
  createStore: (stores as any).createStore as CreateStore,
}))
@observer
class ActionComponent extends Component<ActionComponentProps, object> {
  @observable actionTypeIdx: number = ActionsEnum.End
  private fullPath: string

  action: Action

  constructor(props: ActionComponentProps) {
    super(props)
    const { fullPath, action, dataKey } = props

    this.actionTypeIdx = action.type
    this.fullPath = `${fullPath}/actions/${dataKey}`

    this.action = props.action
  }

  @action onActionTypeSelected = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.actionTypeIdx = parseInt(event.target.value)

    const actionTypePath = `${this.fullPath}/type`
    this.props.createStore!.setProperty(actionTypePath, this.actionTypeIdx)
  }

  // Added this to support dragging
  componentWillUpdate(props: ActionComponentProps) {
    this.actionTypeIdx = props.action.type
  }

  render() {
    const { action, dataKey, removeAction, createStore } = this.props

    return (
      <div className={'event-box-border'}>
        <label className={'event-box-label'}>{`Action ${dataKey + 1}`}</label>
        {!!dataKey && <DeleteIcon className={'remove-icon'} onClick={removeAction} />}
        <InputComponent
          id={`action-label-${dataKey}`}
          label='Label'
          margin='normal'
          required
          fullPath={`${this.fullPath}/label`}
        />
        <TextField
          id='action'
          select
          label='Action'
          SelectProps={{
          }}
          style={{ minWidth: 80 }}
          margin='normal'
          value={this.actionTypeIdx}
          onChange={this.onActionTypeSelected}
        >
          {actionsOptions.map((option: any, idx: number) => (
            <MenuItem key={`o${idx}`} value={idx}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <AfterView action={action} fullPath={this.fullPath} actionTypeIdx={this.actionTypeIdx} createStore={createStore!} />
      </div>
    )
  }
}

const AfterView = observer((props: { actionTypeIdx: number, action: Action, fullPath: string, createStore: CreateStore }) => {
  const { actionTypeIdx, action, fullPath, createStore } = props
  const { t } = useTranslation('event')

  const [isEditingNote, setEditingNote] = useState(false)

  switch (actionTypeIdx) {
    case ActionsEnum.Link:
    case ActionsEnum.LinkAndFinish:
      const linkPath = `${fullPath}/link`
      return <InputComponent fullPath={linkPath} required label={'Link'} />
    case ActionsEnum.Call:
      const callPath = `${fullPath}/call`
      return <InputComponent fullPath={callPath} required label={'Phone'} />
    case ActionsEnum.Repeat:
      const timePath = `${fullPath}/repeatIn`
      return <TimeSelectorComponent timePath={timePath} timeLabel={'Repeat In'} />
    case ActionsEnum.Remind:
      return <span></span>
    case ActionsEnum.End:
      return <span></span>
    case ActionsEnum.EndWith:
      const endPath = `${fullPath}/end`
      return <InputComponent fullPath={endPath} required InputLabelProps={{ shrink: true, }} />
    case ActionsEnum.TakePicture:
      const notePath = `${fullPath}/note`

      if (action.note === undefined) {
        action.note = new Note()
      }

      return (isEditingNote ? 
      <NoteTemplateEditor onClose={() => setEditingNote(false)} fullPath={notePath} /> 
      : 
      <Button onClick={() => setEditingNote(true)}>{t('editNoteTemplate')}</Button>)
    case ActionsEnum.FinishAndAddTheFiles:
      const eventFilesPath = `${fullPath}/eventFiles`
      const eventOptions = createStore!.paths.map(p => p.events).flat().map((e: Event, idx) => ({ _id: e._id, label: e.title, key: idx })) as [any]
      const currentEvent = createStore.getPropertyByPath(fullPath.split('/content')[0]) as Event
      const currentEventIdx = eventOptions.findIndex((e) => e._id === currentEvent._id)

      return <SelectComponent fullPath={eventFilesPath} required defaultOptionIdx={currentEventIdx} label={'Files of:'} options={eventOptions} />
  }

  return <span></span>
})

export default ActionComponent
