import React, { Component, ChangeEvent } from 'react'
import { inject, observer } from 'mobx-react'

import { RouteProps } from 'react-router'
import { WithTranslation, withTranslation } from 'react-i18next'

import {
    Button,
    FormControl,
    InputLabel,
    Typography,
    OutlinedInput
} from '@material-ui/core'

import PersonIcon from '@material-ui/icons/Person'

import AuthStore from '../../../../stores/auth.store'
import { SignInUser } from '../../../../models/User.model'

import googleLogo from './../../../../assets/images/google-logo.png'

import './../auth.style.scss'

interface LogInProps extends RouteProps, WithTranslation {
    authStore?: AuthStore
}

@inject((stores) => ({
    authStore: (stores as any).authStore as AuthStore,
}))
@observer
class LogInComponent extends Component<any, LogInProps> {

    componentWillMount = () => {
    }

    componentWillUnmount = () => {
        this.props.authStore.clearSignInObject()
    }

    onChange = (prop: keyof SignInUser, event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        this.props.authStore.updateSignInData(prop, value)
    }

    render() {
        const { t, authStore } = this.props

        const { email, password } = authStore.signInObject

        return (
            <form className={'signin-form'} noValidate autoComplete="on">
                <Typography className='welcome-message' variant='h6'>{t('welcome')}</Typography>

                <FormControl variant="outlined" fullWidth className={'input'} required>
                    <InputLabel htmlFor="email-input">{t('email')}</InputLabel>
                    <OutlinedInput
                        id="email-input"
                        value={email}
                        onChange={this.onChange.bind(this, 'email')}
                        type='email'
                        label={t('email')}
                    />
                </FormControl>
                <FormControl variant="outlined" fullWidth className={'input'} required>
                    <InputLabel htmlFor="password-input">{t('password')}</InputLabel>
                    <OutlinedInput
                        id="password-input"
                        value={password}
                        onChange={this.onChange.bind(this, 'password')}
                        type='password'
                        label={t('password')}
                        aria-describedby="component-error-text"
                    />
                </FormControl>

                <Button fullWidth disabled={!email || !password}
                    onClick={authStore.logIn} className='login-botton'>
                    {t('login')}
                </Button>

                <div className="or-divider"><span>{t('or')}</span></div>

                <Button fullWidth onClick={authStore.logInUsingGoogle} className='login-button'
                    endIcon={<img src={googleLogo} alt='' width={20} />}>
                    {t('loginWithGoogle')}
                </Button>
            </form>)
    }
}

export default withTranslation('authScreen')(LogInComponent)
