import Path from './Path.model'
import { PlanTypeEnum } from '../utils/constants/plan-type'
import { PlanDeadline } from './Plan-deadline.model'

// import uuidv4 from 'uuid/v4'
const uuidv4 = require('uuid/v4')

export interface PlanColors {
    main: string
    title: string
    background?: string
    description: string
}

export interface PlanRole {
    roleId: string
    isAdmin: boolean
    isEditor: boolean

    isExternal: boolean
    uniqIdentifier?: string // External user has uniq identifier
}

export interface PlanCustomerRequestedDetails {
    fieldId: string
    isRequired: boolean
}

export default class Plan {
    _id: string
    title: string
    description: string
    type: PlanTypeEnum
    paths: [Path]
    lastUpdate: number
    inDevelopment: boolean
    organizationId?: string
    displayOnOrganizationOnly?: boolean
    isPublic: boolean = true
    isLocked: boolean = false
    permissions: string[] = [] // List of permitted email
    users: string[] = [] // List of users with access to this active plan
    version: number = 1

    deadline?: PlanDeadline
    roles?: PlanRole[]

    pixelId?: string

    colors?: PlanColors

    customerDetails: PlanCustomerRequestedDetails[]

    constructor(
        title: string,
        _id?: string,
        paths?: [Path],
        lastUpdate?: number,
        inDevelopment?: boolean,
        organizationId?: string,
        displayOnOrganizationOnly?: boolean,
        type?: PlanTypeEnum,
        isPublic?: boolean,
        description?: string,
        isLocked?: boolean,
        permissions?: string[],
        users?: string[],
        version?: number,
        pixelId?: string,
        colors?: PlanColors,
        deadline?: PlanDeadline,
        roles?: PlanRole[],
        customerDetails?: PlanCustomerRequestedDetails[]
    ) {
        this._id = _id || uuidv4()
        this.title = title
        this.paths = paths || [new Path('')]
        this.lastUpdate = lastUpdate || Date.now()
        this.inDevelopment = inDevelopment || false
        this.organizationId = organizationId
        this.displayOnOrganizationOnly = displayOnOrganizationOnly || false
        this.type = type || PlanTypeEnum.Single
        this.description = description || ''
        this.isPublic = isPublic === undefined ? true : isPublic
        this.isLocked = !!isLocked
        this.permissions = permissions || []
        this.users = users || []
        this.version = version || 1
        this.pixelId = pixelId
        this.colors = colors
        this.deadline = deadline
        this.roles = roles
        this.customerDetails = customerDetails || []
    }
}
