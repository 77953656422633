import { observable } from 'mobx'

// import { User as firebaseUser } from 'firebase'

export class SignInUser {
    @observable email: string
    @observable password?: string
    @observable name?: string

    @observable isEmailValid = true
    @observable isPasswordValid = true

    @observable isUserValid = false

    constructor(email: string, password?: string, name?: string) {
        this.email = email
        this.password = password
        this.name = name
    }
}

export class User {
    id: string
    name: string
    email: string
    planIds: string[]
    phone?: string

    organizationId?: string

    constructor(id: string, name: string, email: string, planIds?: string[], organizationId?: string,
        phone?: string) {
        this.id = id
        this.email = email
        this.name = name
        this.planIds = planIds || []
        this.organizationId = organizationId

        this.phone = phone
    }
}
