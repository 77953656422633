import { PlanTypeEnum } from "../utils/constants/plan-type"

class EventPreview {
    title: string
    shouldShowOnPreview: boolean

    constructor(title: string, shouldShowOnPreview: boolean) {
        this.title = title
        this.shouldShowOnPreview = shouldShowOnPreview
    }
}

export class PathPreview {
    title: string
    events: EventPreview[]
    shouldShowOnPreview: boolean

    constructor(title: string, events: EventPreview[], shouldShowOnPreview: boolean) {
        this.title = title
        this.events = events
        this.shouldShowOnPreview = shouldShowOnPreview
    }
}

export default class PlanPreview {
    _id: string
    title: string
    description: string
    type: PlanTypeEnum
    lastUpdate: number
    inDevelopment: boolean
    organizationId?: string
    displayOnOrganizationOnly?: boolean
    isPublic: boolean = true
    isLocked: boolean = false
    permissions: string[] = [] // List of permitted email
    users: string[] = [] // List of users with access to this active plan

    paths: PathPreview[]

    constructor(_id: string, title: string, paths: PathPreview[], lastUpdate: number,
        inDevelopment: boolean, organizationId?: string,
        displayOnOrganizationOnly?: boolean, type?: PlanTypeEnum, isPublic?: boolean,
        description?: string, isLocked?: boolean, permissions?: string[], users?: string[]) {
        this._id = _id
        this.title = title
        this.paths = paths
        this.lastUpdate = lastUpdate
        this.inDevelopment = inDevelopment
        this.organizationId = organizationId
        this.displayOnOrganizationOnly = displayOnOrganizationOnly
        this.type = type || PlanTypeEnum.Single
        this.description = description || ''
        this.isPublic = isPublic === undefined ? true : isPublic
        this.isLocked = !!isLocked
        this.permissions = permissions || []
        this.users = users || []
    }
}
