
import React, { Component, ChangeEvent } from 'react'
import { inject, observer } from 'mobx-react'

import { RouteProps } from 'react-router'
import { TFunction } from 'i18next'
import { WithTranslation, withTranslation } from 'react-i18next'

import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    FormControlLabel,
    Checkbox,
    TextField,
    Tooltip
} from '@material-ui/core'

import CreateStore from '../../../../../stores/create.store'

import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import { PlanTypeEnum } from './../../../../../utils/constants/plan-type'

import './Permission-modal.style.scss'
import { observable, action } from 'mobx'

const EMAIL_REG = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

interface PermissionsDialogProps extends RouteProps, WithTranslation {
    createStore?: CreateStore
}

@inject((stores) => ({
    createStore: (stores as any).createStore as CreateStore,
}))
@observer
class PermissionsDialogComponent extends Component<PermissionsDialogProps, any> {
    @observable newUser: string = ''
    @observable isUserValid: boolean = false

    @action
    setNewUser = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.newUser = event.target.value
        this.isUserValid = EMAIL_REG.test(this.newUser)
    }

    onCancel = () => {
        this.props.createStore!.togglePermissionsModal()
    }

    onIsPublicChanged = (newState: boolean) => {
        this.props.createStore!.setIsPublic(newState)
    }

    onIsLockedChanged = (newState: boolean) => {
        this.props.createStore!.setIsLocked(newState)
    }

    onDisplayWithOrganizationOnlyChanged = (newState: boolean) => {
        this.props.createStore!.setDisplayOnOrganizationOnly(newState)
    }

    removePermission = (index: number) => {
        this.props.createStore!.removePermission(index)
    }

    @action
    addUser = () => {
        this.props.createStore!.addPermission(this.newUser)
        this.newUser = ''
        this.isUserValid = false
    }

    render() {
        const { t, createStore } = this.props
        const {
            isPermissionsDialogShown, displayOnOrganizationOnly,
            isPublic, isLocked, permissions, planType
        } = createStore!

        return (
            <Dialog onClose={this.onCancel} aria-labelledby='customized-dialog-title'
                open={isPermissionsDialogShown} className='permissions-modal'>

                <DialogContent dividers>
                    <Typography variant='h6' gutterBottom>
                        {t('title')}
                    </Typography>

                    <div className='content'>
                        <FormControlLabel
                            control={
                                <Checkbox checked={isPublic}
                                    onChange={(e, checked) => this.onIsPublicChanged(checked)} />
                            }
                            label={planType === PlanTypeEnum.Template ? t!('isTemplatePublic') : t!('isPublic')}
                        />

                        {/* {<FormControlLabel
                            control={
                                <Checkbox checked={displayOnOrganizationOnly}
                                    onChange={(e, checked) => this.onDisplayWithOrganizationOnlyChanged(checked)} />
                            }
                            label={t!('displayOnOrganizationOnly')} />}
                            <br/> */}
                        {planType !== PlanTypeEnum.Template && <FormControlLabel
                            control={
                                <Checkbox checked={isLocked}
                                    onChange={(e, checked) => this.onIsLockedChanged(checked)} />
                            }
                            label={t!('isLocked')}
                        />}


                        {planType !== PlanTypeEnum.Template && <div className={'event-box-border'}>
                            <label className={'event-box-label'}>{t('permittedUsers')}</label>

                            <div className='permissions'>
                                {permissions.map((p, idx) =>
                                    <PermissionView t={t} email={p} removeUser={this.removePermission.bind(this, idx)} key={idx} />
                                )}
                            </div>

                            <div className='add-permission'>
                                <TextField
                                    id='add-permission'
                                    label={t('addUser')}
                                    style={{ margin: 8, minWidth: 220 }}
                                    placeholder={t('addUser')}
                                    value={this.newUser}
                                    margin='normal'
                                    onChange={this.setNewUser}
                                    fullWidth
                                    onKeyPress={event => event.key === 'Enter' && this.isUserValid && this.addUser()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />


                                <Tooltip title={this.isUserValid ? t('add') as string : t('invalidEmailMessage') as string}>
                                    <span style={{ display: 'flex' }}>
                                        <Button
                                            className='add-user-button'
                                            endIcon={<AddIcon />}
                                            disabled={!this.isUserValid}
                                            onClick={this.addUser}>
                                            {t('add')}
                                        </Button>
                                    </span>
                                </Tooltip>
                            </div>
                        </div>}
                    </div>
                    <DialogActions>
                        <Button autoFocus onClick={this.onCancel} color='secondary'>
                            {t('ok')}
                        </Button>
                    </DialogActions>

                </DialogContent>
            </Dialog >)
    }
}

interface PermissionProps {
    t: TFunction
    email: string
    removeUser: () => void
}
const PermissionView = observer((props: PermissionProps) => {
    const { t, email, removeUser } = props
    return <div className='permission'>
        <Typography>{email}</Typography>
        <Button endIcon={<DeleteIcon />} onClick={removeUser}>{t('delete')}</Button>
    </div>
})


export default withTranslation('permissionsModal')(PermissionsDialogComponent)