import { observer } from 'mobx-react'
import React from 'react'
import { withTranslation, useTranslation } from 'react-i18next'

import Path from '../../../models/Path.model'
import PathEvent from '../../../models/Event.model'

import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'

import Typography from '@material-ui/core/Typography'

import './CreateScreen.style.scss'
import { TFunction } from 'i18next'

interface DeleteModalProps {
    path: Path
    dependedEvents: PathEvent[]
    toggleModal: () => void
    deletePath: () => void
    t: TFunction
}

const DeletePathModal = observer((props: DeleteModalProps) => {
  const {toggleModal, deletePath, path, dependedEvents, t} = props

  return (<Modal
    aria-labelledby='delete organization'
    open={!!path}
    onClose={toggleModal}
  >
    <div className='delete-modal'>
      <Typography variant='h5'>
        {t('deletePath.title')} {path.title}
      </Typography>

      <div className='body'>
        {dependedEvents.length ? <React.Fragment>
            <Typography variant='h6'>
              {t('deletePath.cannotRemove')}
            </Typography>
            {dependedEvents.map((e, idx) => <Typography key={`de-${idx}`}>
              {e.title}
            </Typography>)}
          </React.Fragment>
          :
          <Typography variant='h6'>
            {t('deletePath.areYouSure')}
          </Typography>}
      </div>

      <div className='actions'>
        <Button size='small' onClick={toggleModal}>{t('deletePath.cancel')}</Button>
        <Button size='small' onClick={deletePath} disabled={!!dependedEvents.length}>{t('deletePath.delete')}</Button>
      </div>
    </div>

  </Modal>
  )
})

export default withTranslation('createScreen')(DeletePathModal as any)