import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { action, observable } from 'mobx'

import TextField from '@material-ui/core/TextField'

import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'

import PathEvent, { Appearance } from '../../../../../models/Event.model'
import TimeSelectorComponent from '../../../../../utils/components/time-selector'
import { appearanceOptions, AppearanceEnum } from '../../../../../utils/constants/appearance-types'
import LocationSelectorComponent from '../../../../../utils/components/location-selector'

interface AppearanceComponentProps {
    appearance: Appearance,
    fullPath: string
}

@observer
class AppearanceComponent extends Component<AppearanceComponentProps, object> {
  @observable appearanceIdx: AppearanceEnum = AppearanceEnum.ByTime
  private fullPath: string
  hasAppearanceIdxChanged = false

  constructor(props: AppearanceComponentProps) {
    super(props)
    const {fullPath} = props

    this.fullPath = `${fullPath}/appearance`

    if (this.props.appearance.location !== undefined) {
      this.appearanceIdx = AppearanceEnum.ByLocation
    }
  }

  // Added this to support dragging
  componentWillUpdate(props: AppearanceComponentProps) {
    if (!this.hasAppearanceIdxChanged) {
      if (props.appearance.location !== undefined) {
        this.appearanceIdx = AppearanceEnum.ByLocation
      } else {
        this.appearanceIdx = AppearanceEnum.ByTime
      }
    }
    this.hasAppearanceIdxChanged = false
  }

  @action onAppearanceSelected = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.appearanceIdx = parseInt(event.target.value)
    this.hasAppearanceIdxChanged = true
  }

  render() {
    const { appearance } = this.props
    
    return (
        <div className={'event-box-border'}>
            <label className={'event-box-label'}>Appearance</label>
            <TextField
              id="Appearance-option"
              select
              label="Appears By"
              SelectProps={{
              }}
              margin="normal"
              style={{minWidth: 85}}
              value={this.appearanceIdx}
              onChange={this.onAppearanceSelected}
            >
            {appearanceOptions.map((option: any, idx: number) => (
              <MenuItem key={`a${idx}`} value={idx}>
                {option.label}
              </MenuItem>
            ))}
            </TextField>
            <AfterView {...this.props} fullPath={this.fullPath} appearanceIdx={this.appearanceIdx}/>
        </div>
    )
  }
}

const AfterView = observer((props: AppearanceComponentProps & {appearanceIdx: number}) => {
  const { appearanceIdx, appearance, fullPath } = props
  const timePath = `${fullPath}/time`
  switch (appearanceIdx) {
    case AppearanceEnum.ByTime:
      return <TimeSelectorComponent timePath={timePath} timeLabel={'In'}/>
    case AppearanceEnum.ByLocation:
      const locationPath = `${fullPath}/location`
      return <span>
                <LocationSelectorComponent locationPath={locationPath}/>
                <br/><span className={'start-label'}>Fallback: </span><TimeSelectorComponent timePath={timePath} timeLabel={'In'}/>
              </span>
  }

  return <span></span>
})

export default AppearanceComponent
