
import React, { Component } from 'react'
import { observable, action } from 'mobx'
import { inject, observer } from 'mobx-react'

import { RouteProps } from 'react-router'

import {
    Avatar,
    Menu,
    MenuItem
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'

import AuthStore from '../../../stores/auth.store'

import './auth.style.scss'

interface UserNavbarProps extends RouteProps {
    authStore: AuthStore
}

@observer
class UserNavbarComponent extends Component<any, UserNavbarProps> {

    @observable anchorElement: HTMLDivElement | null = null

    componentWillMount = () => {
    }

    componentWillUnmount = () => {
    }

    getAvavtar = () => {
        const { loggedUser } = this.props.authStore

        if (loggedUser.photoURL) {
            return <Avatar alt={loggedUser.displayName} src={loggedUser.photoURL} className='avatar' />
        } else if (loggedUser.displayName) {
            return <Avatar alt={loggedUser.displayName} className='avatar'>{loggedUser.displayName[0]}</Avatar>
        } else {
            return <Avatar alt={loggedUser.displayName} className='avatar'><PersonIcon /></Avatar>
        }
    }

    @action onClick = (event: any) => {
        this.anchorElement = event.target
    }

    @action handleClose = () => {
        this.anchorElement = null
    }

    logout = () => {
        this.props.authStore!.signOut()
    }

    render() {
        const { authStore } = this.props
        const { loggedUser } = authStore
        
        return (<div>
            <div className="user-navbar" onClick={this.onClick}>
                <span className="user-name">{loggedUser.name}</span>
                {this.getAvavtar()}
            </div>

            <Menu
                id="user-menu"
                anchorEl={this.anchorElement}
                open={Boolean(this.anchorElement)}
                onClose={this.handleClose.bind(this)}
            >
                <MenuItem onClick={this.logout}>Logout</MenuItem>
            </Menu>
        </div>)
    }
}

export default (UserNavbarComponent)


