import axios from 'axios'

import {
    PlanAnalytic
} from '../../models/Analytics.models'

class AnalyticsClientService {
    async getPlanAnalytics(planId: string): Promise<PlanAnalytic[]> {
        const { data } = await axios.get(`/analytics/${planId}`)
        return data
    }
}

export const analyticsClientService =  new AnalyticsClientService()