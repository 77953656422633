
const removeUndefined = (obj: any) => {
    for (let i in obj) {
        if (obj[i] &&
            ['Dependency', 'Availability', 'Appearance', 'Location', 'Action', 'PlanDeadline', 'e']
                .includes(obj[i].constructor.name)) {
            obj[i] = Object.assign({}, obj[i])
        }

        if (obj[i] === undefined) {
            Reflect.deleteProperty(obj, i)
        } else if (typeof (obj[i]) === 'object') {
            removeUndefined(obj[i])
        }
    }

    return obj
}

export {
    removeUndefined
}