import React, { Component, ChangeEvent } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { toJS, observable, action } from 'mobx'

import MainStore from '../../../../stores/main.store'
import CreateStore from '../../../../stores/create.store'

import { firebase } from './../../../../utils/firebase'
import FileUploader from 'react-firebase-file-uploader'

const uuidv4 = require('uuid/v4')

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'

import Typography from '@material-ui/core/Typography'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import MenuItem from '@material-ui/core/MenuItem'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'

import { Avatar, Divider, Modal } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'

import SettingsStore from '../../../../stores/settings.store';
import Organization from '../../../../models/settings/Organization.model';
import Plan from '../../../../models/Plan.model'
import { getPlansByOrganizationId } from '../../../../utils/dal/plans.dal'

import './settings-card.style.scss'

enum ImageType {
  logo,
  cover
}

interface SettingsCardProps {
  mainStore?: MainStore
  createStore?: CreateStore
  settingsStore?: SettingsStore
  history: any // TODO: fix type
}

@inject((stores) => ({
  mainStore: (stores as any).mainStore as MainStore,
  createStore: (stores as any).createStore as CreateStore,
  settingsStore: (stores as any).settingsStore as SettingsStore,
}))
@observer
class SettingsCard extends Component<SettingsCardProps, object> {

  @observable newOrganizationName?: string
  @observable newOrganizationAvatar?: string
  @observable newOrganizationCoverLogo?: string
  @observable newOrganizationColor?: string

  @observable candidateDeleteOrganization?: Organization
  @observable attachedPlans: Plan[] = []

  state = {
    isUploading: false,
    progress: 0,
  };

  constructor(props: any) {
    super(props)
    this.state
  }

  componentDidMount = () => {
    // this.props.mainStore.init()
  }

  componentWillUnmount = () => {
    // this.props.mainStore.reset()
  }

  addOrganization = () => {

    const organization = new Organization(uuidv4(),
      this.newOrganizationName!,
      this.newOrganizationAvatar!,
      this.newOrganizationCoverLogo!,
    )

    this.props.settingsStore!.addOrganization(organization)
  }

  toggleModal = () => {
    this.props.settingsStore!.toggleIsModalOpen()
  }
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = (progress: number) => this.setState({ progress });
  handleUploadError = (error: string) => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  @action handleUploadSuccess = (imageType: ImageType, filename: string) => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref('images')
      .child(filename)
      .getDownloadURL()
      .then(action((url: string) => {
        if (imageType === ImageType.logo) {
          this.setState({ avatarURL: url })
          this.newOrganizationAvatar = url
        } else if (imageType === ImageType.cover) {
          this.setState({ coverURL: url })
          this.newOrganizationCoverLogo = url
        }

        // this.props.settingsStore!.toggleIsModalOpen()
      }));
  };

  @action onNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    this.newOrganizationName = event.target.value
  }

  onOrganizationPressed = async (organization: Organization) => {
    const { history } = this.props
    history.push(`/organizations/${organization._id}`)
  }

  onRemovePressed = async (organization: Organization) => {
    this.attachedPlans = await getPlansByOrganizationId(organization._id)
    this.candidateDeleteOrganization = organization
  }

  @action closeDeleteModal = () => {
    this.candidateDeleteOrganization = undefined
  }

  deleteOrganization = () => {
    this.props.settingsStore!.removeOrganization(this.candidateDeleteOrganization!)
    this.closeDeleteModal()
  }

  render() {
    const { isModalOpen, organizations } = this.props.settingsStore!

    return (
      <div>

        <DeleteModal
          organization={this.candidateDeleteOrganization}
          toggleModal={this.closeDeleteModal}
          deleteOrganization={this.deleteOrganization}
          plans={this.attachedPlans}
        />

        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={isModalOpen}
          onClose={this.toggleModal}
        >
          <div className='modal'>
            <Typography variant='h6'>
              Add Organization
            </Typography>
            <TextField
              id={'input-name'}
              margin='normal'
              label={'Name'}
              style={{ minWidth: 90 }}
              value={this.newOrganizationName}
              onChange={this.onNameChanged}
              InputLabelProps={{
                shrink: true,
              }}>
            </TextField>

            {!!this.newOrganizationAvatar && <img src={this.newOrganizationAvatar} width='100' height='100' />}
            {!!this.newOrganizationCoverLogo && <img src={this.newOrganizationCoverLogo} width='200' height='80' />}

            {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
            <span>Logo</span>
            <FileUploader
              accept='image/*'
              name='avatar'
              randomizeFilename
              storageRef={firebase.storage().ref('images')}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess.bind(this, ImageType.logo)}
              onProgress={this.handleProgress}
            />

            <span>Cover logo</span>
            <FileUploader
              accept='image/*'
              name='cover'
              randomizeFilename
              storageRef={firebase.storage().ref('images')}
              onUploadStart={this.handleUploadStart}
              onUploadError={this.handleUploadError}
              onUploadSuccess={this.handleUploadSuccess.bind(this, ImageType.cover)}
              onProgress={this.handleProgress}
            />

            <Button size='small' disabled={!this.newOrganizationName} onClick={this.addOrganization}>Save</Button>
          </div>

        </Modal>

        <Card className='card-container'>
          <CardHeader title='Organizations'>
          </CardHeader>
          <CardContent>
            {/* <Fab color='primary' aria-label='Add' onClick={this.toggleModal} style={{ position: 'absolute', right: 0 }}>
              <AddIcon />
            </Fab> */}
            <List>
              {organizations.map((o, idx) =>
                <ListItem alignItems='flex-start' key={idx} onClick={this.onOrganizationPressed.bind(this, o)}>
                  <ListItemAvatar>
                    <Avatar alt={o.name} src={o.logo} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={o.name}
                  //   secondary={
                  //     <React.Fragment>
                  //       <Typography
                  //         component='span'
                  //         variant='body2'
                  //         color='textPrimary'
                  //       >
                  //         Ali Connors
                  // </Typography>
                  //       {' — I'll be in your neighborhood doing errands this…'}
                  //     </React.Fragment>
                  //   }
                  />

                  <ListItemSecondaryAction>
                    <IconButton aria-label='delete' onClick={this.onRemovePressed.bind(this, o)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
              <Divider variant='inset' component='div' />
            </List>
          </CardContent>

          <CardActions>
            <Button size='small' onClick={this.toggleModal}>Add Organization</Button>
          </CardActions>
        </Card>
      </div>
    )
  }
}


interface DeleteModalProps {
  organization?: Organization
  plans: Plan[]
  toggleModal: () => void
  deleteOrganization: () => void
}
const DeleteModal = observer((props: DeleteModalProps) => {
  const { toggleModal, organization, deleteOrganization, plans } = props

  return (<Modal
    aria-labelledby='delete organization'
    open={!!organization}
    onClose={toggleModal}
  >
    <div className='delete-modal'>
      <Typography variant='h5'>
        Delete Organization
      </Typography>

      <div className='body'>
        {plans.length ? <Typography variant='h6'>
          Cannot remove organization since it attached to {plans.length} Plan{plans.length > 1 ? 's' : ''}
        </Typography> :
          <Typography variant='h6'>
            Are you sure?
      </Typography>}
      </div>

      <div className='actions'>
        <Button size='small' onClick={toggleModal}>Cancel</Button>
        <Button size='small' onClick={deleteOrganization} disabled={!!plans.length}>Delete</Button>
      </div>
    </div>

  </Modal>
  )
})

export default SettingsCard
