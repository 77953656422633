import { observable } from 'mobx'
import Event from './Event.model'
import generateColor from './../utils/colors'

// import uuidv4 from 'uuid/v4'
// Fix 
const uuidv4 = require('uuid/v4')


export default class Path {
    _id: string
    @observable title: string
    color: string
    @observable appearanceLengthIdx: number
    @observable events: [Event]

    @observable settings: PathSettings

    constructor(title: string, _id?: string, color?: string, events?: [Event], settings?: PathSettings) {
      this._id = _id || uuidv4()
      this.title = title
      this.color = color || generateColor()
      this.appearanceLengthIdx = 3 
      this.events = events || [new Event('')]
      this.settings = settings || new PathSettings()
    }
}

export class PathSettings {
  @observable shouldShowOnPreview: boolean
  @observable parallelWithPath?: string // Index of the path

  constructor(shouldShowOnPreview? :boolean, parallelWithPath?: string) {
      this.shouldShowOnPreview = shouldShowOnPreview == undefined ? true : shouldShowOnPreview
      this.parallelWithPath = parallelWithPath
  }
}

  