import React from 'react'

import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Checkbox, FormControlLabel, LinearProgress, MenuItem, TextField } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline'

import { EventFormItem } from '../../../../../../models/Event.model'
import CreateStore from '../../../../../../stores/create.store'
import InputComponent from '../../../../../../utils/components/input-component'

const inputTypes = [
    'text',
    'number',
    'tel',
    'email',
    'date'
]

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formItemContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }
    }),
)


interface FormItemComponentProps {
    fullPath: string
    formItem: EventFormItem,
    createStore?: CreateStore
    dataKey: number
    removeItem: () => void
}
const FromItemComponent = inject('createStore')(
    observer((props: FormItemComponentProps) => {
        const { fullPath, formItem, createStore, removeItem, dataKey } = props
        const classes = useStyles()
        const { t } = useTranslation('event')

        return <div className={classes.formItemContainer + ' event-box-border'}>
            {dataKey > 0 && <DeleteIcon className={'remove-icon'} onClick={removeItem}/>}
            <div className={classes.row}>

                <InputComponent
                    id='key'
                    label={t('key')}
                    variant='outlined'
                    required
                    margin='normal'
                    fullPath={`${fullPath}/key`}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField
                    id='type'
                    select
                    label={t('type')}
                    value={formItem.type}
                    style={{ marginLeft: 8 }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        createStore!.setProperty(`${fullPath}/type`, event.target.value)
                    }}
                >
                    {inputTypes.map((option: any, idx: number) => (
                        <MenuItem key={`o${idx}`} value={option}>
                            {t(option)}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div className={classes.row}>

                <FormControlLabel
                    key={'isMultiLine'}
                    control={
                        <Checkbox checked={formItem.isMultiLine}
                            onChange={(e, checked) => createStore!.setProperty(`${fullPath}/isMultiLine`, checked)} />
                    }
                    label={t('isMultiLine')} />

                <FormControlLabel
                    control={
                        <Checkbox checked={formItem.required}
                            onChange={(e, checked) => createStore!.setProperty(`${fullPath}/required`, checked)} />
                    }
                    label={t('required')} />
            </div>
        </div>
    }),
)

export { FromItemComponent }
