import { observable, action, computed, toJS } from 'mobx'
import Plan, { PlanColors, PlanCustomerRequestedDetails, PlanRole } from './../models/Plan.model'
import Path from './../models/Path.model'
import PathEvent from './../models/Event.model'

import timeTypes from './../utils/constants/time-types'
import { propFromPath } from '../utils/component-path'

import plansClient from '../utils/client/plans.client'
import { getPlanPreview } from '../utils/plan.utils'
import { savePlanPreview } from '../utils/dal/plan-preview.dal'
import { updatePlan, savePlan } from '../utils/dal/plans.dal'
import RootStore from './root.store'
import { addPlanToUser } from '../utils/dal/users.dal'
import { PlanTypeEnum } from '../utils/constants/plan-type'
import { PlanDeadline, PlanDeadlineType } from '../models/Plan-deadline.model'

const uuidv4 = require('uuid/v4')

const ids = new Set(['6ZMImFWGdrfbtR4cqjQpgvCNkP83', 'B4bjYG3oXBTjlAszBPxsTX1LLFt2', 'OihjxWbRuPahtC1S2Q2VMJxlfMy2'])

const DEFAULT_MAIN_COLOR = '#44A785'
const DEFAULT_TITLE_COLOR = '#ffffff'
const DEFAULT_DESC_COLOR = '#000000'

class CreateStore {
    @observable currentLengthTypeIdx = 3
    isEditMode = false
    @observable snackMessage: string = ''
    @observable isLoading: boolean = true
    @observable displayedEvent?: PathEvent
    plan?: Plan

    // Plan props
    @observable description = ''
    @observable inDevelopment: boolean = true
    @observable isPublic: boolean = true
    @observable displayOnOrganizationOnly?: boolean
    @observable planType: PlanTypeEnum = PlanTypeEnum.Single
    @observable version: number = 1
    @observable pixelId?: string

    @observable colors?: PlanColors

    @observable deadline?: PlanDeadline

    @observable roles?: PlanRole[]
    @observable customerDetails: PlanCustomerRequestedDetails[] = []

    @observable title: string = ''
    organizationId: string = ''

    @observable isLocked: boolean = false
    @observable permissions: string[] = []
    @observable isPermissionsDialogShown: boolean = false
    users: string[] = []

    shouldShowOrganizationPicker = true
    isFormChanged = false

    // Same as @observable path
    paths = observable<Path>([new Path('')])

    constructor(private rootStore: RootStore) {
        window.onbeforeunload = () => {
            if (this.isFormChanged) {
                return 'Save your data'
            }
        }
    }

    @action startCreating = async () => {
        this.isLoading = false

        if (this.rootStore.authStore.loggedUser) {
            this.organizationId = this.rootStore.authStore.loggedUser.organizationId || ''

            this.shouldShowOrganizationPicker = !this.organizationId

            const { id, email } = this.rootStore.authStore.loggedUser
            if (this.permissions.indexOf(email) < 0) {
                this.permissions.unshift(email)
            }
            if (this.users.indexOf(email) < 0) {
                this.users.unshift(email)
            }
        }
    }

    @action setPlan = async (planId: string) => {
        try {
            const plan = await plansClient.getPlan(planId)
            this.title = plan.title
            this.planType = plan.type
            this.version = plan.version
            this.pixelId = plan.pixelId
            this.description = plan.description
            this.inDevelopment = plan.inDevelopment
            this.displayOnOrganizationOnly = plan.displayOnOrganizationOnly
            this.organizationId = plan.organizationId || ''
            this.isPublic = plan.isPublic
            this.isLocked = plan.isLocked
            this.permissions = plan.permissions
            this.colors = plan.colors
            this.deadline = plan.deadline
            this.roles = plan.roles
            this.customerDetails = plan.customerDetails

            this.isEditMode = true
            this.snackMessage = ''
            this.plan = plan
            this.paths = observable<Path>(plan.paths)

            if (this.rootStore.authStore.loggedUser) {
                const { id, email } = this.rootStore.authStore.loggedUser
                if (this.permissions.indexOf(email) < 0 && !ids.has(id)) {
                    this.permissions.unshift(email)
                }
                if (this.users.indexOf(email) < 0 && !ids.has(id)) {
                    this.users.unshift(email)
                }
            }
        } catch (err) {
            console.error(err)
        } finally {
            this.isLoading = false
        }

        if (this.rootStore.authStore.loggedUser) {
            this.organizationId = this.rootStore.authStore.loggedUser.organizationId || ''
            this.displayOnOrganizationOnly = !!this.organizationId
            this.shouldShowOrganizationPicker = !this.organizationId
        }
    }

    @action reset = () => {
        this.plan = undefined
        this.isEditMode = false
        this.paths = observable<Path>([new Path('')])

        this.inDevelopment = true
        this.displayOnOrganizationOnly = undefined

        this.title = ''
        this.organizationId = ''
    }

    // TODO: Use separate stroe for messages
    @action removeMessage() {
        this.snackMessage = ''
    }

    @action setDisplayedEvent(event?: PathEvent) {
        this.displayedEvent = event
    }

    @action reorderPaths = (fromIndex: number, toIndex: number) => {
        const [removed] = this.paths.splice(fromIndex, 1)
        this.paths.splice(toIndex, 0, removed)
        this.isFormChanged = true
    }

    @action reorderEvents = (pathIdx: number, fromIndex: number, toIndex: number) => {
        const [removed] = this.paths[pathIdx].events.splice(fromIndex, 1)
        this.paths[pathIdx].events.splice(toIndex, 0, removed)
        this.isFormChanged = true
    }

    @action addPath = () => {
        this.paths.push(new Path(''))
        this.isFormChanged = true
    }

    @action duplicatePath = (path: Path) => {
        let idMap = new Map<string, string>()

        let clone: Path = new Path(`${path.title} clone`, uuidv4(), undefined , undefined, path.settings)
        // The path constructor creates an unnecessary empty event.
        clone.events.shift()
        path.events.forEach((event) => {
            let eventClone: PathEvent = JSON.parse(JSON.stringify(event))
            eventClone._id = uuidv4()
            eventClone.title = `${eventClone.title} clone`
            clone.events.push(eventClone)

            idMap.set(event._id, eventClone._id)
        })
        // This loop makes sure the dependency structure stays the same. 
        // The dependecies of each event in the cloned path are the same as in the original. 
        clone.events.forEach((event) => {
            event.dependencies.forEach((dependency) => {
                if(dependency.availability.afterEvents){
                    dependency.availability.afterEvents.forEach((eventId, index) => {
                        let newId = idMap.get(eventId as any as string)
                        if(newId){
                            dependency.availability.afterEvents![index] = newId as any as PathEvent
                        }
                    })
                }
            })
        })
        this.paths.push(clone)

        this.isFormChanged = true
        this.snackMessage = `Path ${path.title} was duplicate successfully`
    }

    @action removePath = (idx: number) => {
        this.paths.splice(idx, 1)
        this.isFormChanged = true
    }

    @action addEvent = (pathIdx: number) => {
        this.paths[pathIdx].events.push(new PathEvent(''))
        this.isFormChanged = true
    }

    @action duplicateEvent = (pathIdx: number, event: PathEvent) => {
        let clone: PathEvent = JSON.parse(JSON.stringify(event))
        // let clone: PathEvent = Object.assign( Object.create( Object.getPrototypeOf(event)), event)
        clone._id = uuidv4()

        const eventIdx = this.paths[pathIdx].events.findIndex((e) => e._id === event._id)

        this.paths[pathIdx].events = [...this.paths[pathIdx].events.slice(0, eventIdx), clone, ...this.paths[pathIdx].events.slice(eventIdx)] as [
            PathEvent
        ]

        this.isFormChanged = true
        this.snackMessage = `Event ${event.title} was duplicate successfully`
    }

    @action switchPath = (prevPathIdx: number, newPathIdx: number, event: PathEvent) => {
        const eventIdx = this.paths[prevPathIdx].events.findIndex((e) => e._id === event._id)

        this.paths[prevPathIdx].events.splice(eventIdx, 1)
        this.paths[newPathIdx].events.push(event)

        this.isFormChanged = true
        this.snackMessage = `Event ${event.title} moved successfully`
    }

    @action setParallelPath = (sourcePathId: string, targetPathId?: string, prevTargetPathId?: string) => {
        if (targetPathId) {
            const targetIdx = this.paths.findIndex((p) => p._id === targetPathId)

            const prevParallelOfTarget = this.paths[targetIdx].settings.parallelWithPath
            if (prevParallelOfTarget) {
                this.setParallelPath(targetPathId, undefined, prevParallelOfTarget)
            }

            this.paths[targetIdx].settings.parallelWithPath = sourcePathId
        }

        if (prevTargetPathId) {
            const targetIdx = this.paths.findIndex((p) => p._id === prevTargetPathId)

            this.paths[targetIdx].settings.parallelWithPath = undefined
        }
    }

    @action setTitle = (element: Path | PathEvent | undefined, title: string) => {
        if (element) {
            element.title = title
        } else {
            this.title = title
        }
        this.isFormChanged = true
    }

    @action setDescription = (text: string) => {
        this.description = text
        this.isFormChanged = true
    }

    @action togglePermissionsModal = () => {
        this.isPermissionsDialogShown = !this.isPermissionsDialogShown
    }

    @action setLengthType = (event: Event) => {
        this.currentLengthTypeIdx = parseInt((event.target as HTMLOptionElement).value)
        this.isFormChanged = true
    }

    @action setInDevelopment = (state: boolean) => {
        this.inDevelopment = state
        this.isFormChanged = true
    }

    @action setIsPublic = (state: boolean) => {
        this.isPublic = state
        this.isFormChanged = true
    }

    @action setIsLocked = (state: boolean) => {
        this.isLocked = state
        this.isFormChanged = true
    }

    initColors = () => {
        this.colors = {
            title: DEFAULT_TITLE_COLOR,
            description: DEFAULT_DESC_COLOR,
            main: DEFAULT_MAIN_COLOR
        }
    }

    // TODO: implement
    clearDescriptionColor = () => {
        if (this.colors) {
            this.colors.background = undefined
        }
    }

    @action setColor = (key: keyof PlanColors, value: string) => {
        if (this.colors === undefined) {
            this.initColors()
        }
        this.colors![key] = value
    }

    @action setDeadline = (type: PlanDeadlineType, value: number) => {
        this.deadline = new PlanDeadline(type, value)
    }

    // Roles
    @action addPlanRole = (roleId: string, isAdmin: boolean, isEditor: boolean, isExternal: boolean, uniqIdentifier?: string) => {
        this.roles = this.roles || []
        this.roles.push({ roleId, isAdmin, isEditor, isExternal, uniqIdentifier })
    }

    @action setRoleAdmin = (roleAtIndex: number, isAdmin: boolean) => {
        this.roles![roleAtIndex] = { ...this.roles![roleAtIndex], isAdmin, isExternal: false }
    }

    @action setRoleEditor = (roleAtIndex: number, isEditor: boolean) => {
        this.roles![roleAtIndex] = { ...this.roles![roleAtIndex], isEditor, isExternal: false }
    }

    @action setRoleExternal = (roleAtIndex: number, isExternal: boolean) => {
        this.roles![roleAtIndex] = {
            ...this.roles![roleAtIndex],
            isAdmin: false,
            isEditor: false,
            isExternal
        }
    }

    @action removeRole = (roleAtIndex: number) => {
        this.roles!.splice(roleAtIndex, 1)
    }

    // Customer Details
    @action addRequestedCustomerDetail = (fieldId: string, isRequired: boolean) => {
        this.customerDetails.push({ fieldId, isRequired })
    }

    @action removeRequestedCustomerDetail = (fieldId: string) => {
        const index = this.customerDetails.findIndex(c => c.fieldId === fieldId)

        this.customerDetails.splice(index, 1)
    }

    @action setCustomerDetailRequired = (fieldId: string, isRequired: boolean) => {
        const index = this.customerDetails.findIndex(c => c.fieldId === fieldId)
        this.customerDetails[index].isRequired = isRequired
    }

    // Deadline
    public getDeadlineTime = (): number => {
        if (this.deadline) {
            return this.deadline.value
        } else {
            return 0
        }
    }

    public getDeadlineType = (): PlanDeadlineType | undefined => {
        if (this.deadline) {
            return this.deadline.type
        }
    }

    @action addPermission = (mail: string) => {
        this.permissions.push(mail)
        this.isFormChanged = true
    }

    @action removePermission = (index: number) => {
        this.permissions.splice(index, 1)
        this.isFormChanged = true
    }

    @action addUser = (mail: string) => {
        this.users.push(mail)
        this.isFormChanged = true
    }

    @action removeUser = (index: number) => {
        this.users.splice(index, 1)
        this.isFormChanged = true
    }

    @action setDisplayOnOrganizationOnly = (state: boolean) => {
        this.displayOnOrganizationOnly = state
        this.isFormChanged = true
    }

    @action setPlanType = (type: PlanTypeEnum) => {
        this.planType = type
        this.isFormChanged = true
    }

    @action setVersion = (version: number) => {
        this.version = version
        this.isFormChanged = true
    }

    @action setPixelId = (pixelId: string) => {
        this.pixelId = pixelId
        this.isFormChanged = true
    }

    @computed get currentLengthType() {
        return timeTypes[this.currentLengthTypeIdx]
    }

    async submit() {
        this.snackMessage = `Saving...`

        const paths = this.removeUndefined(toJS(this.paths))

        console.log(paths)

        if (this.isEditMode) {
            try {
                addPlanToUser(this.rootStore.authStore.loggedUser!.id, this.plan!._id)
            } catch (err) {
                console.error('Failed to add plan to user')
            }

            this.updatePlan(paths)
            this.isFormChanged = false
        } else {
            // Generate id
            this.plan = new Plan(this.title)

            try {
                await addPlanToUser(this.rootStore.authStore.loggedUser!.id, this.plan._id)
            } catch (err) {
                console.error('Failed to add plan to user')
            }

            const plan: Plan = {
                _id: this.plan!._id,
                title: this.title,
                version: this.version,
                pixelId: this.pixelId,
                description: this.description,
                type: this.planType,
                lastUpdate: Date.now(),
                inDevelopment: this.inDevelopment,
                isPublic: this.isPublic,
                organizationId: this.organizationId,
                isLocked: this.isLocked,
                permissions: this.permissions,
                colors: this.colors,
                deadline: this.deadline,
                roles: this.roles,
                customerDetails: this.customerDetails,
                users: this.users,
                paths
            }

            // In order to avoid adding undefined to firebase which cause problems
            if (this.displayOnOrganizationOnly) {
                plan.displayOnOrganizationOnly = true
            }

            // Wait for addPlanToUser
            setTimeout(async () => {
                try {
                    await savePlan(plan)

                    await savePlanPreview(getPlanPreview(plan))

                    this.isEditMode = false
                    this.isFormChanged = false
                    this.snackMessage = `Plan ${this.title} was addded successfully`
                } catch (err) {
                    console.log(err)
                    this.snackMessage = `Failed to add plan. No permissions?`
                }
            }, 1000)
        }
    }

    duplicatePlan = async (planId?: string, isFromTemplate?: boolean): Promise<string> => {
        const newId = uuidv4()

        let plan: Plan

        if (!planId) {
            const paths = this.removeUndefined(toJS(this.paths))

            plan = {
                _id: newId,
                title: `${this.title} - cloned`,
                version: this.version,
                pixelId: this.pixelId,
                description: this.description,
                type: this.planType,
                lastUpdate: Date.now(),
                inDevelopment: this.inDevelopment,
                isPublic: this.isPublic,
                organizationId: this.organizationId,
                isLocked: this.isLocked,
                permissions: this.permissions,
                deadline: this.deadline,
                roles: this.roles,
                customerDetails: this.customerDetails,
                colors: this.colors,
                users: this.users,
                paths
            }
            // In order to avoid adding undefined to firebase which cause problems
            if (this.displayOnOrganizationOnly) {
                plan.displayOnOrganizationOnly = true
            }
        } else {
            plan = await plansClient.getPlan(planId)
            plan._id = newId
            plan.title = `${plan.title} - cloned`
            plan.organizationId = this.rootStore.authStore.loggedUser!.organizationId || ''
            plan.type = PlanTypeEnum.Single
        }

        try {
            await addPlanToUser(this.rootStore.authStore.loggedUser!.id, newId)
            // Wait for addPlanToUser
            await this.timeout(800)
        } catch (err) {
            console.error('Failed to add plan to user')
        }

        try {
            await savePlan(plan)

            await savePlanPreview(getPlanPreview(plan))

            this.isEditMode = false
            if (!isFromTemplate) {
                this.snackMessage = `Plan ${this.title} was duplicated successfully`
            } else {
                this.snackMessage = `We're Creating Your New Instance of ${plan.title}`
            }
        } catch (err) {
            console.log(err)
            this.snackMessage = `Failed to create plan. No permissions?`
        }

        return newId
    }

    async updatePlan(paths: [Path]) {
        try {
            const plan = {
                _id: this.plan!._id,
                title: this.title,
                version: this.version,
                pixelId: this.pixelId,
                description: this.description,
                type: this.planType,
                lastUpdate: Date.now(),
                inDevelopment: this.inDevelopment,
                isPublic: this.isPublic,
                organizationId: this.organizationId,
                displayOnOrganizationOnly: this.displayOnOrganizationOnly,
                isLocked: this.isLocked,
                permissions: this.permissions,
                colors: this.colors,
                deadline: this.deadline,
                roles: this.roles,
                customerDetails: this.customerDetails,
                users: this.users,
                paths
            }

            try {
                await updatePlan(plan)
                await savePlanPreview(getPlanPreview(plan))
                this.snackMessage = `Plan ${this.title} was edited successfully`
            } catch (err) {
                console.error(err)

                this.snackMessage = `Failed to save ${this.title}. No permissions?`
            } finally {
                this.isLoading = false
            }
        } catch (err) {
            console.log(err)
            this.snackMessage = `Failed to update. No permissions?`
        }
    }

    getEventById = (id: string): PathEvent | undefined => {
        this.paths.forEach((p) => {
            p.events.forEach((e) => {
                if (e._id === id) {
                    return e
                }
            })
        })
        return
    }

    getDependedEvents = (event: PathEvent): PathEvent[] => {
        const dependedEvents: PathEvent[] = []

        for (let p of this.paths) {
            for (let e of p.events) {
                e.dependencies.forEach((d) => {
                    // Dependency events are actually the event ids
                    if (d.availability.afterEvents && d.availability.afterEvents.some((eId) => (eId as any as string) === event._id)) {
                        dependedEvents.push(e)
                    }
                })
            }
        }

        // Return uniq
        return Array.from(new Set(dependedEvents))
    }

    removeUndefined = (obj: any) => {
        for (let i in obj) {
            if (obj[i] && ['Dependency', 'Availability', 'Appearance', 'Location', 'Action', 'e'].includes(obj[i].constructor.name)) {
                obj[i] = Object.assign({}, obj[i])
            }

            if (obj[i] === undefined) {
                Reflect.deleteProperty(obj, i)
            } else if (typeof obj[i] === 'object') {
                this.removeUndefined(obj[i])
            }
        }

        return obj
    }

    getPropertyByPath = (fullPath: string) => {
        const pathParts = fullPath.split('/')
        const parentProp = propFromPath(this.paths, pathParts)
        return parentProp[pathParts[pathParts.length - 1]]
    }

    @action setProperty<T>(fullPath: string, data: T) {
        const pathParts = fullPath.split('/')
        const parentProp = propFromPath(this.paths, pathParts)
        const propToSet = pathParts[pathParts.length - 1]

        parentProp[propToSet] = data
        this.isFormChanged = true
    }

    @action addProperty<T>(fullPath: string, data: T) {
        const pathParts = fullPath.split('/')
        const parentProp = propFromPath(this.paths, pathParts)
        const propToSet = pathParts[pathParts.length - 1]

        parentProp[propToSet].push(data)
        this.isFormChanged = true
    }

    @action removePropertyFromArray<T>(fullPath: string, index: number) {
        const pathParts = fullPath.split('/')
        const parentProp = propFromPath(this.paths, pathParts)
        const propToSet = pathParts[pathParts.length - 1]

        parentProp[propToSet].splice(index, 1)
        this.isFormChanged = true
    }

    @action removeProperty(fullPath: string) {
        const pathParts = fullPath.split('/')
        const parentProp = propFromPath(this.paths, pathParts)
        const propToDelete = pathParts[pathParts.length - 1]

        Reflect.deleteProperty(parentProp, propToDelete)
        this.isFormChanged = true
    }

    timeout(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms))
    }
    async sleep(fn: (...args: any[]) => any, ...args: any) {
        await this.timeout(3000)
        return fn(...args)
    }
}

export default CreateStore
