
enum PlanTypeEnum {
    Single,
    Multi,
    Template,
    Sharing,
    Sales,
    MultiUsers
}

const PlanTypeTypes = {
    [PlanTypeEnum[0]]: { label: 'Single Customer', idx: 0 },
    [PlanTypeEnum[1]]: { label: 'Multi Customers', idx: 1 },
    [PlanTypeEnum[2]]: { label: 'Template', idx: 2 },
    [PlanTypeEnum[3]]: { label: 'Customer/Client (active plan is shared with you)', idx: 3 },
    [PlanTypeEnum[4]]: { label: 'Sales (with analytics)', idx: 4 },
    [PlanTypeEnum[5]]: { label: 'Multi Users', idx: 5 },
}

const PlanTypeOptions = Object.values(PlanTypeTypes)

export { PlanTypeOptions, PlanTypeTypes, PlanTypeEnum }