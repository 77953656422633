import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { action, observable } from 'mobx'

import TextField from '@material-ui/core/TextField'

import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import CreateStore from '../../stores/create.store'
import InputComponent from './input-component'

import { Location }  from '../../models/Event.model'
import { locationOptions, LocationsEnum } from '../constants/location-types';

interface LocationSelectorComponentProps {
    locationPath: string
    createStore? : CreateStore
}

@inject((stores) => ({
    createStore: (stores as any).createStore as CreateStore,
}))
@observer
class LocationSelectorComponent extends Component<LocationSelectorComponentProps, object> {
  @observable locationType: number = LocationsEnum.Area

  constructor(props: LocationSelectorComponentProps) {
    super(props)

    const { createStore, locationPath } = props

    const location: Location = createStore!.getPropertyByPath(locationPath)

    if (location) {
      this.locationType = (createStore!.getPropertyByPath(locationPath) as Location).type
    } else {
      createStore!.setProperty(locationPath, new Location(this.locationType))
    }
  }

  // componentDidMount() {
  //   const { locationPath, createStore } = this.props
  //   createStore!.setProperty(locationPath, new Location(this.locationType))
  // }

  @action onLocationTypeSelected = (selectEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { locationPath, createStore } = this.props
    this.locationType = parseInt(selectEvent.target.value)
    
    createStore!.setProperty(locationPath, new Location(this.locationType))
  }

  // setArea = (prop: 'lat' | 'lng' | 'radius') => (event: React.ChangeEvent<HTMLInputElement>) => {
  //   let { locationPath, createStore } = this.props
  //   const pathToProp = `${locationPath}/area/${prop}` 
  //   createStore!.setProperty(pathToProp, parseInt(event.target.value))
  // }

  render() {
    const { locationPath } = this.props

    return (
        <span>
        <TextField
              id='location-type'
              select
              label='Type'
              margin='normal'
              value={this.locationType}
              onChange={this.onLocationTypeSelected}
            >
              {locationOptions.map((option: any, idx: number) => (
                <MenuItem key={`lt${idx}`} value={idx}>
                  {option.label}
                </MenuItem>
              ))}
        </TextField>
        { this.locationType === LocationsEnum.Area && 
            <span>
            <InputComponent
                id='lat'
                label={'lat'}
                type='number'
                required
                InputLabelProps={{
                shrink: true,
                }}
                style={{minWidth: 50}}
                margin='normal'
              fullPath={`${locationPath}/area/lat` }
            />
            <InputComponent
                id='lng'
                label={'lng'}
                type='number'
                required
                InputLabelProps={{
                shrink: true,
                }}
                style={{minWidth: 50}}
                margin='normal'
                fullPath={`${locationPath}/area/lng` }
            />
            <InputComponent
                id='radius'
                label={'radius'}
                type='number'
                required
                InputLabelProps={{
                shrink: true,
                }}
                style={{minWidth: 50}}
                margin='normal'
              fullPath={`${locationPath}/area/radius` }
            />
            </span>
        }
        </span>
    )
  }
}

export default LocationSelectorComponent
