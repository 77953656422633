import { observable } from 'mobx'
import { LocationsEnum } from '../utils/constants/location-types'
import { ActionsEnum } from '../utils/constants/action-types'
import { EventTypesEnum } from '../utils/constants/event-types'
import { PlanDeadline } from './Plan-deadline.model'

// import uuidv4 from 'uuid/v4'
// Fix 
const uuidv4 = require('uuid/v4')

export const DEFAULT_EVENT_END = 5

export default class Event {
    _id: string
    @observable title: string
    @observable dependencies: [Dependency]
    @observable deadline?: PlanDeadline

    @observable roles?: EventRoles

    settings: Settings
    content: Content

    comment: String = ''

    constructor(title: string, _id?: string, dependencies?: [Dependency],
        content?: Content, setting?: Settings, deadline?: PlanDeadline,
        roles?: EventRoles) {
        this._id = _id || uuidv4()
        this.title = title

        this.dependencies = dependencies || [new Dependency()]
        this.content = content || new Content()
        this.settings = setting || new Settings()
        this.deadline = deadline

        this.roles = roles
    }
}

export class EventRoles {
    @observable executors: string[] // All executors are also notified
    @observable viewers: string[]
    @observable notified: string[] // All notified are also viewers

    constructor(executors: string[], viewers: string[], notified: string[]) {
        this.executors = executors
        this.viewers = viewers
        this.notified = notified
    }
}

export class Settings {
    @observable shouldShowOnPreview: boolean
    @observable shouldDisplayOverWeb: boolean

    constructor(shouldShowOnPreview?: boolean, shouldDisplayOverWeb?: boolean) {
        this.shouldShowOnPreview = shouldShowOnPreview == undefined ? true : shouldShowOnPreview
        this.shouldDisplayOverWeb = shouldDisplayOverWeb || false
    }
}

export class Dependency {
    availability: Availability
    appearance: Appearance

    constructor(availability?: Availability, appearance?: Appearance) {
        this.availability = availability || new Availability()
        this.appearance = appearance || new Appearance()
    }
}

export class Availability {
    @observable afterTime: number
    @observable afterEvents?: [Event]
    @observable eventEndIdx?: number // in case of after event

    constructor(time?: number, events?: [Event], eventEndIdx?: number) {
        this.afterTime = time || 0
        this.afterEvents = events
        this.eventEndIdx = eventEndIdx
    }
}

export class Appearance {
    @observable time: number
    @observable location?: Location

    constructor(time?: number, location?: Location) {
        this.time = time || 0
        this.location = location
    }
}

export class Location {
    type: LocationsEnum
    area?: { lat: number, lng: number, radius: number }

    constructor(type: LocationsEnum) {
        this.type = type

        if (type === LocationsEnum.Area) {
            this.area = { lat: 0, lng: 0, radius: 1 }
        }
    }
}

export class Content {
    type: EventTypesEnum
    @observable message: string
    @observable actions?: Action[]
    @observable comment?: string
    @observable form?: EventFormItem[]

    constructor(type?: EventTypesEnum, message?: string, actions?: Action[],
        comment?: string, form?: EventFormItem[]) {
        this.type = type == undefined ? EventTypesEnum.WithActions : type
        this.message = message || ''
        this.actions = actions
        this.comment = comment
        this.form = form
    }
}

export class Action {
    @observable label: string
    type: ActionsEnum
    @observable link?: string
    @observable call?: string
    @observable end?: string
    @observable repeatIn?: number
    @observable remindIn?: number
    @observable eventFiles?: number // in case of FinishAndAddTheFiles, the eventId which the files belong to
    @observable note?: Note

    constructor(label?: string, type?: ActionsEnum, link?: string, call?: string,
        repeatIn?: number, remindIn?: number, end?: string, eventFiles?: number,
        note?: Note) {
        this.label = label || ''
        this.type = type == undefined ? ActionsEnum.End : type
        this.link = link
        this.call = call
        this.end = end
        this.repeatIn = repeatIn
        this.remindIn = remindIn
        this.eventFiles = eventFiles
        this.note = note
    }
}

export class Ends {
    when: number
    with?: EndsWith

    constructor(when: number, _with?: EndsWith) {
        this.when = when
        this.with = _with || ''
    }
}


interface EndsWith { }

export class EndsWithQuestion implements EndsWith {
    constructor(question: Question) { }
}

export class EndsWithNothing implements EndsWith {
    constructor() { }
}

export class Question {
    @observable public answers: [Answer]
    constructor(
        public title: string,
        public content: string,
        answers: [Answer]
    ) {
        this.answers = answers
    }
}

export class Answer {
    constructor(
        public option: string,
        public action?: EndsAction | undefined
    ) { }
}

interface EndsAction { }

export class RepeatAction implements EndsAction {
    constructor(public repeatIn: number) { }
}

export class GoToAction implements EndsAction {
    constructor(public goTo?: Event) { }
}

export class EventFormItem {
    @observable key: string
    @observable type: string // Any html5 input type prop
    @observable isMultiLine: boolean
    @observable required: boolean

    constructor(key: string, type: string = 'text', isMultiLine: boolean = false,
        required: boolean = false) {

        this.key = key
        this.type = type
        this.isMultiLine = isMultiLine
        this.required = required
    }
}

export class Note {
    @observable template?: string
    @observable signatures?: string[]
    @observable withLogo: boolean = false

    constructor(withLogo: boolean = false, template?: string, signatures?: string[]) {
        this.withLogo = withLogo
        this.template = template
        this.signatures = signatures
    }
}

export { }