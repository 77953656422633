import React, { Component } from 'react'
import { reaction } from 'mobx'
import { inject, observer } from 'mobx-react'

import { RouteComponentProps } from 'react-router'
import { WithTranslation, withTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import { Card, CardContent, CircularProgress, Button, Tabs, Tab } from '@material-ui/core'
import { AuthTabsEnum } from '../../../utils/enums/auth-tabs.enum'

import AuthStore from '../../../stores/auth.store'

import SignInComponent from './components/signin.component'
import LogInComponent from './components/login.component'

import './auth.style.scss'

interface AuthScreenProps extends RouteComponentProps, WithTranslation  {
  authStore: AuthStore
}

@inject((stores) => ({
  authStore: (stores as any).authStore as AuthStore,
}))
@observer
class AuthScreen extends Component<AuthScreenProps, any> {

  constructor(props: AuthScreenProps) {
    super(props)

    // React to user authenticate
    reaction(
      () => props.authStore.loggedUser,
      (loggedUser, reaction) => {

        const { from } = props.location.state as any || { from: { pathname: '/' } }

        if (loggedUser && from.pathname) {
          props.history.replace(from)
        }

        reaction.dispose()
      }
    )
  }
  componentDidMount = () => {
  }

  componentWillUnmount = () => {
  }

  componentDidUpdate(prevProps: AuthScreenProps, prevState: {}) {

  }

  onTabChanged = (event: React.ChangeEvent<{}>, value: number) => {
    this.props.authStore.setSelectedTab(value as AuthTabsEnum)
  }

  render() {
    const { t, authStore } = this.props
    const { selectedTab, isLoading } = authStore

    return (
      <div id="load">
        {isLoading ?
          <Grid container
            style={{ padding: "150px" }}
            alignItems={'center'}
            justify={'center'}
            className='loading-container'>
            <CircularProgress color="secondary" />
          </Grid>
          :
          <Grid container
            alignItems={'center'}
            direction={'column'}
            className='auth-screen'>

            <Grid item xs={12} sm={8} className='content'>
              <Card>
                <Tabs value={selectedTab} onChange={this.onTabChanged}>
                  <Tab label={t('login')} id={'login-tab'} />
                  <Tab label={t('signin')} id={'signin-tab'} />
                </Tabs>

                <CardContent className='tab-content'>
                  {selectedTab === AuthTabsEnum.SIGN_IN && <SignInComponent />}
                  {selectedTab === AuthTabsEnum.LOGIN && <LogInComponent />}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        }
      </div>)
  }
}

export default withTranslation('authScreen')(AuthScreen)
