import { action, observable } from 'mobx'
import firebase from 'firebase'

import { SignInUser, User } from '../models/User.model'
import RootStore from './root.store'
import { AuthTabsEnum } from '../utils/enums/auth-tabs.enum'
import { addUser, getUser, addPlanToUser, setOrganization } from '../utils/dal/users.dal'
import { savePlan } from '../utils/dal/plans.dal'
import { savePlanPreview } from '../utils/dal/plan-preview.dal'
import { getPlanPreview } from '../utils/plan.utils'
import Organization from '../models/settings/Organization.model'
import { getOrganizations, getOrganizationById } from '../utils/dal/organizations.dal'
import plansClient from '../utils/client/plans.client'

const uuidv4 = require('uuid/v4')

export const SESSION_TOKEN_KEY = 'session_token'

const EMAIL_REG = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
const PASSWORD_REG = /w*/g
const MIN_PASSWORD_LENGTH = 6

class AuthStore {
    @observable loggedUser?: User

    @observable signInObject: SignInUser = new SignInUser('', '')

    @observable isLoading = false
    @observable isNewUser = false

    userOrganizationId?: string

    @observable selectedTab: AuthTabsEnum = 0

    constructor(private rootStore: RootStore) {
        const user = firebase.auth().currentUser

        // if (user) {
        //     this.onUserLoggedIn(user)
        // }

        firebase.auth().onAuthStateChanged(this.onAuthStateChanged)

        // const token = localStorage.getItem(SESSION_TOKEN_KEY)
    }

    @action
    setSelectedTab = (index: AuthTabsEnum) => {
        this.selectedTab = index
    }


    @action onAuthStateChanged = async (user: firebase.User | null) => {

        if (user) {
            console.log(user.uid)
            await this.onUserLoggedIn(user)
            await this.rootStore.settingsStore.init()
            await this.rootStore.mainStore.init()
        } else {
            this.loggedUser = undefined
            this.rootStore.settingsStore.isLoading = false
        }

        if (this.loggedUser) {
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        } else {
            localStorage.removeItem(SESSION_TOKEN_KEY)
        }
    }

    @action
    updateSignInData = (key: 'email' | 'password' | 'name', val: any) => {
        this.signInObject[key] = val

        if (key === 'email') {
            this.signInObject.isEmailValid = EMAIL_REG.test(val)
        } else if (key === 'password') {
            this.signInObject.isPasswordValid = !!val && val.length >= MIN_PASSWORD_LENGTH && PASSWORD_REG.test(val)
        }

        this.signInObject.isUserValid = !!this.signInObject.name && !!this.signInObject.password && !!this.signInObject.email &&
            this.signInObject.isPasswordValid && this.signInObject.isEmailValid
    }

    @action signIn = async () => {
        const { email, password, name } = this.signInObject

        try {
            const { user, additionalUserInfo } = await firebase.auth().createUserWithEmailAndPassword(email, password!)

            if (user && additionalUserInfo && additionalUserInfo.isNewUser) {
                await this.addNewUser(user, name)
            } else {
                await this.onUserLoggedIn(user!)
            }

        } catch (error) {
            console.warn((error as any).code, (error as any).message)
        }
    }

    @action logIn = async () => {
        const { email, password, name } = this.signInObject

        try {
            this.isLoading = true
            const { user, additionalUserInfo } = await firebase.auth().signInWithEmailAndPassword(email, password!)

            if (user && additionalUserInfo && additionalUserInfo.isNewUser) {
                await this.addNewUser(user, name)
            } else {
                await this.onUserLoggedIn(user!)
            }

        } catch (error) {
            console.warn((error as any).code, (error as any).message)
        } finally {
            this.isLoading = false
        }
    }

    @action logInUsingGoogle = async () => {
        try {
            const provider = new firebase.auth.GoogleAuthProvider()

            const { user, additionalUserInfo } = await firebase.auth().signInWithPopup(provider)

            console.log(user!.uid)
            if (user && additionalUserInfo && additionalUserInfo.isNewUser) {
                await this.addNewUser(user, user.displayName!)
            } else {
                await this.onUserLoggedIn(user!)
            }
        } catch (error) {
            console.warn((error as any).code, (error as any).message, (error as any).credential, (error as any).email)
        }
    }

    signOut = () => {
        firebase.auth().signOut()
    }

    @action addNewUser = async (user: firebase.User, name?: string) => {
        const userName = name || user.displayName || user.email!.split('@')[0]
        const newUser = new User(user.uid, userName, user.email!)

        const token = await user.getIdToken()
        localStorage.setItem(SESSION_TOKEN_KEY, token)

        this.loggedUser = newUser
        await addUser(newUser)

        // const plan = await plansClient.getPlan('926ef9bc-6ba3-455b-834e-66563dcd74ab')

        // plan._id = uuidv4()
        // delete plan.organizationId
        // plan.isPublic = false

        // await savePlan(plan)

        this.isNewUser = true

        // const preview = getPlanPreview(plan)
        // await savePlanPreview(preview)
        // this.rootStore.mainStore.addPlan(preview)
        // await addPlanToUser(newUser.id, plan._id)
    }

    @action setIsNewUser = (state: boolean) => {
        this.isNewUser = state
    }

    @action setOrganization = async (organization: Organization) => {
        if (this.loggedUser) {
            this.loggedUser.organizationId = organization._id
            await setOrganization(this.loggedUser.id, organization._id)
        } else {
            console.warn('No logged user')
        }
    }

    @action onUserLoggedIn = async (user: firebase.User) => {
        const token = await user.getIdToken()
        localStorage.setItem(SESSION_TOKEN_KEY, token)

        this.loggedUser = await getUser(user!.uid)

        if (this.loggedUser && this.loggedUser.organizationId) {
            const organization = await getOrganizationById(this.loggedUser.organizationId)

            this.rootStore.organizationStore.setOrganization(organization)
        }
    }

    clearSignInObject = (): void => {
        this.signInObject = new SignInUser('', '')
    }
}

export default AuthStore
