
import { AbstractModelFactory } from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'
import { EventEndPortModel } from './end-port.model'

export class EventEndPortFactory extends AbstractModelFactory<EventEndPortModel, DiagramEngine> {
	constructor() {
		super('event-end')
	}

	generateModel(): EventEndPortModel {
		return new EventEndPortModel({
			name: 'event-end'
		})
	}
}
