import React, { useCallback, useEffect } from 'react'

import { inject, observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button, LinearProgress } from '@material-ui/core'

import { Content, EventFormItem, Action } from '../../../../../../models/Event.model'
import CreateStore from '../../../../../../stores/create.store'
import InputComponent from '../../../../../../utils/components/input-component'
import { FromItemComponent } from './form-item.component'
import ActionComponent from '../action.component'

interface FormComponentProps {
    content: Content
    fullPath: string
    createStore?: CreateStore
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formContainer: {

        },
        childrenContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: '4px 0',
            borderLeft: 'solid 1px',
            marginLeft: '60px'
        }
    }),
)

interface FormComponentProps {
    content: Content
    fullPath: string
    createStore?: CreateStore
}

const FormComponent = inject((stores) => ({
    createStore: (stores as any).createStore as CreateStore,
}))(
    observer((props: FormComponentProps) => {
        const { createStore, fullPath, content } = props
        const classes = useStyles()
        const { t } = useTranslation('event')

        const addFormItem = useCallback(() => {
            if (!content.actions) {
                createStore!.setProperty(`${fullPath}/form`, [new EventFormItem('')])
            } else {
                createStore!.addProperty(`${fullPath}/form`, new EventFormItem(''))
            }
        }, [])

        const removeFormItem = useCallback((idx: number) => {
            createStore!.removePropertyFromArray(`${fullPath}/form`, idx)
        }, [])

        useEffect(() => {
            if (!content.actions || !content.actions.length) {
                createStore!.setProperty(`${fullPath}/actions`, [new Action()])
            }
            if (!content.form) {
                createStore!.setProperty(`${fullPath}/form`, [new EventFormItem('')])
            }
        })

        return <div className={classes.formContainer + ' event-box-border'}>
            <label className={'event-box-label'}>Form</label>
            <InputComponent
                id='message'
                label='Message'
                multiline
                variant='outlined'
                fullWidth
                rows='2'
                margin='normal'
                fullPath={`${fullPath}/message`}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            {!!content.form && content.form.map((formItem, index) =>
                <FromItemComponent
                    key={index} dataKey={index}
                    fullPath={`${fullPath}/form/${index}`}
                    formItem={formItem}
                    removeItem={() => removeFormItem(index)}    
                />
            )}

            <Button fullWidth onClick={addFormItem}>
                {t('addFormItem')}
            </Button>


            {!!content.actions && content.actions.length &&
                <InputComponent
                    id='submitLabel'
                    label='Submit Label'
                    variant='outlined'
                    fullWidth
                    required
                    margin='normal'
                    fullPath={`${fullPath}/actions/0/label`}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />}
        </div>
    }),
)

export { FormComponent }
