import { Checkbox, FormControlLabel, IconButton, MenuItem, TextField, Tooltip, Typography } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../../stores/root.store'

import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
const uuidv4 = require('uuid/v4')

const EXTERNAL_ROLE_ID = '-1'

export const PlanRolesPicker = observer(() => {
    const store = useStore('createStore')
    const organization = useStore('organizationStore').organization
    const { t } = useTranslation('roles')

    const [newRole, setNewRole] = useState('')
    const [externalRoleIdentifier, setExternalRoleIdentifier] = useState('')

    const addNewRole = () => {
        if (newRole === EXTERNAL_ROLE_ID) {
            store.addPlanRole(uuidv4(), false, false, true, externalRoleIdentifier)
        } else {
            store.addPlanRole(newRole, (store.roles || []).length === 0, false, false)
        }
        setNewRole('')
    }

    return (
        <div className={'event-box-border'} style={{ display: 'flex', flexDirection: 'column' }}>
            <label className={'event-box-label'}>Roles</label>
            {(store.roles || [])
                .map((r) => ({ ...r, ...organization!.roles.find((role) => role.id === r.roleId)! }))
                .map((role, index) => (
                    <span key={index} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography style={{ margin: '4px 16px 4px 8px' }}>{role.isExternal ? role.uniqIdentifier : role.text!}</Typography>
                        <FormControlLabel
                            style={{ fontSize: 10 }}
                            control={<Checkbox checked={role.isAdmin} onChange={(e, checked) => store.setRoleAdmin(index, checked)} />}
                            label={t('isAdmin')}
                            disabled={role.isExternal}
                        />
                        <Tooltip title={t('isEditorExplained') as string}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={role.isAdmin || role.isEditor}
                                        onChange={(e, checked) => store.setRoleEditor(index, checked)}
                                    />
                                }
                                disabled={role.isAdmin || role.isExternal}
                                label={t('isEditor')}
                            />
                        </Tooltip>
                        <Tooltip title={t('isExternalExplained') as string}>
                            <FormControlLabel
                                control={<Checkbox checked={role.isExternal} onChange={(e, checked) => store.setRoleExternal(index, checked)} />}
                                disabled
                                label={t('isExternal')}
                            />
                        </Tooltip>

                        <IconButton aria-label="delete" onClick={() => store.removeRole(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </span>
                ))}

            <span style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                    id="role-selector"
                    select
                    label="Select Role"
                    value={newRole}
                    onChange={(event) => setNewRole(event.target.value)}
                    style={{ minWidth: '200px', marginBottom: '10px' }}
                >
                    {organization!.roles.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.text}
                        </MenuItem>
                    ))}
                    <MenuItem key={'-1'} value={EXTERNAL_ROLE_ID}>
                        {t('customer')}
                    </MenuItem>
                </TextField>

                {newRole === EXTERNAL_ROLE_ID && (
                    <TextField
                        id="identifier-picker"
                        label={t('identifier')}
                        value={externalRoleIdentifier}
                        onChange={(event) => setExternalRoleIdentifier(event.target.value)}
                        style={{ minWidth: '200px', marginBottom: '10px' }}
                    ></TextField>
                )}

                <IconButton
                    disabled={!newRole || (newRole === EXTERNAL_ROLE_ID && !externalRoleIdentifier)}
                    aria-label="expand row"
                    size="small"
                    onClick={addNewRole}
                >
                    <AddIcon />
                </IconButton>
            </span>
        </div>
    )
})
