import db, { firebase } from './../firebase'
import Organization, { PlansGroup } from '../../models/settings/Organization.model'
import { User } from '../../models/User.model'
import { OrganizationCustomerField } from '../../models/settings/CustomerField'

const uuidv4 = require('uuid/v4')

const IDENTIFIER_ID = '99'
const FIRST_NAME_ID = '100'
const LAST_NAME_ID = '101'
const PHONE_ID = '102'
const EMAIL_ID = '103'

const ids = new Set(['6ZMImFWGdrfbtR4cqjQpgvCNkP83', 'B4bjYG3oXBTjlAszBPxsTX1LLFt2', 'OihjxWbRuPahtC1S2Q2VMJxlfMy2'])

const storage = firebase.storage()

const buildOrganization = (organizationsData: any): Organization => {
    let groups

    if (organizationsData.groups && organizationsData.groups.length) {
        groups = organizationsData.groups.map((g: any) => new PlansGroup(g._id, g.name, g.planIds))
    }

    let customersFields: OrganizationCustomerField[] = organizationsData.customersFields
    if (!customersFields || !customersFields.length) {
        customersFields = [
            {
                id: IDENTIFIER_ID,
                name: 'identifier',
                type: 'text',
                isDeleted: false,
                canBeDeleted: false
            },
            {
                id: FIRST_NAME_ID,
                name: 'firstName',
                type: 'text',
                isDeleted: false,
                canBeDeleted: false
            },
            {
                id: LAST_NAME_ID,
                name: 'lastName',
                type: 'text',
                isDeleted: false,
                canBeDeleted: false
            },
            {
                id: PHONE_ID,
                name: 'phone',
                type: 'phone',
                isDeleted: false,
                canBeDeleted: false
            },
            {
                id: EMAIL_ID,
                name: 'email',
                type: 'email',
                isDeleted: false,
                canBeDeleted: false
            },
        ]
    }

    return new Organization(organizationsData._id,
        organizationsData.name,
        organizationsData.logo,
        organizationsData.coverLogo,
        organizationsData.docsLogo,
        undefined,
        groups,
        organizationsData.isPublic,
        organizationsData.users,
        organizationsData.roles,
        customersFields)
}

const getOrganizations = async (user: User): Promise<Organization[]> => {

    if (!user.organizationId && !ids.has(user.id)) {
        return Promise.resolve([])
    }

    const query = db.collection('organizationss')

    if (!ids.has(user.id)) {
        query.where('_id', '==', user.organizationId)
    }
    const { docs } = await query.get()

    const organizations = []
    if (docs) {
        for (let doc of docs) {
            const organizationsData = doc.data()
            const organization = buildOrganization(organizationsData)

            organizations.push(organization)
        }
    }

    return organizations
}

const addImage = async (organizationId: string, type: 'logo' | 'cover' | 'docs', image: File): Promise<string> => {
    return new Promise((resolve, reject) => {

        const uploadTask = storage.ref(`/organizations/images/${organizationId}/${type}`).put(image)
        //initiates the firebase side uploading 
        uploadTask.on('state_changed',
            (snapShot) => {
                //takes a snap shot of the process as it is happening
                console.log(snapShot)
            }, (err) => {
                //catches the errors
                console.log(err)
                reject(err)
            }, async () => {
                // gets the functions from storage references the image storage in firebase by the children
                // gets the download url then sets the image from firebase as the value for the imgUrl key:
                const url = await storage.ref('organizations').child(`/images/${organizationId}/${type}`).getDownloadURL()
                resolve(url)
            })
    })
}

const removeImage = async (imageUrl: string) => {
    const ref = firebase.storage().refFromURL(imageUrl)

    const res = await ref.delete()
}

const removeOrganization = async (organizationId: string): Promise<void> => {

    const organizations = await db
        .collection('organizationss')
        .where('_id', '==', organizationId)
        .get();

    const batch = db.batch();

    organizations.forEach(doc => {
        batch.delete(doc.ref)
    });

    await batch.commit();
}

const getOrganizationById = async (organizationId: string): Promise<Organization> => {

    const res = await db
        .collection('organizationss')
        .where('_id', '==', organizationId)
        .get();

    let organization
    if (res && res.docs && res.docs.length) {

        for (let dock of res.docs) {
            organization = buildOrganization(dock.data())
        }
    }
    return organization as Organization
}

export {
    getOrganizationById,
    removeOrganization,
    getOrganizations,
    removeImage,
    addImage
}
