import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import createEngine, {
	DiagramModel,
	DefaultDiagramState,
	LinkModel,
	LinkModelGenerics,
	DiagramEngine
} from '@projectstorm/react-diagrams'

import { CanvasWidget, BaseEntityEvent, BaseEntity, BaseEntityGenerics, BaseModel, BaseEvent } from '@projectstorm/react-canvas-core'
import CreateStore from '../../../../../stores/create.store'

import dagre from 'dagre'

import Event, { DEFAULT_EVENT_END } from './../../../../../models/Event.model'

import { EventNodeFactory } from './graph-utils/event-node.factory'
import { EventNodeModel } from './graph-utils/event-node.model'
import { EventEndPortFactory } from './graph-utils/event-end-port/end-port.factory'
import { ActionsEnum } from '../../../../../utils/constants/action-types'
import { EventTypesEnum } from '../../../../../utils/constants/event-types'

import ZoomInIcon from '@material-ui/icons/ZoomInRounded'
import ZoomOutIcon from '@material-ui/icons/ZoomOutRounded'
import ZoomToFitIcon from '@material-ui/icons/ViewQuiltSharp'

import './graph.style.scss'

// List of actions, which end the event
const eventsEndTypes = [ActionsEnum.End, ActionsEnum.LinkAndFinish, ActionsEnum.FinishAndAddTheFiles]

interface GraphComponentProps {
	createStore?: CreateStore
}

@inject((stores) => ({
	createStore: (stores as any).createStore as CreateStore,
}))
@observer
class GraphComponent extends Component<GraphComponentProps, object> {
	engine: DiagramEngine
	diagramModel: DiagramModel

	eventNodes: { [id: string]: EventNodeModel } = {}

	constructor(props: GraphComponentProps) {
		super(props)


		//1) setup the diagram engine
		this.engine = createEngine()

		this.engine.getNodeFactories().registerFactory(new EventNodeFactory())
		this.engine.getPortFactories().registerFactory(new EventEndPortFactory())


		//2) setup the diagram model
		this.diagramModel = new DiagramModel()


	}

	initGraph = () => {
		const { paths } = this.props.createStore!

		// ############################################ MAGIC HAPPENS HERE
		const state = this.engine.getStateMachine().getCurrentState()
		if (state instanceof DefaultDiagramState) {
			state.dragNewLink.config.allowLooseLinks = false
		}
		// ############################################ MAGIC HAPPENS HERE

		// In order to know the positions of the nodes, we use dagre
		// Create a new directed graph 
		const g = new dagre.graphlib.Graph()

		// Set an object for the graph label
		g.setGraph({})

		// Default to assigning a new object as a label for each new edge.
		g.setDefaultEdgeLabel(function () { return {} })

		paths.forEach(path => {

			path.events.forEach((e) => {

				// Add nodes to the graph. The first argument is the node id. The second is
				// metadata about the node. In this case we're going to add labels to each of
				// our nodes.
				g.setNode(e._id, { e, color: path.color, width: 150, height: 80 })

				e.dependencies.forEach(d => {
					if (d.availability.afterEvents && d.availability.afterEvents.length) {

						d.availability.afterEvents.forEach(ae => {

							// Add edges to the graph. TODO: fix afterEvents type. ea is a string!!!
							g.setEdge(e._id, ae as any as string, { endIdx: d.availability.eventEndIdx })
						})
					}
				})
			})
		})

		// Layout the graph
		dagre.layout(g)


		//3-C) link the 2 nodes together
		const links: LinkModel<LinkModelGenerics>[] = []

		const graphHeight = g.graph().height!

		// Build the ui of the graph
		g.nodes().forEach((v) => {
			const node = g.node(v)

			if (node) {	
				if (!this.eventNodes[v]) {
					const event = (node as any).e as Event
					let title = event.title

					if (event.content.type === EventTypesEnum.Form) {
						title = `${title} 📝`
					}

					this.eventNodes[v] = new EventNodeModel(title, (node as any).color, event._id)
				}
				this.eventNodes[v].setPosition(node.x, graphHeight - node.y)
			}
		})

		// Add the links
		g.edges().forEach((e) => {
			const edge = g.edge(e)
			const eventEnd = (edge as any).endIdx

			const dependsOn = (g.node(e.w) as any).e as Event
			let actionText = ''

			try {
				if (dependsOn!.content.type === EventTypesEnum.WithActions &&
					(eventEnd === undefined || eventEnd === DEFAULT_EVENT_END)) {
					actionText = dependsOn!.content!.actions!.filter(a => eventsEndTypes.includes(a.type))[0].label
				} else {
					actionText = dependsOn!.content!.actions![eventEnd].label
				}
			} catch (err) {
				console.log(err)
			}

			const dependencyPort = this.eventNodes[e.v].addInPort('')
			const eventEndPort = this.eventNodes[e.w].getPorts()[actionText] || this.eventNodes[e.w].addOutPort(actionText)

			links.push(dependencyPort.link(eventEndPort))
		})

		//4) add the models to the root graph
		// model.addAll(node1, node2, link1)
		let models = this.diagramModel.addAll(...Object.values(this.eventNodes), ...links)

		this.diagramModel.setLocked(true)

		//5) load model into engine
		this.engine.setModel(this.diagramModel)

		// add a selection listener to each
		models.forEach(item => {
			const oldListener = item.getListenerHandle({ selectionChanged: this.onSelectionChanged })

			if (oldListener) {
				oldListener.deregister()
			}

			item.registerListener({
				selectionChanged: this.onSelectionChanged
			})
		})

		// setTimeout(this.zoomToFit, 400)

		// model.registerListener({
		// 	eventDidFire: this.onEvent
		// })
	}

	clearGraph = () => {
		this.diagramModel.getNodes().forEach(n => {
			n.clearListeners()

			this.diagramModel.removeNode(n)
		})
		this.diagramModel.getLinks().forEach(l => {
			this.diagramModel.removeLink(l)
		})

		this.eventNodes = {}
	}

	onSelectionChanged = (event: BaseEvent) => {
		// console.log((event as any).entity.options.id)

		// if ((event as any).isSelected) {
		// 	const n = document.querySelector(`[event-id="${(event as any).entity.eventId}"]`)

		// 	if (n) {
		// 		window.scrollTo({ behavior: 'smooth', top: (n! as any).getBoundingClientRect().top - 10 })
		// 	}
		// }
	}

	zoomToFit = () => {
		this.engine.zoomToFitNodes()

		// setTimeout(this.zoomOut, 200)
	}

	zoomIn = () => {
		const zoom = this.diagramModel.getZoomLevel()
		this.diagramModel.setZoomLevel(zoom + 5)
		this.engine.repaintCanvas()
	}

	zoomOut = (event: MouseEvent) => {
		const zoom = this.diagramModel.getZoomLevel()
		this.diagramModel.setZoomLevel(zoom - 5)
		this.engine.repaintCanvas()
	}

	render() {
		this.clearGraph()
		this.initGraph()

		return (
			<div className='graph-container'>
				<div className="buttons">
					<div onClick={this.zoomToFit}><ZoomToFitIcon /></div>
					<div onClick={this.zoomIn}><ZoomInIcon /></div>
					<div onClick={this.zoomOut as any}><ZoomOutIcon /></div>
				</div>
				<CanvasWidget engine={this.engine} className='graph-container' />
			</div>
		)
	}
}

export default GraphComponent
