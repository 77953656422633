import { Button, Checkbox, FormControlLabel, IconButton, Tooltip, Typography } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../../../stores/root.store'

import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import { OrganizationCustomerField } from '../../../../../models/settings/CustomerField'
import { NewCustomerDetailsForm } from './new-customer-field.modal'

export const RequestedCustomerDetails = observer(() => {
    const store = useStore('createStore')
    const organizationStore = useStore('organizationStore')
    const organization = organizationStore.organization
    const { t } = useTranslation('customerDetails')

    const [addingNew, setAddingNew] = useState(false)

    const onFieldChecked = (field: OrganizationCustomerField, status: boolean) => {
        if (status === true) {
            const isFieldRedried = field.name == 'phone' || field.name == 'lastName'
            store.addRequestedCustomerDetail(field.id, isFieldRedried)
        } else {
            store.removeRequestedCustomerDetail(field.id)
        }
    }

    const onDeletePressed = (field: OrganizationCustomerField, isChecked: boolean) => {
        if (isChecked) {
            store.removeRequestedCustomerDetail(field.id)
        }

        organizationStore.deleteCustomerField(field.id)
    }

    return (
        <div className={'event-box-border'} style={{ display: 'flex', flexDirection: 'column' }}>
            <label className={'event-box-label'}>{t('title')}</label>

            <span style={{ margin: '4px 0 ' }} />

            {addingNew && <NewCustomerDetailsForm onFinish={() => setAddingNew(false)} />}

            {organization!.customersFields
                .filter((f) => !f.isDeleted)
                .map((f) => ({ ...f, ...(store.customerDetails.find((c) => c.fieldId === f.id) || {}) }))
                .map((field, index) => (
                    <span key={index} style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={t('addFieldToPlan') as string}>
                            <FormControlLabel
                                style={{ fontSize: 10 }}
                                control={<Checkbox checked={Boolean(field.fieldId)} onChange={(e, checked) => onFieldChecked(field, checked)} />}
                                label={''}
                            />
                        </Tooltip>

                        <Typography style={{ margin: '4px 20px 4px -16px', color: Boolean(field.fieldId) ? 'initial' : 'rgba(0, 0, 0, 0.38)' }}>
                            {field.name.match(/^[a-zA-Z]/) ? t(field.name) : field.name} ({t(field.type)})
                        </Typography>

                        <FormControlLabel
                            style={{ fontSize: 10 }}
                            control={
                                <Checkbox
                                    checked={Boolean(field.isRequired)}
                                    onChange={(e, checked) => store.setCustomerDetailRequired(field.id, checked)}
                                />
                            }
                            label={t('required')}
                            disabled={!Boolean(field.fieldId)}
                        />

                        {field.canBeDeleted && (
                            <Tooltip title={t('deleteOption') as string}>
                                <IconButton aria-label="delete" onClick={() => onDeletePressed(field, Boolean(field.fieldId))}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </span>
                ))}

            <Button endIcon={<AddIcon />} onClick={() => setAddingNew(true)}>
                {t('addNewField')}
            </Button>
        </div>
    )
})
