import * as React from 'react'

import { DiagramEngine } from '@projectstorm/react-diagrams-core'
import { DefaultNodeModel } from '@projectstorm/react-diagrams'

import styled from '@emotion/styled';

import './event-node.style.scss'
import { EventEndPortLabel } from './event-end-port/end-port.label';

const S  = {
	Node: styled.div<{ background: string; selected: boolean }>`
		background-color: rgb(247, 255, 252);
		box-shadow: ${p => p.background} 0px 1px 3px 0px, ${p => p.background} 0px 1px 2px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px;
		border-radius: 22px;
		font-family: sans-serif;
		color: black;
		overflow: visible;
		font-size: 14px;
		border: solid ${p => (p.selected ? '2px rgb(2, 70, 64)' : '1px rgba(4, 171, 157, 0.14)')};
		width: 120px;
		height: 70px;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
	`,
	Title: styled.div`
		display: flex;
		justify-items: center;
		overflow-wrap: break-word;
		text-align: center;
	`,
	TitleName: styled.div`
		flex-grow: 1;
		overflow-wrap: break-word;
	`,
	PortsIn: styled.div`
		position: absolute;
		top: 0;
		left: 45%;
		width: 10%;
	`,
	PortsOut: styled.div`
		position: absolute;
		top: 108%;
	`,
	PortsInContainer: styled.div`
	`,
	PortsOutContainer: styled.div`
		display: flex;
	`
	// 	flex-grow: 1;
	// 	display: flex;
	// 	flex-direction: column;

	// 	&:first-of-type {
	// 		margin-right: 10px;
	// 	}

	// 	&:only-child {
	// 		margin-right: 0px;
	// 	}
	// `
}

export interface EventNodeProps {
	node: DefaultNodeModel;
	engine: DiagramEngine;
}

/**
 * Default node that models the DefaultNodeModel. It creates two columns
 * for both all the input ports on the left, and the output ports on the right.
 */
export class EventNodeWidget extends React.Component<EventNodeProps> {
	generatePort = (port: any) => {
		return <EventEndPortLabel engine={this.props.engine} port={port} key={port.getID()} />;
	}

	render() {
		return (
			<S.Node
				className='event-node'
				data-default-node-name={this.props.node.getOptions().name}
				selected={this.props.node.isSelected()}
				background={this.props.node.getOptions().color!}>
				<S.PortsIn>
					<S.PortsInContainer>{this.props.node.getInPorts().map(this.generatePort)}</S.PortsInContainer>
				</S.PortsIn>
				<S.Title>
					<S.TitleName>{this.props.node.getOptions().name}</S.TitleName>
				</S.Title>
				<S.PortsOut>
					<S.PortsOutContainer>{this.props.node.getOutPorts().map(this.generatePort)}</S.PortsOutContainer>
				</S.PortsOut>
			</S.Node>
		);
	}
}
