import { observable } from 'mobx'
import { OrganizationCustomerField } from './CustomerField'

// import uuidv4 from 'uuid/v4'
const uuidv4 = require('uuid/v4')

export default class Organization {
    _id: string
    name: string
    color?: string
    logo?: string
    coverLogo?: string
    docsLogo?: string
    isPublic: boolean
    @observable groups?: PlansGroup[]

    @observable users: OrganizationUser[] = []
    @observable roles: Role[] = []

    @observable customersFields: OrganizationCustomerField[]

    constructor(
        _id: string,
        name: string,
        logo?: string,
        coverLogo?: string,
        docsLogo?: string,
        color?: string,
        groups?: PlansGroup[],
        isPublic?: boolean,
        users?: OrganizationUser[],
        roles?: Role[],
        customersFields?: OrganizationCustomerField[]
    ) {
        this._id = _id || uuidv4()
        this.name = name
        this.color = color
        this.logo = logo
        this.coverLogo = coverLogo
        this.docsLogo = docsLogo
        this.groups = groups

        this.isPublic = isPublic === undefined ? true : isPublic

        this.users = users || []
        this.roles = roles || []

        this.customersFields = customersFields || []
    }
}

export class PlansGroup {
    _id: string
    @observable name: string
    @observable planIds: string[]

    constructor(_id: string, name: string, planIds: string[]) {
        this._id = _id || uuidv4()
        this.name = name
        this.planIds = planIds
    }
}

export interface Role {
    id: string
    text: string
}

interface OrganizationUser {
    userId: string
    roleIds: string[]

    canSearchAllData: boolean
}
