import axios from 'axios'

import Plan from '../../models/Plan.model'
import PlanPreview from '../../models/Plan-preview.model'

class PlansClientService {

    async getPlansPreview(): Promise<PlanPreview[]> {
        const { data } = await axios.get<PlanPreview[]>(`/plans/previews`)
        return data
    }

    async getPlan(planId: string): Promise<Plan> {
        const { data } = await axios.get<Plan>(`/plans/${planId}`)
        return data
    }
}

export default new PlansClientService()