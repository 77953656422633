import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';

import { RouteComponentProps } from 'react-router'
import { WithTranslation, withTranslation } from 'react-i18next'
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

import { PlanAnalytic } from './../../../models/Analytics.models'
import AnalyticsStore from '../../../stores/analytics.store';
import { AnalyticsTable } from './analytics-table.component'

import './analytics.style.scss'

interface AnalyticsScreenProps extends RouteComponentProps, WithTranslation {
  analyticsStore?: AnalyticsStore
}

@inject((stores) => ({
  analyticsStore: (stores as any).analyticsStore as AnalyticsStore,
}))
@observer
class AnalyticsScreen extends Component<AnalyticsScreenProps, any> {

  constructor(props: AnalyticsScreenProps) {
    super(props)

    this.startPlanFromRoute()
  }

  startPlanFromRoute = async () => {
    const { match, history, analyticsStore } = this.props
    const { id } = match!.params as any

    if (id) {
      await analyticsStore!.initAnalytics(id)
    } else {
      history.push('')
    }
  }

  render() {
    const { analytics, displayedPlan, numOfStarts } = this.props.analyticsStore!

    if (!analytics || !displayedPlan) {
      return <span></span>
    }

    return (
      <Grid container spacing={3} className="analytics-screen">
        <Typography variant="h6" gutterBottom component="div">
          Starts: {numOfStarts} Uses: {analytics.length}
        </Typography>
        <AnalyticsTable></AnalyticsTable>
      </Grid>
    )
  }
}


export default withTranslation('analyticsScreen')(AnalyticsScreen)