enum AvailabilitiesEnum {
    AfterTime,
    AfterEvents
}

const AvailabilityTypes = {
    [AvailabilitiesEnum[0]]: { label: 'After (time)', idx: 0 },
    [AvailabilitiesEnum[1]]: { label: 'After (event/s)', idx: 1 },
}

const availabilityOptions = Object.values(AvailabilityTypes)

export { availabilityOptions, AvailabilityTypes, AvailabilitiesEnum }