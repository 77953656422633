
enum ActionsEnum {
    LinkAndFinish,
    Repeat,
    Call,
    Remind,
    End,
    EndWith,
    SetCall,
    TakePicture,
    FinishAndAddTheFiles,
    Link
}

const ActionTypes = {
    [ActionsEnum[0]]: { label: 'Open Link and Finish', idx: 0 },
    [ActionsEnum[1]]: { label: 'Repeat In', idx: 1 },
    [ActionsEnum[2]]: { label: 'Call', idx: 2 },
    [ActionsEnum[3]]: { label: 'Remind', idx: 3 },
    [ActionsEnum[4]]: { label: 'End', idx: 4 },
    [ActionsEnum[5]]: { label: 'End With', idx: 5 },
    [ActionsEnum[6]]: { label: 'Set a Call', idx: 6 },
    [ActionsEnum[7]]: { label: 'Add Picture, File or Note', idx: 7 },
    [ActionsEnum[8]]: { label: 'Finish and add the files', idx: 8 },
    [ActionsEnum[9]]: { label: 'Open Link', idx: 9 },
}

const actionsOptions = Object.values(ActionTypes)

export { actionsOptions, ActionTypes, ActionsEnum }
