import React, { Component, ReactElement } from 'react'
import { inject, observer } from 'mobx-react'
import { action, observable } from 'mobx'

import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Typography from '@material-ui/core/Typography'


import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionActions from '@material-ui/core/AccordionActions'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  Menu,
  MenuItem,
  IconButton,
  Dialog,
  DialogTitle,
  ListItem,
  List,
  ListItemText
} from '@material-ui/core'

import MoreIcon from '@material-ui/icons/MoreVert'
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline'
import ShowIcon from '@material-ui/icons/Visibility'

import PathEvent, { Dependency, Settings } from '../../../../../models/Event.model'
import timeTypes from '../../../../../utils/constants/time-types'
import CreateStore from '../../../../../stores/create.store'


import ContentComponent from './content/content.component'
import InputComponent from '../../../../../utils/components/input-component'
import DependenciesComponent from './dependencies.component'
import Path from '../../../../../models/Path.model'
import './event.style.scss'
import { DeadlinePicker } from '../Deadline'
import { PlanTypeEnum } from '../../../../../utils/constants/plan-type'
import { EventRolesView } from './event-roles'

interface EventComponentProps {
  fullPath: string
  event: PathEvent
  dataKey: number
  removeEvent: (any: any) => void
  createStore?: CreateStore
}

@inject((stores) => ({
  createStore: (stores as any).createStore as CreateStore,
}))
@observer
class EventComponent extends Component<EventComponentProps, any> {
  private fullPath: string

  @observable anchorElement: HTMLDivElement | null = null

  @observable settings: Settings

  @observable isPathsDialogOpen: boolean = false
  pathsOptions: Path[]

  constructor(props: EventComponentProps) {
    super(props)
    const { fullPath, dataKey, createStore } = props

    this.fullPath = `${fullPath}/events/${dataKey}`
    this.settings = props.event.settings

    // Full path = ["paths", pathIdx, "events", EventIdx]
    const pathIdx = parseInt(this.fullPath.split('/')[1])
    this.pathsOptions = createStore!.paths.filter((p, idx) => idx !== pathIdx)
  }

  @action onMenuClick = (event: any) => {
    this.anchorElement = event.target
  }

  @action handleMenuClose = () => {
    this.anchorElement = null
  }

  @action onSettingsChanged = (prop: keyof Settings, checked: boolean) => {
    this.props.createStore!.setProperty(`${this.fullPath}/${prop}`, checked)
    this.settings[prop] = checked
  }

  duplicate = () => {
    // Full path = ["paths", pathIdx, "events", EventIdx]
    const pathIdx = this.fullPath.split('/')[1]
    this.props.createStore!.duplicateEvent(parseInt(pathIdx, 10), this.props.event)

    this.handleMenuClose()
  }

  @action openPathsDialog = () => {
    this.handleMenuClose()
    this.isPathsDialogOpen = true
  }

  @action displayEvent = () => {
    this.props.createStore!.setDisplayedEvent(this.props.event)
  }

  @action handleDialogClose = (path?: Path) => {
    const { createStore, event } = this.props

    this.isPathsDialogOpen = false

    if (path) {
      // Full path = ["paths", pathIdx, "events", EventIdx]
      const pathIdx = parseInt(this.fullPath.split('/')[1])
      const newPathIdx = createStore!.paths.findIndex((p) => p._id === path._id)

      createStore!.switchPath(pathIdx, newPathIdx, event)
    }
  }


  render() {
    const { event, dataKey, removeEvent, createStore } = this.props
    const { isEditMode } = createStore!

    if (!event) {
      return
    }

    return (
      <Accordion defaultExpanded={!event.title} className={'eventPanel'}
        classes={{ expanded: 'panelExpanded' }} event-id={event._id}>


        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant='h6'>Event {dataKey + 1} {event.title && `- ${event.title}`}</Typography>
          {!!dataKey && <DeleteIcon className={'remove-icon'} onClick={removeEvent} />}
        </AccordionSummary>

        <AccordionDetails className={'main-container'}>

          {/* // TODO: use only single PathsDialog */}
          <PathsDialog paths={this.pathsOptions} isOpen={this.isPathsDialogOpen} onClose={this.handleDialogClose} />

          <span className='row'>

            <InputComponent
              id='event_title'
              className='event-title-input'
              label='Title'
              required
              fullPath={`${this.fullPath}/title`}
              style={{ margin: '0 8px' }}
              placeholder='title'
              margin='normal'
              InputLabelProps={{
                shrink: true,
              }}
            />

            <DeadlinePicker deadlinePath={`${this.fullPath}/deadline`} />

            <Button size='large' onClick={(e) => this.onMenuClick(e)}>Options</Button>

            <Menu
              id='options-menu'
              anchorEl={this.anchorElement}
              open={Boolean(this.anchorElement)}
              onClose={this.handleMenuClose.bind(this)}
            >
              <MenuItem onClick={this.duplicate}>Duplicate Event</MenuItem>
              <MenuItem onClick={this.openPathsDialog} disabled={!this.pathsOptions.length}>Switch Path</MenuItem>
            </Menu>

            <IconButton color='primary' component='span' onClick={this.displayEvent}>
              <ShowIcon />
            </IconButton>
          </span>

          {createStore!.planType === PlanTypeEnum.MultiUsers && <EventRolesView path={`${this.fullPath}/roles`} />}
          
          <EventSettings settings={this.settings} onCheck={this.onSettingsChanged} />

          <ContentComponent content={event.content} fullPath={this.fullPath} />
          <DependenciesComponent dependencies={event.dependencies} fullPath={this.fullPath} />
        </AccordionDetails>
      </Accordion>
    )
  }
}


const EventSettings = observer((props: { settings: Settings, onCheck: (prop: keyof Settings, checked: boolean) => void }) => {
  const { settings, onCheck } = props
  return <div className={'event-box-border'}>
    <label className={'event-box-label'}>Event Settings</label>
    <FormControlLabel
      control={
        <Checkbox checked={settings.shouldShowOnPreview}
          onChange={(e, checked) => onCheck('shouldShowOnPreview', checked)} />
      }
      label={'Should show on preview?'} />
    {/* <FormControlLabel
      control={
        <Checkbox checked={settings.shouldDisplayOverWeb}
          onChange={(e, checked) => onCheck('shouldDisplayOverWeb', checked)} />
      }
      label={'Should show over web page?'} /> */}
  </div>
})


interface PathsDialogProps {
  onClose: (path?: Path) => any
  isOpen: boolean
  paths: Path[]
}
const PathsDialog = observer((props: PathsDialogProps) => {
  const { onClose, isOpen, paths } = props;

  const handleClose = () => {
    onClose();
  }

  const handleListItemClick = (value: Path) => {
    onClose(value);
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={isOpen}>
      <DialogTitle id="simple-dialog-title">Move to path:</DialogTitle>
      <List>
        {paths.map((p) => (
          <ListItem button onClick={() => handleListItemClick(p)} key={p._id}>
            <ListItemText primary={p.title} />
          </ListItem>
        ))}

      </List>
    </Dialog>
  );
})

export default EventComponent
