import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { action, observable } from 'mobx'

import TextField, { TextFieldProps } from '@material-ui/core/TextField'

import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import CreateStore from '../../stores/create.store'

import PathEvent from '../../models/Event.model'
import { getPathIdx } from '../component-path'

interface SelectComponentProps {
  fullPath: string
  options: [{label: string, key: any, [s: string]: any}]
  defaultOptionIdx: number
  label?: string
  createStore?: CreateStore
}

@inject((stores) => ({
    createStore: (stores as any).createStore as CreateStore,
}))
@observer
class SelectComponent extends Component<TextFieldProps & SelectComponentProps, object> {
  @observable selectedKey: any 

  constructor(props: SelectComponentProps) {
    super(props)
    const { createStore, fullPath, options, defaultOptionIdx } = props

    this.selectedKey = createStore!.getPropertyByPath(fullPath)
    if (this.selectedKey == undefined) this.selectedKey = options[defaultOptionIdx].key
  }

  @action onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { createStore, fullPath, options } = this.props
    
    this.selectedKey = parseInt(event.target.value)
    
    createStore!.setProperty(fullPath, this.selectedKey)
  }

  render() {
    // Text props will hold only text props if all the other props will in the {} below
    const { createStore, fullPath, label, options, defaultOptionIdx, ...textProps } = this.props

    return (
      <TextField
        id={`select-${fullPath.split('/').pop()}`}
        select
        margin='normal' 
        label={label || '.'}
        style={{minWidth: 90}}
        value={this.selectedKey}
        onChange={this.onChange}
        {...textProps}
          >
        {options.map((option: any, idx: number) => (
          <MenuItem key={`opt-${idx}`} value={option.key}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    )
  }
}

export default SelectComponent
