import { User } from '../../models/User.model'
import { removeUndefined } from './utils.dal'

import db from './../firebase'

const buildUser = (userData: any): User => {
    return new User(userData.id,
        userData.name,
        userData.email,
        userData.planIds,
        userData.organizationId)
}

const addUser = async (user: User) => {
    await db.collection('users').doc(user.id).set(removeUndefined({
        id: user.id,
        name: user.name,
        email: user.email,
        planIds: user.planIds,
        organizationId: user.organizationId,
        phone: user.phone
    }))
}

const setOrganization = async (userId: String, organizationId: string) => {

    const res = await db.collection('users').where('id', '==', userId).get()

    if (res && res.docs && res.docs.length) {

        for (let dock of res.docs) {
            const user = buildUser(dock.data())

            await dock.ref.update(removeUndefined({
                id: user.id,
                name: user.name,
                email: user.email,
                planIds: user.planIds,
                organizationId: organizationId
            }))
        }
    }
}

const addPlanToUser = async (userId: String, planId: string) => {

    const res = await db.collection('users').where('id', '==', userId).get()

    if (res && res.docs && res.docs.length) {

        for (let dock of res.docs) {
            const user = buildUser(dock.data())
            const plans = new Set(user.planIds)

            if (!plans.has(planId)) {
                user.planIds.push(planId)

                await dock.ref.update(removeUndefined({
                    id: user.id,
                    name: user.name,
                    email: user.email,
                    planIds: user.planIds,
                    organizationId: user.organizationId
                }))
            }
        }
    }
}

const getUser = async (userId: string): Promise<User> => {

    const res = await db.collection('users').where('id', '==', userId).get()

    let user = new User('', '', '')
    if (res && res.docs && res.docs.length) {

        for (let dock of res.docs) {
            user = buildUser(dock.data())
        }
    }
    return user
}

export {
    setOrganization,
    addPlanToUser,
    addUser,
    getUser
}