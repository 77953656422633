import Plan from '../models/Plan.model'
import PlanPreview, { PathPreview } from '../models/Plan-preview.model'

const getPlanPreview = (plan: Plan): PlanPreview => {

    const paths: PathPreview[] = []

    for (const path of plan.paths) {
        const events = path.events.map(({title, settings }) => ({ 
            title, 
            shouldShowOnPreview: settings.shouldShowOnPreview
        }))
        
        paths.push({
            title: path.title,
            shouldShowOnPreview: path.settings.shouldShowOnPreview,
            events
        })
    }
    return new PlanPreview(plan._id,
        plan.title,
        paths,
        plan.lastUpdate,
        plan.inDevelopment,
        plan.organizationId,
        plan.displayOnOrganizationOnly,
        plan.type,
        plan.isPublic,
        plan.description,
        plan.isLocked,
        plan.permissions,
        plan.users)
}

export {
    getPlanPreview
}