import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { action, observable } from 'mobx'

import TextField, { TextFieldProps } from '@material-ui/core/TextField'

import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import CreateStore from '../../stores/create.store'

import PathEvent from '../../models/Event.model'
import { getPathIdx } from '../component-path'

interface InputComponentProps {
  fullPath: string
  label?: string
  createStore?: CreateStore
}

@inject((stores) => ({
    createStore: (stores as any).createStore as CreateStore,
}))
@observer
class InputComponent extends Component<TextFieldProps & InputComponentProps, object> {

  constructor(props: InputComponentProps) {
    super(props)
    const { fullPath } = props
  }

  @action onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { createStore, fullPath } = this.props
    
    createStore!.setProperty(fullPath, event.target.value)
  }

  render() {
    // Text props will hold only text props if all the other props will in the {} below
    const { createStore, fullPath, label, ...textProps } = this.props
    
    return (
      <TextField
        id={`input-${fullPath.split('/').pop()}`}
        margin='normal' 
        label={label || '.'}
        style={{minWidth: 90}}
        value={textProps.value || createStore!.getPropertyByPath(fullPath)}
        onChange={this.onChange}
        InputLabelProps={{
          shrink: true,
        }}
        {...textProps}
          >
      </TextField>
    )
  }
}

export default InputComponent
