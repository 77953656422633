
enum EventTypesEnum {
    WithActions,
    Tip,
    Form
}

const EventTypes = {
    [EventTypesEnum[0]]: { label: 'With Actions', idx: 0 },
    [EventTypesEnum[1]]: { label: 'Tip', idx: 1 },
    [EventTypesEnum[2]]: { label: 'Form', idx: 2 }
}

const eventTypeOptions = Object.values(EventTypes)

export { eventTypeOptions, EventTypes, EventTypesEnum }
