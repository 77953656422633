import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { action, observable } from 'mobx'

import TextField from '@material-ui/core/TextField'

import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'

import timeUnitTypes, { fromTheOptions, timeParser } from '../constants/time-types'
import CreateStore from '../../stores/create.store'

interface TimeSelectorComponentProps {
  timePath?: string // For time under paths/...
  getTime?(): number // For time under plan
  setTime?(time: number): void // For time under plan
  timeLabel?: string
  shouldShowFrom?: boolean
  createStore?: CreateStore
}

@inject((stores) => ({
  createStore: (stores as any).createStore as CreateStore,
}))
@observer
class TimeSelectorComponent extends Component<TimeSelectorComponentProps, object> {
  @observable currentTimeUnitIdx: number
  @observable currentFromTheIdx: number = 0
  @observable currentTime: number = 0

  constructor(props: TimeSelectorComponentProps) {
    super(props)
    const { createStore, timePath } = props

    if (props.timePath !== undefined) {
      this.currentTime = createStore!.getPropertyByPath(timePath!)
    } else if (props.getTime !== undefined) {
      this.currentTime = props.getTime()
    } else {
      throw new Error('Props is missing timePath or getTime')
    }

    const time = timeParser(this.currentTime)
    this.currentTimeUnitIdx = time.type
    this.currentTime = time.value
    this.currentFromTheIdx = +(time.value < 0)
  }

  @action setFromThe = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.currentFromTheIdx = parseInt(event.target.value)

    this.updateTime()
  }

  @action setTimeUnit = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.currentTimeUnitIdx = parseInt(event.target.value)
    this.updateTime()
  }

  @action setTimeNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.currentTime = parseInt(event.target.value)

    this.updateTime()
  }

  updateTime = () => {
    const { timePath, createStore } = this.props

    if (this.currentTime != undefined) {
      let newTime: number = this.currentTime * timeUnitTypes[this.currentTimeUnitIdx].value
      newTime = fromTheOptions[this.currentFromTheIdx].value * newTime


      if (this.props.timePath !== undefined) {
        createStore!.setProperty(timePath!, newTime)
      } else if (this.props.setTime !== undefined) {
        this.props.setTime(newTime)
      } else {
        throw new Error('Props is missing timePath or getTime')
      }
    }
  }

  render() {
    const { timeLabel, shouldShowFrom, createStore, timePath } = this.props

    return (
      <span>
        <TextField
          id="length"
          label={(timeLabel || '.')}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={this.currentTime}
          style={{ width: 50 }}
          onChange={this.setTimeNumber}
          margin="normal"
        />

        <TextField
          id="time-unit"
          select
          label="Time Unit"
          SelectProps={{
          }}
          margin="normal"
          value={this.currentTimeUnitIdx}
          onChange={this.setTimeUnit}
        >
          {timeUnitTypes.map((option: any, idx: number) => (
            <MenuItem key={option.value} value={idx}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        { shouldShowFrom &&
          <TextField
            id="from-the"
            select
            label="From the"
            margin="normal"
            value={this.currentFromTheIdx}
            onChange={this.setFromThe}
          >
            {fromTheOptions.map((option: any, idx: number) => (
              <MenuItem key={`ft${idx}`} value={idx}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        }
      </span>
    )
  }
}

export default TimeSelectorComponent
