import axios from 'axios'
import { SESSION_TOKEN_KEY } from '../../stores/auth.store'

const init = () => {
    axios.interceptors.request.use((config) => {
        const token = localStorage.getItem(SESSION_TOKEN_KEY)
        config.headers.Authorization = token
        config.headers.backoffice = true

        return config
    })
    // axios.defaults.baseURL = 'https://api.simplan.app';
    axios.defaults.baseURL = 'https://simplan-api-zznh3ls73q-uc.a.run.app'
}

export default { init }
