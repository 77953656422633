import React, { useCallback, useState } from 'react'

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { CustomerCardFieldTypes } from '../../../../../models/settings/CustomerField'
import { useStore } from '../../../../../stores/root.store'

const uuidv4 = require('uuid/v4')

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formContainer: {
            marginTop: '10px'
        },
        formItems: {
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    })
)

interface Props {
    onFinish(): void
}
const NewCustomerDetailsForm = (props: Props) => {
    const classes = useStyles()

    const organizationsStore = useStore('organizationStore')

    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [type, setType] = useState<CustomerCardFieldTypes>('text')

    const { t } = useTranslation('customerDetails')

    const onSubmit = useCallback(async (name: string, type: CustomerCardFieldTypes) => {
        try {
            await organizationsStore.addCustomerField({
                id: uuidv4(),
                name,
                type,
                isDeleted: false,
                canBeDeleted: true
            })
            props.onFinish()
        } catch {
            setLoading(false)
        }
    }, [])

    return (
        <Dialog open={true} onClose={props.onFinish} aria-labelledby="form-dialog-title" maxWidth="sm">
            <DialogTitle id="form-dialog-title">{t('addNewModal.title')}</DialogTitle>

            <DialogContent>
                {loading ? (
                    <div className="loading">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className={classes.formItems}>
                        <TextField
                            key="name"
                            label={t('name')}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={name}
                            onChange={(event) => setName(event.target.value as string)}
                        />

                        <Select
                            id="type"
                            label={t('type')}
                            value={type}
                            style={{ marginTop: '16px' }}
                            onChange={(event) => setType(event.target.value as CustomerCardFieldTypes)}
                        >
                            {(['text', 'number', 'email', 'phone', 'textList'] as CustomerCardFieldTypes[]).map((u, index) => (
                                <MenuItem key={index} value={u}>
                                    {t(u)}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onFinish} color="primary">
                    {t('cancel')}
                </Button>
                <Button onClick={() => onSubmit(name, type)} color="primary" disabled={!name}>
                    {t('save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export { NewCustomerDetailsForm }
