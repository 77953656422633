import React from 'react'
import { Typography, Link } from '@material-ui/core'


const URL_REGEX = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig

const ContentText = (text: string): JSX.Element[] => {
    const lines = text.split('\n')

    const jsxElements: JSX.Element[] = []
    let currentText = ''

    lines.forEach((l, idx) => {
        const words = l.split(/\s+/)

        words.forEach((w, idx) => {
            if (w.match(URL_REGEX) && idx) {

                // Add the text and remove last word
                jsxElements.push(<Typography key={jsxElements.length} className='text-inline'>
                    {currentText.substring(0, currentText.length - words[idx - 1].length)}
                </Typography>)

                // Add link
                jsxElements.push(<Link style={{textDecoration: 'underline'}} key={jsxElements.length} href={w} target='_blank'>
                    {words[idx - 1]}
                </Link>)

                currentText = ''
            } else if (w) {
                currentText = `${currentText} ${w}`
            }
        })

        jsxElements.push(<Typography key={`line-${idx}`} className='text-inline'>
            {currentText}{'\n'}
        </Typography>)
        currentText = ''
    })

    if (currentText) {
        jsxElements.push(<Typography key={jsxElements.length}> {currentText} </Typography>)
    }

    return jsxElements
}

export { ContentText }