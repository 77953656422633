import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { EventRoles } from '../../../../../models/Event.model'
import { useStore } from '../../../../../stores/root.store'

interface Props {
    path: string
}
export const EventRolesView = observer((props: Props) => {
    const store = useStore('createStore')
    const organization = useStore('organizationStore').organization!

    const roles = store.getPropertyByPath(props.path) as EventRoles

    useEffect(() => {
        if (!roles) {
            store.setProperty(props.path, new EventRoles([], [], []))
        }
    }, [roles])

    if (!roles) {
        return <Typography>Loading...</Typography>
    }

    const adminRoles = (store.roles || []).filter((r) => r.isAdmin).map((r) => r.roleId)

    const updatePathWithUniqList = (list: string[], path: string) => {
        store.setProperty(path, Array.from(new Set(list)))
    }

    return (
        <div className={'event-box-border'}>
            <label className={'event-box-label'}>Roles</label>

            <FormControl style={{ minWidth: '120px', margin: '10px 6px' }} key="executors">
                <InputLabel id="executors-label">Executors</InputLabel>
                <Select
                    id="standard-select-currency"
                    label="executors"
                    value={roles.executors}
                    onChange={(event) => updatePathWithUniqList(event.target.value as string[], `${props.path}/executors`)}
                    multiple
                >
                    {(store.roles || [])
                        .map((r) => ({ ...r, ...organization!.roles.find((role) => role.id === r.roleId)! }))
                        .map((role, index) => (
                            <MenuItem key={index} value={role.id || role.roleId}>
                                {role.uniqIdentifier || role.text}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>

            <FormControl style={{ minWidth: '120px', margin: '10px 6px' }} key="notified">
                <InputLabel id="notified-label">Notified</InputLabel>
                <Select
                    id="standard-select-currency"
                    label="notified"
                    value={[...roles.notified, ...roles.executors]}
                    onChange={(event) => updatePathWithUniqList(event.target.value as string[], `${props.path}/notified`)}
                    multiple
                >
                    {(store.roles || [])
                        .map((r) => ({ ...r, ...organization!.roles.find((role) => role.id === r.roleId)! }))
                        .map((role, index) => (
                            <MenuItem key={index} value={role.id || role.roleId}>
                                {role.uniqIdentifier || role.text}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>

            <FormControl style={{ minWidth: '120px', margin: '10px 6px' }} key="viewer">
                <InputLabel id="viewer-label">Viewer</InputLabel>
                <Select
                    id="viewers-inner"
                    label="notified"
                    value={[...adminRoles, ...roles.notified, ...roles.executors, ...roles.viewers]}
                    onChange={(event) => updatePathWithUniqList(event.target.value as string[], `${props.path}/viewers`)}
                    multiple
                >
                    {(store.roles || [])
                        .map((r) => ({ ...r, ...organization!.roles.find((role) => role.id === r.roleId)! }))
                        .map((role, index) => (
                            <MenuItem key={index} value={role.id || role.roleId}>
                                {role.uniqIdentifier || role.text}
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </div>
    )
})
