import * as React from 'react'


import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import { EventNodeWidget } from './event-node.widget';
import { EventNodeModel } from './event-node.model'
import { DefaultNodeModel } from '@projectstorm/react-diagrams';

export class EventNodeFactory extends AbstractReactFactory<EventNodeModel, DiagramEngine> {
	constructor() {
		super('event');
	}

	generateReactWidget(event: GenerateWidgetEvent<EventNodeModel>): JSX.Element {
		return <EventNodeWidget engine={this.engine} node={event.model as any as DefaultNodeModel} />
	}

	generateModel(event: GenerateModelEvent): EventNodeModel {
		return new EventNodeModel();
	}
}

