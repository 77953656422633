import axios from 'axios'

import Plan from '../../models/Plan.model'
import PlanPreview from '../../models/Plan-preview.model'

class TemplatesClientService {
    async getTemplates(): Promise<Plan> {
        const { data } = await axios.get(`/plans/templates`)
        return data
    }

    async getPreviews(): Promise<PlanPreview[]> {
        const { data } = await axios.get(`/plans/templates/previews`)
        return data
    }
}

export default new TemplatesClientService()