import { observable, action } from 'mobx'

import RootStore from './root.store'
import PlanPreview from '../models/Plan-preview.model'
import plansClient from '../utils/client/plans.client'
import templatesService from '../utils/client/templates.service'
import clientService from '../utils/client/client.service'

class MainStore {
  @observable plans: PlanPreview[] = []
  templates: PlanPreview[] = []
  @observable userTemplates: PlanPreview[] = []

  @observable isLoading: boolean = true
  isInitialized = false

  @observable candidateDeletePlan?: PlanPreview

  constructor(private rootStore: RootStore) { }

  @action init = async () => {
    this.isLoading = true

    try {
      const loggedUser = this.rootStore.authStore.loggedUser!
      this.plans = await plansClient.getPlansPreview()
      const templates = await templatesService.getPreviews()
      this.userTemplates = []
      this.templates = []
      for (const template of templates) {
        if (loggedUser.planIds.find(pId => pId === template._id)) {
          this.userTemplates.push(template)
        } else {
          this.templates.push(template)
        }
      }

    } catch (err) {
      console.error(err)
    } finally {
      this.isInitialized = true
      this.isLoading = false
    }
    // const { docs } = await db.collection('planz').get()

    // if (docs) {
    //   for (let dock of docs) {
    //     const planData = dock.data()
    //     this.plansRef.push(dock.ref)
    //     this.plans.push(buildPlanFromDb(planData))
    //   }

    // }
  }

  @action addPlan = (plan: PlanPreview) => {
    this.plans.push(plan)
  }

  @action setIsLoading = (state: boolean) => {
    this.isLoading = state
  }

  reset = () => {
    this.plans = []
  }

  @action setCandidateDeletePlan = (plan?: PlanPreview) => {
    this.candidateDeletePlan = plan
  }

  @action removePlan = (plan: PlanPreview) => {
    clientService.deletePlan(plan._id)

    const index = this.plans.findIndex(p => p._id === plan._id)
    this.plans.splice(index, 1)
    this.candidateDeletePlan = undefined
  }
}

export default MainStore