import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { action, observable } from 'mobx'

import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline'

import CreateStore from '../../../../../stores/create.store'
import { Content, Action, Dependency } from '../../../../../models/Event.model'
import AvailabilityComponent from './availability.component'
import AppearanceComponent from './appearance.component'


interface DependenciesComponentProps {
    dependencies: [Dependency],
    fullPath: string 
    createStore? : CreateStore
}

@inject((stores) => ({
    createStore: (stores as any).createStore as CreateStore,
}))
@observer
class DependenciesComponent extends Component<DependenciesComponentProps, object> {
  private fullPath: string

  constructor(props: DependenciesComponentProps) {
    super(props)
    const { fullPath } = props

    this.fullPath = `${fullPath}/dependencies`
  }

  addDependency = () => {
    this.props.createStore!.addProperty(this.fullPath, new Dependency())
  }

  removeDependency = (idx: number) => {
    this.props.createStore!.removePropertyFromArray(this.fullPath, idx)
  }

  render() {
    const { dependencies } = this.props
    
    return <div className={'event-box-border'}>
            <label className={'event-box-label'}>Dependencies</label>
            { dependencies.map((dependency: Dependency, idx: number) => 
              <div className={'dependency-box'} key={`d-${idx}`}>
                {!!idx && <DeleteIcon className={'remove-icon'} 
                  onClick={this.removeDependency.bind({}, idx)}/>}
                <AvailabilityComponent availability={dependency.availability} 
                  fullPath={`${this.fullPath}/${idx}`}/>
                <AppearanceComponent  appearance={dependency.appearance} 
                  fullPath={`${this.fullPath}/${idx}`}/>
            </div>
            )}
            <Button fullWidth onClick={this.addDependency}>
              Add Dependency
            </Button>
        </div>}
}

export default DependenciesComponent
