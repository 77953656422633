import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'

import MainStore from '../../../stores/main.store'
import CreateStore from '../../../stores/create.store'
import SettingsStore from '../../../stores/settings.store'

import Grid from '@material-ui/core/Grid'

import Snackbar from '@material-ui/core/Snackbar'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import Typography from '@material-ui/core/Typography'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import MenuItem from '@material-ui/core/MenuItem'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionActions from '@material-ui/core/AccordionActions'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'

import MapIcon from '@material-ui/icons/Map'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

import { RouteProps } from 'react-router'

import Plan from '../../../models/Plan.model'

import './SettingsScreen.style.scss'
import SettingsCard from './components/settings-card.component';
import { CircularProgress } from '@material-ui/core';

interface SettingsScreenProps extends RouteProps {
  mainStore: MainStore
  createStore: CreateStore
  settingsStore: SettingsStore
}

@inject((stores) => ({
  mainStore: (stores as any).mainStore as MainStore,
  createStore: (stores as any).createStore as CreateStore,
  settingsStore: (stores as any).settingsStore as SettingsStore,
}))
@observer
class SettingsScreen extends Component<any, object> {

  constructor(props: any) {
    super(props)
    this.state
  }

  componentWillMount = async () => {
    // this.props.settingsStore.init()
  }

  componentDidMount = () => {
    // this.props.mainStore.init()
  }

  componentWillUnmount = () => {
    // this.props.mainStore.reset()
  }

  render() {
    const { isLoading } = this.props.settingsStore
    const { snackMessage } = this.props.createStore


    if (isLoading) {
      return <div className='loading'>
        <CircularProgress />
      </div>
    } else {
      return (

        <Grid container alignItems={'center'} direction={'column'} className='settings-screen'>
          {/* <Fab color="primary" aria-label="Add"  style={{left: '10px', position: 'absolute'}}>
          <AddIcon />
        </Fab> */}
          <Grid item xs={12} style={{ minWidth: '400px' }}>
            <Typography variant='h6'>
              Settings
          </Typography>
          </Grid>

          <SettingsCard history={this.props.history}>
          </SettingsCard>
        </Grid>
      )
    }
  }
}

export default SettingsScreen
