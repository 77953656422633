import React, { useCallback, useState } from 'react'
import { Button, Dialog, TextField, DialogTitle, DialogActions, DialogContent, Checkbox, FormControlLabel } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import { User } from '../../../../models/User.model'
import { RolesPicker } from './roles-picker'
import { useStore } from '../../../../stores/root.store'

const felids: { id: keyof User; label: string; type?: string }[] = [
    {
        id: 'name',
        label: 'name'
    },
    {
        id: 'email',
        label: 'email',
        type: 'email'
    },
    {
        id: 'phone',
        label: 'phone',
        type: 'tel'
    }
]

interface Props {
    onClose(): void
    onFinished(user: User): void
}
export const AddUserDialog = (props: Props) => {
    const { t } = useTranslation('addUserDialog')

    const store = useStore('organizationStore')
    const [roles, setRoles] = useState<string[]>([])
    const [canSearchAllData, setCanSearchAllData] = useState<boolean>(false)

    const onSubmit = useCallback((e: React.FormEvent) => {
        e.preventDefault()
        const user: Partial<User> = {}

        felids.forEach((item, index) => {
            user[item.id] = ((e.target as any)[index] as any).value
            // console.log(item.id, ((e.target as any)[index] as any).value)
        })
        // console.log('user phone', user.phone)
        user['phone'] = `+972${user.phone}`

        store.addUser(user as User, roles, canSearchAllData).finally(props.onClose)
    }, [])

    return (
        <Dialog onClose={props.onClose} aria-labelledby="customized-dialog-title" open className="add-organization" onBackdropClick={props.onClose}>
            <DialogTitle>{t('title')}</DialogTitle>

            <form autoComplete="off" onSubmit={onSubmit}>
                <DialogContent className="dialog-content">
                    {felids.map((f) => (
                        <TextField key={f.id} id={f.id} label={f.label} type={f.type} required style={{ width: '45%', margin: '0 8px' }} />
                    ))}

                    <RolesPicker onSelectedRolesChanged={(roles) => setRoles(roles.map((r) => r.id))} />

                    <FormControlLabel
                        style={{ fontSize: 10 }}
                        control={<Checkbox checked={canSearchAllData} onChange={(e, checked) => setCanSearchAllData(checked)} />}
                        label={t('canSearchAllData')}
                    />
                </DialogContent>
                <DialogActions>
                    <Button type="submit" size="large" autoFocus>
                        {t('add')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
