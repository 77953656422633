import React, { useCallback, useEffect, useState } from 'react'
import { MenuItem, TextField } from '@material-ui/core'
import { deadlineOptions, PlanDeadline, PlanDeadlineType } from '../../../../models/Plan-deadline.model'
import TimeSelectorComponent from '../../../../utils/components/time-selector'
import { useStore } from '../../../../stores/root.store'

interface DeadlinePickerProps {
    deadlinePath?: string // For time under paths/...
    getTime?(): number
    onTimeChanged?(type: PlanDeadlineType, value: number): void
    getType?(): PlanDeadlineType | undefined
}
export const DeadlinePicker = (props: DeadlinePickerProps) => {
    const createStore = useStore('createStore')

    const [deadlineMode, setDeadlineMode] = useState<number>(0)

    useEffect(() => {
        let mode = -1
        if (props.deadlinePath !== undefined) {
            const deadline = createStore.getPropertyByPath(props.deadlinePath)
            mode = deadline ? createStore.getPropertyByPath(`${props.deadlinePath!}/type`) : -1
        } else if (props.getType !== undefined) {
            mode = props.getType() === undefined ? -1 : props.getType()!
        } else {
            throw new Error('Props missing one of getType or typePath')
        }

        setDeadlineMode(mode + 1)
    }, [])

    const onModeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newMode = parseInt(event.target.value)
        if (props.deadlinePath && deadlineMode === 0 && newMode > 0) {
            const deadline = createStore.getPropertyByPath(props.deadlinePath)

            if (!deadline) {
                createStore.setProperty(props.deadlinePath!, new PlanDeadline(PlanDeadlineType.TIME, 24 * 60))
            }
        } else if (props.deadlinePath && newMode === 0) {
            createStore.setProperty(props.deadlinePath, undefined)
        }

        setDeadlineMode(newMode)
    }

    const setTime = useCallback(
        (time: number) => {
            const mode = PlanDeadlineType.TIME

            if (props.onTimeChanged) {
                props.onTimeChanged(mode, time)
            } else if (props.deadlinePath !== undefined) {
                createStore.setProperty(props.deadlinePath!, new PlanDeadline(mode, time))
            } else {
                throw new Error('Props missing one of getType or typePath')
            }
        },
        [props.onTimeChanged]
    )

    return (
        <span>
            <TextField id="deadline" select label="Deadline" SelectProps={{}} margin="normal" value={deadlineMode} onChange={onModeChanged}>
                {deadlineOptions.map((option: any, idx: number) => (
                    <MenuItem key={idx} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>

            {deadlineMode === 1 && (
                <TimeSelectorComponent
                    timePath={props.deadlinePath ? `${props.deadlinePath!}/value` : undefined}
                    getTime={props.getTime}
                    setTime={setTime}
                />
            )}
        </span>
    )
}
