import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Modal, TextField, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertFromRaw, convertToRaw, EditorState } from "draft-js";

import { useStore } from '../../../../../stores/root.store'

import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface NoteTemplateEditorProps {
    fullPath: string,
    onClose(): void
}
export const NoteTemplateEditor = observer((props: NoteTemplateEditorProps) => {
    const { t } = useTranslation('event')
    const creatStore = useStore('createStore')
    const contentPath = `${props.fullPath}/template`
    const content = creatStore.getPropertyByPath(contentPath) as string

    const logoPath = `${props.fullPath}/withLogo`
    const _withLogo = creatStore.getPropertyByPath(logoPath) as boolean
    const [withLogo, setWithLogo] = useState<boolean>(_withLogo)

    const signaturesPath = `${props.fullPath}/signatures`
    const signatures = creatStore.getPropertyByPath(signaturesPath) as [] | undefined


    const [withSignatures, setWithSignatures] = useState<boolean>(!!signatures && signatures.length > 0)
    const [newSignature, setNewSignature] = useState<string | undefined>()

    const [editorState, setEditorState] = useState(() =>
        content === undefined ? EditorState.createEmpty()
            :
            (EditorState.createWithContent(content.startsWith('{') ?
                convertFromRaw(JSON.parse(content))
                : // Backwards compatibility 
                ContentState.createFromText(content)))
    );

    const onContentChanged = useCallback((s: EditorState) => {
        creatStore.setProperty(contentPath, JSON.stringify(convertToRaw(s.getCurrentContent())))
        setEditorState(s)
    }, [])

    const initSignatures = () => {
        if (!signatures) {
            creatStore.setProperty(signaturesPath, [])
        }
    }

    const addSignature = () => {
        if (!newSignature) return
        initSignatures()

        creatStore.addProperty(signaturesPath, newSignature)
    }

    const removeSignature = (index: number) => {
        creatStore.removePropertyFromArray(signaturesPath, index)
    }

    return <Dialog onClose={props.onClose} aria-labelledby="simple-dialog-title" open maxWidth='sm' fullWidth>
        <DialogTitle id="simple-dialog-title">{t('editNote')}</DialogTitle>
        <DialogContent>
            <div style={{ border: "1px solid grey", borderRadius: 6, padding: '8px', minHeight: '300px' }}>
                <Editor
                    editorState={editorState}
                    onEditorStateChange={onContentChanged}
                />
            </div>

            <div>

                <FormControlLabel
                    control={
                        <Checkbox checked={withLogo}
                            onChange={(e, checked) => {
                                setWithLogo(checked)
                                creatStore.setProperty(logoPath, checked)
                            }} />
                    }

                    label={t('withLogo')} />
            </div>

            <FormControlLabel
                control={
                    <Checkbox checked={withSignatures}
                        onChange={(e, checked) => {
                            if (checked) initSignatures()
                            setWithSignatures(checked)
                        }} />
                }
                label={t('withSignatures')} />

            {signatures !== undefined && <div className={'event-box-border'}>
                <label className={'event-box-label'}>{t('requiredSignatures')}</label>

                <div className='permissions' style={{ padding: 12 }}>
                    {signatures.map((s, idx) =>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} key={idx}>
                            <Typography>{s}</Typography>
                            <Button endIcon={<DeleteIcon />} onClick={() => removeSignature(idx)}>{t('delete')}</Button>
                        </div>
                    )}
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField
                        id='add-signature'
                        label={t('addSigner')}
                        style={{ margin: 8, minWidth: 220 }}
                        value={newSignature}
                        margin='normal'
                        onChange={(event) => setNewSignature(event.target.value)}
                        fullWidth
                        onKeyPress={event => event.key === 'Enter' && addSignature()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />


                    <span style={{ display: 'flex' }}>
                        <Button
                            className='add-user-button'
                            endIcon={<AddIcon />}
                            disabled={!newSignature}
                            onClick={addSignature}>
                        </Button>
                    </span>
                </div>
            </div>}
        </DialogContent>
    </Dialog>
})
