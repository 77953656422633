enum LocationsEnum {
    Home,
    OnTheWay,
    Area
}

const LocationTypes = {
    [LocationsEnum[0]]: { label: 'At Home', idx: 0 },
    [LocationsEnum[1]]: { label: 'On The Way', idx: 1 },
    [LocationsEnum[2]]: { label: 'In the Area', idx: 2 },
}

const locationOptions = Object.values(LocationTypes)

export { locationOptions, LocationTypes, LocationsEnum }