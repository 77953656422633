import axios from 'axios'
import { NewUserRequest, NewUserResponse } from '../../models/api/NewOrganizationUser'

import Organization from '../../models/settings/Organization.model'

class OrganizationClientService {
    async addOrganization(organization: Organization): Promise<void> {
        const { data } = await axios.post('/organizations', organization)
        return data
    }

    async updateOrganization(organization: Organization): Promise<void> {
        const { data } = await axios.post(`/organizations/${organization._id}`, organization)
        return data
    }

    async addOrganizationUser(organization: Organization, user: NewUserRequest): Promise<NewUserResponse> {
        const { data } = await axios.post(`/organizations/${organization._id}/users`, user)
        return data
    }
}

export default new OrganizationClientService()