import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { action, observable } from 'mobx'

import TextField from '@material-ui/core/TextField'

import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'

import Select from '@material-ui/core/Select'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'

import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import CreateStore from '../../stores/create.store'

import PathEvent, { Action, DEFAULT_EVENT_END } from '../../models/Event.model'
import { getPathIdx, getEventIdx } from '../component-path'

import { EventTypesEnum } from '../constants/event-types'
import { ActionsEnum } from '../constants/action-types'
import SelectComponent from './select-component'

interface EventSelectorComponentProps {
  eventFullPath: string
  avoidCurrentEvent?: boolean
  createStore?: CreateStore
}

@inject((stores) => ({
    createStore: (stores as any).createStore as CreateStore,
}))
@observer
class EventSelectorComponent extends Component<EventSelectorComponentProps, object> {
  @observable selectedEvents: string[]
  eventOptions: PathEvent[]
  pathIdx: number

  constructor(props: EventSelectorComponentProps) {
    super(props)
    const { eventFullPath, createStore, avoidCurrentEvent } = props

    this.pathIdx = getPathIdx(eventFullPath)
    this.eventOptions = createStore!.paths.map(p => p.events).flat()

    if (avoidCurrentEvent) {
      const eventID = createStore!.paths[this.pathIdx].events[getEventIdx(eventFullPath)]._id

      this.eventOptions = this.eventOptions.filter((e) => e._id !== eventID)
    }

    this.selectedEvents = createStore!.getPropertyByPath(eventFullPath) || []

    // Makes sure this event is still exist
    const eventOptionsSet = new Set(this.eventOptions.map(e => e._id))
    this.selectedEvents = this.selectedEvents.filter(e => eventOptionsSet.has(e))
    
    // If no events, change the after-event to after time. not the right place, but easier
    // if (this.selectedEvents.length === 0) {
      createStore!.setProperty(eventFullPath, this.selectedEvents )
    // }
  }

  @action onChange = (selectEvent: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    let { createStore, eventFullPath } = this.props
    this.selectedEvents = (selectEvent.target.value as any as [string])

    // Reset end with for each change
    const path = `${this.props.eventFullPath.split('/afterEvents')[0]}/eventEndIdx`
    createStore!.setProperty(path, undefined)

    createStore!.setProperty(eventFullPath, this.selectedEvents)
  }

  render() {
    const { createStore, eventFullPath } = this.props

    return (
        <span>
          <Select
            multiple
            required
            value={this.selectedEvents}
            onChange={this.onChange}
            input={<Input id="select-multiple-chip" />}
            style={{marginTop:'32px', marginBottom:'8px', maxWidth: '400px'}}
            renderValue={(eventIds: any) => 
              eventIds.map((eventId: string) => {
                const event = this.eventOptions.find((event) => event._id == eventId)

                if (event) {
                  return event!.title
                } else {
                  return ''
                }

              }).join(', ')}
          >
            {this.eventOptions.map((pathEvent: PathEvent, idx: number) => (
              <MenuItem key={`evt${idx}`} value={pathEvent._id}>
                <Checkbox checked={this.selectedEvents.indexOf(pathEvent._id) > -1} />
                <ListItemText primary={pathEvent.title} />
              </MenuItem>
            ))}
          </Select>
          {this.selectedEvents.length == 1 && <EndView fullPath={eventFullPath}
            pathEvent={this.eventOptions.find((event) => event._id == this.selectedEvents[0])!}/>}
        </span>
    )
  }
}

const EndView = observer((props: {pathEvent: PathEvent, fullPath: string}) => {
  const {pathEvent, fullPath} = props

  let options: [{label: string, key: number}] | undefined
  if (pathEvent && pathEvent.content.type === EventTypesEnum.WithActions && pathEvent.content.actions) {
    options = pathEvent.content.actions.filter((action: Action) => 
      action.type === ActionsEnum.EndWith).map((action: Action, idx: number) => ({ label: action.end, key: idx })) as [{label: string, key: number}]

      // TODO: fix this 5 unreadable issue
      if (options.length) options.unshift({ label: 'Default', key: DEFAULT_EVENT_END })
  }

  if (!(options && options.length)) {
    return <span></span>
  } else {
    const path = `${fullPath.split('/afterEvents')[0]}/eventEndIdx`
    return <span>
      <SelectComponent options={options} fullPath={path} defaultOptionIdx={0} label={'Ends With'}/>
    </span>

  }
})

export default EventSelectorComponent
